import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {Storage} from '@ionic/storage';
// Babytalk Client V2
// Component: nobaby
// Purpose: no baby warning?
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-nobaby',
    templateUrl: './nobaby.component.html',
    styleUrls: ['./nobaby.component.scss'],
})

export class NobabyComponent implements OnInit {
    // property type declarations
    PageTitle = 'No Child Selected';
    basesurl: string; // base server url
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    UserData: any;
    selectedBabyData: any;
    babyid: any;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public storage: Storage,
                public apiService: ApiService) {
    }

    // Initialisation routine
    ngOnInit() {
        
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {
        this.spinner.show();
    }
    

}
