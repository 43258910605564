import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';
import {ActivityLayout, GPSpositionLayout, TagListLayout} from '../interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActionSheetController, AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {File} from '@ionic-native/file/ngx';
import {Media, MediaObject} from '@ionic-native/media/ngx';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {AddtagComponent} from '../addtag/addtag.component';
import {Storage} from '@ionic/storage';
import {Base64} from '@ionic-native/base64/ngx';
import {MediaCapture} from '@ionic-native/media-capture/ngx';

import {Geolocation} from '@ionic-native/geolocation/ngx';
import {NativeGeocoder} from '@ionic-native/native-geocoder/ngx';
// import undefined = require('firebase/empty-import');

// Babytalk Client V2
// Component: diary
// Purpose: activity diary
// Porting Status: Done, not tested
declare var google;

@Component({
    selector: 'app-diary',
    templateUrl: './diary.component.html',
    styleUrls: ['./diary.component.scss'],
})
export class DiaryComponent implements OnInit {
    map: any;
    // @ViewChild('map') mapElement: ElementRef;
    @ViewChild('map', {static: false}) mapElement: ElementRef;


    [x: string]: any;

    // property type declarations

    basesurl: string;
    baseurl: string;
    message: string;
    todaysDate: any;
    PageTitle = 'Activity Diary';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    browsingDiary: boolean;
    successmessage: string;
    isImage = false;
    myImageUrl: any;
    // Audio
    recording = false;
    filePath: string;
    fileName: string;
    audio: MediaObject;
    audioList: any[] = [];
    hideDetailOption = false;
    isImageShown = false;
    isRecording = false;
    isOtherActivityShown = false;
    addDiaryURL: string;
    uid: any; // userId
    tagsData: [];
    getTagsURL: any; // URL NAME
    selectedTagId: any;
    diaryData: [];
    shownGroup = null;
    babydata: any;
    val: any;
    requested_page = 0;
    audioBase64String: any;
    freeHandLocation: any;
    latestAudioFile: any;
    private babyname: string;
    private newActivityurl: string;
    private babyid: string;
    // todo : interface
    private activity: ActivityLayout = {};
    private taglist: TagListLayout[];
    private GPSposition: GPSpositionLayout;
    private taglisturl: string;
    private userfirstname: string;
    private usersurname: string;
    private userid: string;
    private newActivityForm: FormGroup;
    private selectedBabyData: any;

    wordBoost: boolean;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                public platform: Platform,
                public file: File,
                public alertController: AlertController,
                private media: Media,
                private camera: Camera,
                public storage: Storage,
                private modalController: ModalController,
                public actionSheetController: ActionSheetController,
                public navCtrl: NavController,
                public base64: Base64,
                public mediaCaputre: MediaCapture,
                // private media: Media,
                private nativeGeocoder: NativeGeocoder,
                private geolocation: Geolocation
    ) {

        this.newActivityForm = new FormGroup({
            activityTag: new FormControl('', Validators.required),
            activityDetail: new FormControl('', Validators.required),
            dateObserved: new FormControl('', Validators.required),
            freeHandLocation: new FormControl('', Validators.required),
            otherTagId: new FormControl('', Validators.required),
        });

        this.newActivityForm.value.dateObserved = new Date(new Date().setDate(new Date().getDate())).toISOString();

        if (environment.verboseDebugMode == true) {
            console.log(this.newActivityForm.value.dateObserved);
        }
        this.wordBoost = environment.wordboost;

    }


    // Initialisation routine
    ngOnInit() {
        if (environment.verboseDebugMode == true) {
            console.log('latestAudioFile', this.latestAudioFile);
        }
        this.baseurl = 'https://' + environment.serverUrl + '/';
    }

    ionViewDidLoad() {
        this.isImageShown = false;
    }

    loadMap() {
        this.geolocation.getCurrentPosition().then((position) => {

            const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            const mapOptions = {
                center: latLng,
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };

            this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

        }, (err) => {
            if (environment.verboseDebugMode == true) {
                console.log(err);
            }
        });

    }


    addMarker() {
        this.router.navigateByUrl('/map');
    }


    ionViewWillEnter() {
        this.isImageShown = false;
        this.isRecording = false;
        if (environment.verboseDebugMode == true) {
            console.log('diary init');
        }
        this.browsingDiary = false;
        this.getUserInfo();

    }


    setBabyData() {

        this.babyname = this.selectedBabyData.baby_name;
        this.babyid = this.selectedBabyData.id;
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';


        this.getDiary();


        this.getTags();

        this.GPSposition = {};

        this.activity = {};

        //  set the date to today
        const d = new Date();
        const year = d.getFullYear().toString();
        const month = d.getMonth() + 1;
        let smonth: string;
        let sday: string;

        if (month < 10) {
            smonth = '0' + month.toString();
        } else {
            smonth = month.toString();
        }

        const day = d.getDate();
        if (day < 10) {
            sday = '0' + day.toString();
        } else {
            sday = day.toString();
        }

        // const dateobject = new Date(year + ' ' + smonth + ' ' + sday);
        this.activity.date_observed = new Date(year + ' ' + smonth + ' ' + sday);

        // when page is loaded, populate the drop down with activity tags
        // get the list of tags from the server
        this.taglist = [];

        this.userfirstname = this.UserData.givenname;
        this.usersurname = this.UserData.surname;
        this.userid = this.UserData.id;
        this.taglisturl = this.basesurl + 'diary/activitytags/0';

        this.spinner.show();

    }


    async presentGPSActionSheet() {

        const GPSactionSheet = await this.actionSheetController.create({
            header: 'Location',
            buttons: [{
                text: 'Delete',
                role: 'destructive',
                icon: 'trash',
                handler: () => {
                    if (environment.verboseDebugMode == true) {
                        console.log('Delete clicked');
                    }
                }
            }, {
                text: 'Show',
                icon: 'globe',
                handler: () => {
                    if (environment.verboseDebugMode == true) {
                        console.log('Show clicked');
                    }
                    this.getLocation();
                }
            }]
        });
        await GPSactionSheet.present();
    }


    async presentAudioActionSheet() {

        const AudioactionSheet = await this.actionSheetController.create({
            header: 'Audio',
            buttons: [{
                text: 'Record',
                role: 'destructive',
                icon: 'trash',
                handler: () => {
                    if (environment.verboseDebugMode == true) {
                        console.log('Delete clicked');
                    }
                }
            }, {
                text: 'Show',
                icon: 'globe',
                handler: () => {
                    if (environment.verboseDebugMode == true) {
                        console.log('Show clicked');
                    }
                    this.recordAudioToDiary();
                }
            }, {
                text: 'Show',
                icon: 'globe',
                handler: () => {
                    if (environment.verboseDebugMode == true) {
                        console.log('Show clicked');
                    }
                    this.getLocation();
                }

            }]
        });
        await AudioactionSheet.present();
    }

    activityDetail(event) {

        if (environment.verboseDebugMode == true) {
            console.log(event.detail.checked);
        }

        event.detail.checked === true ? this.hideDetailOption = true : this.hideDetailOption = false;

    }


    browseDiary() {
        this.browsingDiary = true;
        if (environment.verboseDebugMode == true) {
            console.log('Requestedage', this.requested_page);
        }
        this.apiService.diaryList(this.requested_page).subscribe(res => {
            this.diaryData = res.data;
        }, err => {
            if (err.status == 401) {
                this.apiService.tokenExpired();
            } else {
                console.log('Error', err);
            }

            // if (environment.verboseDebugMode == true) {
            //     console.log(err);
            // }

        });
    }


    closeActivityBrowser() {
        this.browsingDiary = false;
    }

    uploadPhotoToDiary() {

    }

    getLocation() {
        // onSuccess Callback
        // This method accepts a Position object, which contains the
        // current GPS coordinates
        //
        const onSuccess = position => {
            this.GPSposition = {
                Latitude: position.coords.latitude,
                Longitude: position.coords.longitude,
                Altitude: position.coords.altitude,
                Accuracy: position.coords.accuracy,
                AltitudeAccuracy: position.coords.altitudeAccuracy,
                Heading: position.coords.heading,
                Speed: position.coords.speed,
                Timestamp: position.timestamp
            };
        };

        // onError Callback receives a PositionError object
        //
        function onError(error) {
            alert('code: ' + error.code + '\n' +
                'message: ' + error.message + '\n');
        }

        navigator.geolocation.getCurrentPosition(onSuccess, onError);


    }

    async selectPhoto() {

        const actionSheet = await this.actionSheetController.create({
            header: 'Select Photo From',
            buttons: [{
                text: 'Camera',
                handler: () => {
                    this.photoOption(1);
                }
            }, {
                text: 'Gallery',
                handler: () => {
                    this.photoOption(2);

                }
            }, {
                text: 'Cancel',
                handler: () => {
                    if (environment.verboseDebugMode == true) {
                        console.log('Cancel clicked');
                    }
                }
            }]
        });
        await actionSheet.present();


    }


    photoOption(params) {

        if (params == '1') {
            this.isImageShown = true;
            params = this.camera.PictureSourceType.CAMERA;
        } else {
            this.isImageShown = true;
            params = this.camera.PictureSourceType.PHOTOLIBRARY;
        }

        const options: CameraOptions = {
            quality: 100,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            sourceType: params,
            saveToPhotoAlbum: false,
            correctOrientation: true,
            allowEdit: true,
        };

        this.camera.getPicture(options).then((imageData) => {
            this.myImageUrl != '' ? this.isImage = false : this.isImage = true;
            if (environment.verboseDebugMode == true) {
                console.log('ImageData', this.isImage);
            }
            this.myImageUrl = 'data:image/jpeg;base64,' + imageData;
        }, (err) => {

            if (environment.verboseDebugMode == true) {
                console.log(err);
            }

        });

        // this.ImageUpload();
    }

    getDiary() {

        if (environment.verboseDebugMode == true) {
            console.log(true);
        }

        this.apiService.getDiaryData().subscribe(res => {
            if (res.replyStatus == 'success') {
                this.diaryData = res.data;
            }
            if (environment.verboseDebugMode == true) {
                console.log('Data', this.diaryData);
            }
        }, err => {
            if (err.status == 401) {
                this.apiService.access401();
            }
        });
    }

    toggleGroup(group) {
        if (this.isGroupShown(group)) {
            this.shownGroup = null;
        } else {
            this.shownGroup = group;
        }
    }

    isGroupShown(group) {
        return this.shownGroup === group;
    }


    startRecord() {
        if (environment.verboseDebugMode == true) {
            console.log('Start Recording');
        }
        if (this.platform.is('ios')) {
            this.fileName = 'record'
                + new Date().getDate()
                + new Date().getMonth()
                + new Date().getFullYear()
                + new Date().getHours()
                + new Date().getMinutes()
                + new Date().getSeconds()
                + '.m4a';
            this.filePath = this.file.documentsDirectory.replace(/file:\/\//g, '') + this.fileName;
            this.audio = this.media.create(this.filePath);
        } else if (this.platform.is('android')) {
            this.fileName = 'record'
                + new Date().getDate()
                + new Date().getMonth()
                + new Date().getFullYear()
                + new Date().getHours()
                + new Date().getMinutes()
                + new Date().getSeconds()
                + '.mp3';
            this.filePath = this.file.externalDataDirectory.replace(/file:\/\//g, '') + this.fileName;
            this.audio = this.media.create(this.filePath);
        }
        this.audio.startRecord();
        this.recording = true;
    }

    stopRecord() {
        // if (environment.verboseDebugMode == true) { console.log("Stop Recording"); }
        this.audio.stopRecord();
        this.audioList = [];
        const data: any = {filename: this.fileName};
        this.audioList.push(data);
        localStorage.setItem('audiolist', JSON.stringify(this.audioList));
        this.recording = false;
        this.getAudioList();
        if (environment.verboseDebugMode == true) {
            console.log('FilePath', this.filePath);
        }
        console.log('FileName', this.fileName);


        this.base64.encodeFile(this.filePath).then((base64File: string) => {

            console.log('base64', base64File);
            const x = base64File.substr(34, base64File.length);
            // x = "data:audio/mpeg;base64" + x;
            this.audioBase64String = x;

            console.log('Audio Dataa: ', this.audioBase64String);
        }, (err) => {
            if (environment.verboseDebugMode == true) {
                console.log('Error::', JSON.stringify(err));
            }
        });
    }


    audioFromServer(audio) {
        // const audioFile = 'http://www.babytalk.website/btsrv/public/' + audio;
        const audioFile = 'https://' + environment.serverUrl + '/' + audio;

        if (this.platform.is('ios')) {
            this.filept = audioFile;
            this.audio = this.media.create(this.filept);
        } else if (this.platform.is('android')) {
            this.filept = audioFile;
            this.audio = this.media.create(this.filept);
        }
        this.audio.play();
        this.audio.setVolume(0.8);
    }

    playAudio(file, idx) {
        if (this.platform.is('ios')) {
            this.filePath = this.file.documentsDirectory.replace(/file:\/\//g, '') + file;
            this.audio = this.media.create(this.filePath);
        } else if (this.platform.is('android')) {
            this.filePath = this.file.externalDataDirectory.replace(/file:\/\//g, '') + file;
            console.log('Play filePath', this.filePath);
            this.audio = this.media.create(this.filePath);
        }
        this.audio.play();
        this.audio.setVolume(0.8);
    }


    stopAudio() {
        this.audio.stop();
    }

    async delete(fileName, idx) {
        // if (environment.verboseDebugMode == true) { console.log("Delete"); }
        const alert = await this.alertController.create({
            header: 'Delete Recording!',
            message: 'Do you want to delete Recording?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        if (environment.verboseDebugMode == true) {
                            console.log('Confirm Cancel: blah');
                        }
                    }
                }, {
                    text: 'Okay',
                    handler: () => {
                        this.removeFile(fileName);
                        // if (environment.verboseDebugMode == true) { console.log(true); }
                    }
                }
            ]
        });

        await alert.present();
    }

    removeFile(fileName) {
        if (this.platform.is('ios')) {

            if (environment.verboseDebugMode == true) {
                console.log('IOS');
            }

            this.filePath = this.file.documentsDirectory.replace(/file:\/\//g, '');
            console.log('FileToRemove:', this.filePath);
            this.file.removeFile(this.filePath, this.fileName);
            this.apiService.showToast('File Removed');
            localStorage.removeItem('audiolist');
            this.filePath = '';
        } else {
            this.filePath = this.file.externalDataDirectory.replace(/file:\/\//g, '');
            console.log('FileToRemove:', this.filePath);
            if (environment.verboseDebugMode == true) {
                console.log('FileName', this.fileName);
            }
            this.file.removeFile(this.filePath, this.fileName);
            this.apiService.showToast('File Removed');
            localStorage.removeItem('audiolist');
            this.filePath = '';

        }


    }

    getAudioList() {
        if (environment.verboseDebugMode == true) {
            console.log('AudioList');
        }
        if (localStorage.getItem('audiolist')) {
            this.audioList = JSON.parse(localStorage.getItem('audiolist'));
            console.log('AudioList', this.audioList);
            this.latestAudioFile = this.audioList[0].filename;
            if (environment.verboseDebugMode == true) {
                console.log('latest Audio: ', this.latestAudioFile);
            }
        }
    }

    recordAudioToDiary() {
        if (this.platform.is('ios')) {
            this.fileName = 'record'
                + new Date().getDate()
                + new Date().getMonth()
                + new Date().getFullYear()
                + new Date().getHours()
                + new Date().getMinutes()
                + new Date().getSeconds()
                + '.3gp';
            this.filePath = this.file.documentsDirectory.replace(/file:\/\//g, '') + this.fileName;
            this.audio = this.media.create(this.filePath);
        } else if (this.platform.is('android')) {
            this.fileName = 'record'
                + new Date().getDate()
                + new Date().getMonth()
                + new Date().getFullYear()
                + new Date().getHours()
                + new Date().getMinutes()
                + new Date().getSeconds()
                + '.3gp';
            this.filePath = this.file.externalDataDirectory.replace(/file:\/\//g, '') + this.fileName;
            if (environment.verboseDebugMode == true) {
                console.log('filePath', this.filePath);
            }
            this.audio = this.media.create(this.filePath);
            if (environment.verboseDebugMode == true) {
                console.log('audio', this.audio);
            }
        }
        this.audio.startRecord();
        this.recording = true;
    }


    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }


    refresh() {
        // if (environment.verboseDebugMode == true) { console.log('Show spinner'); }
        // if (environment.verboseDebugMode == true) { console.log('refresh screen'); }
        this.spinner.show();

    }

    async addTagModal() {

        const modal = await this.modalController.create({
            component: AddtagComponent,
            animated: true
        });
        modal.onDidDismiss()
            .then((data: any) => {

                this.getTags();
            });
        return await modal.present();
    }

    getUserInfo() {
        this.storage.get('userInfo').then((val) => {
            this.uid = val.UserID;
            this.UserData = val;
            if (environment.verboseDebugMode == true) {
                console.log('USER INFO', this.UserData);
            }
        });

        this.storage.get('selectedbaby').then((val) => {
            this.selectedBabyData = val[0];
            console.log('selectedBaby', val[0]);
            this.setBabyData();
        });
    }

    getTags() {
        if (environment.verboseDebugMode == true) {
            console.log('Check for tags');
        }

        this.apiService.getTags(this.UserData.id).subscribe(res => {
            if (environment.verboseDebugMode == true) {
                console.log('Response', res);
            }
            if (res.replyStatus == 'success') {
                const mandatoryTags = res.mandatoryTags;
                const useraddedTags = res.data;

                this.tagsData = mandatoryTags.concat(useraddedTags);

                if (environment.verboseDebugMode == true) {
                    console.log('tagsData', this.tagsData);
                }

            } else {
                this.apiService.showToast('please try again');
            }
            if (environment.verboseDebugMode == true) {
                console.log('tagData', this.tagsData);
            }
        }, err => {
            if (err.status == 401) {
                this.apiService.access401();
            }
            this.apiService.showToast('Try again');
        });
    }

    selectedTag(tagId) {

        if (environment.verboseDebugMode == true) {
            console.log('selectedTag');
        }
        this.selectedTagId = tagId.detail.value;
        tagId.detail.value == '1' ? this.isOtherActivityShown = true : this.isOtherActivityShown = false;
    }


    saveEntry() {

        const activityEntry = {
            babyId: this.selectedBabyData.id,
            tagId: this.selectedTagId,
            lat: this.GPSposition.Latitude,
            long: this.GPSposition.Longitude,
            freeHandLocation: this.activity.freeHandLocation,
            otherTagId: this.activity.otherTagId,
            photo: this.myImageUrl,
            audio: this.audioBase64String,
            details: this.activity.detail,
            dateTime: this.newActivityForm.value.dateObserved
        };
        if (environment.verboseDebugMode == true) {
            console.log('ActivityEntry', activityEntry);
        }

        if (activityEntry.freeHandLocation == undefined) {
            this.apiService.showToast('Enter Nearest Location');
        } else if (activityEntry.tagId == undefined || activityEntry.tagId == '' || activityEntry.tagId == null) {
            this.apiService.showToast('Select Tag');
        } else if (activityEntry.dateTime == undefined) {
            this.apiService.showToast('please select date');
        } else if (activityEntry.tagId == '1') {
            if (activityEntry.otherTagId != '') {
                this.apiService.showLoader();
                this.apiService.addDiaryActity(activityEntry).subscribe((res: any) => {
                    if (res.replyStatus == 'success') {
                        this.apiService.hideLoader();
                        if (environment.verboseDebugMode == true) {
                            console.log('Response', res);
                        }
                        this.apiService.showToast(res.replyMsg);
                        this.navCtrl.navigateRoot('/buttons');
                    } else {
                        this.apiService.hideLoader();
                        this.apiService.showToast(res.replyMsg);
                    }
                }, err => {
                    this.apiService.hideLoader();
                    if (environment.verboseDebugMode == true) {
                        console.log('Err', err);
                    }
                });
            } else {
                this.apiService.hideLoader();
                this.apiService.showToast('please enter other activity tag name');
            }

        } else {
            // if(activityEntry.lat == undefined || activityEntry.long == undefined || activityEntry.audio == undefined||activityEntry.details == undefined ||activityEntry.otherTagId == undefined ||activityEntry.photo == undefined||activityEntry.tagId == undefined)
            // {
            //     activityEntry.lat = ''; 
            //     activityEntry.long = ''; 
            //     activityEntry.audio = ''; 
            //     activityEntry.details = ''; 
            //     activityEntry.otherTagId = ''; 
            //     activityEntry.photo = ''; 
            //     activityEntry.tagId = ''; 
            // }
            this.apiService.showLoader();
            this.apiService.addDiaryActity(activityEntry).subscribe((res: any) => {
                if (res.replyStatus == 'success') {
                    this.apiService.showToast(res.replyMsg);
                    this.navCtrl.navigateRoot('/buttons');
                } else {
                    this.apiService.showToast(res.replyMsg);
                }
                this.apiService.hideLoader();
            }, err => {
                if (err.status == 401) {
                    this.apiService.access401();
                }
                this.apiService.hideLoader();
                if (environment.verboseDebugMode == true) {
                    console.log('Err', err);
                }
            });
        }
    }
}
