import {Component, Input, OnInit} from '@angular/core';
import * as d3 from 'd3';

// Babytalk Client V2
// Component: barplot
// Purpose: component to generate a bar chart
// Porting Status: Ported
// Functional Status: Not Ready

interface WordCatCountLayout {
    category: string;
    wordCount: number;
}

let StatsBarChart: WordCatCountLayout[];
StatsBarChart = [
    {category: 'animals', wordCount: 20},
    {category: 'animalsounds', wordCount: 8},
    {category: 'vehicles', wordCount: 18},
    {category: 'toys', wordCount: 12},
    {category: 'food/drink', wordCount: 12},
    {category: 'bodyparts', wordCount: 10},
    {category: 'people', wordCount: 5},
    {category: 'games', wordCount: 4},
    {category: 'clothes', wordCount: 10},
    {category: 'furniture', wordCount: 8},
    {category: 'household', wordCount: 2},
    {category: 'outside', wordCount: 7},
    {category: 'describing', wordCount: 12},
    {category: 'questions', wordCount: 3},
    {category: 'time', wordCount: 5},
    {category: 'pronouns', wordCount: 4},
    {category: 'places', wordCount: 15},
    {category: 'amounts', wordCount: 6},
    {category: 'action', wordCount: 9}
];

@Component({
    selector: 'app-barplot',
    templateUrl: './barplot.component.html',
    styleUrls: ['./barplot.component.scss'],
})
export class BarplotComponent implements OnInit {
    @Input() type;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    pagetitle: string;
    babyname: string;
    width: number;
    height: number;
    margin = {top: 20, right: 20, bottom: 30, left: 70};
    x: any;
    y: any;
    svg: any;
    g: any;
    subtitle: string;
    title: string;

    constructor() {
        this.width = 850 - this.margin.left - this.margin.right;
        this.height = 800 - this.margin.top - this.margin.bottom;
    }

    ngOnInit() {
        this.babyname = 'Chewbacca';
        this.title = '01/01/2019';
        this.subtitle = this.babyname + '\'sVocabulary by category';
        if (this.type == '1') {
            this.pagetitle = this.babyname + '\'s Vocabulary';
        }

        if (this.type == '2') {
            this.pagetitle = 'type 1';
        }

        const dataset = [
            {
                label: 'May 15',
                'animals': 25,
                'animalsounds': 10,
                'vehicles': 40,
                'toys': 15,
                'food/drink': 5,
                'bodyparts': 4,
                'people': 0,
                'games': 1,
                'clothes': 1,
                'furniture': 1,
                'household': 8,
                'outside': 1,
                'describing': 1,
                'questions': 1,
                'time': 1,
                'pronouns': 1,
                'places': 1,
                'amounts': 9,
                'action': 0
            },
            {
                label: 'Jul 15',
                'animals': 5,
                'animalsounds': 10,
                'vehicles': 30,
                'toys': 50,
                'food/drink': 5,
                'bodyparts': 10,
                'people': 0,
                'games': 17,
                'clothes': 1,
                'furniture': 1,
                'household': 1,
                'outside': 1,
                'describing': 1,
                'questions': 1,
                'time': 2,
                'pronouns': 1,
                'places': 1,
                'amounts': 1,
                'action': 0
            },
            {
                label: 'Sep 15',
                'animals': 20,
                'animalsounds': 10,
                'vehicles': 30,
                'toys': 40,
                'food/drink': 5,
                'bodyparts': 5,
                'people': 0,
                'games': 1,
                'clothes': 1,
                'furniture': 10,
                'household': 1,
                'outside': 21,
                'describing': 1,
                'questions': 1,
                'time': 19,
                'pronouns': 30,
                'places': 1,
                'amounts': 1,
                'action': 0
            },
            {
                label: 'Nov 15',
                'animals': 10,
                'animalsounds': 10,
                'vehicles': 40,
                'toys': 35,
                'food/drink': 5,
                'bodyparts': 0,
                'people': 5,
                'games': 1,
                'clothes': 1,
                'furniture': 1,
                'household': 4,
                'outside': 1,
                'describing': 1,
                'questions': 1,
                'time': 1,
                'pronouns': 1,
                'places': 4,
                'amounts': 1,
                'action': 8
            },
            {
                label: 'Feb 16',
                'animals': 10,
                'animalsounds': 10,
                'vehicles': 40,
                'toys': 35,
                'food/drink': 5,
                'bodyparts': 0,
                'people': 0,
                'games': 1,
                'clothes': 1,
                'furniture': 1,
                'household': 20,
                'outside': 6,
                'describing': 10,
                'questions': 7,
                'time': 1,
                'pronouns': 13,
                'places': 1,
                'amounts': 1,
                'action': 10
            },
            {
                label: 'Apr 16',
                'animals': 5,
                'animalsounds': 10,
                'vehicles': 15,
                'toys': 40,
                'food/drink': 0,
                'bodyparts': 20,
                'people': 0,
                'games': 1,
                'clothes': 17,
                'furniture': 1,
                'household': 1,
                'outside': 1,
                'describing': 1,
                'questions': 18,
                'time': 1,
                'pronouns': 1,
                'places': 1,
                'amounts': 1,
                'action': 11
            },
            {
                label: 'May 15',
                'animals': 10,
                'animalsounds': 10,
                'vehicles': 40,
                'toys': 35,
                'food/drink': 15,
                'bodyparts': 10,
                'people': 10,
                'games': 10,
                'clothes': 10,
                'furniture': 8,
                'household': 3,
                'outside': 10,
                'describing': 10,
                'questions': 5,
                'time': 8,
                'pronouns': 1,
                'places': 9,
                'amounts': 3,
                'action': 12
            },
            {
                label: 'Jun 15',
                'animals': 10,
                'animalsounds': 10,
                'vehicles': 40,
                'toys': 35,
                'food/drink': 15,
                'bodyparts': 10,
                'people': 10,
                'games': 10,
                'clothes': 10,
                'furniture': 10,
                'household': 10,
                'outside': 10,
                'describing': 10,
                'questions': 10,
                'time': 10,
                'pronouns': 10,
                'places': 10,
                'amounts': 10,
                'action': 10
            },
            {
                label: 'Aug 16',
                'animals': 15,
                'animalsounds': 10,
                'vehicles': 40,
                'toys': 35,
                'food/drink': 25,
                'bodyparts': 24,
                'people': 20,
                'games': 20,
                'clothes': 29,
                'furniture': 20,
                'household': 20,
                'outside': 25,
                'describing': 20,
                'questions': 25,
                'time': 20,
                'pronouns': 27,
                'places': 20,
                'amounts': 20,
                'action': 19
            },

        ];
        this.barchart();
        //   this.initSvg();
        //  this.initAxis();
        // this.drawBars();
    }

    barchart() {

        /* edit these settings freely */
        var w = 600,
            h = 400,
            topMargin = 15,
            labelSpace = 40,
            innerMargin = w / 2 + labelSpace,
            outerMargin = 15,
            gap = 2,
            dataRange = d3.max(StatsBarChart.map(function(d) {
                return Math.max(d.wordCount);
            }));
        var leftLabel;
        var rightLabel;
        leftLabel = 'Left label',
            rightLabel = 'Right label';

        /* edit with care */
        var chartWidth = w - innerMargin - outerMargin,
            barWidth = h / StatsBarChart.length,
            yScale = d3.scale.linear().domain([0, StatsBarChart.length]).range([0, h - topMargin]),
            total = d3.scale.linear().domain([0, dataRange]).range([0, chartWidth - labelSpace]),
            commas = d3.format(',.0f');

// if (environment.verboseDebugMode === true) { console.log(StatsBarChart.length); }

        /* main panel */
        var vis = d3.select('#vis').append('svg')
            .attr('width', w)
            .attr('height', h);


        var bar = vis.selectAll('g.bar')
            .data(StatsBarChart)
            .enter().append('g')
            .attr('class', 'bar')
            .attr('transform', function(d, i) {
                return 'translate(0,' + (yScale(i) + topMargin) + ')';
            });


        bar.append('text')
            .attr('class', 'below')
            .attr('x', 12)
            .attr('dy', '1.2em')
            .attr('text-anchor', 'left')
            .text(function(d) {
                return d.category;
            })
            .style('fill', '#000000');


        bar.append('rect')
            .attr('class', 'malebar')
            .attr('height', barWidth - gap)
            .attr('x', 10);


        bar.append('svg')
            .attr({
                height: barWidth - gap
            })
            .append('text')
            .attr('class', 'up')
            .attr('x', 12)
            .attr('dy', '1.2em')
            .attr('text-anchor', 'left')
            .text(d => d.category)
            .style('fill', '#ffffff');


        d3.select('#generate').on('click', () => {
            for (let i = 0; i < StatsBarChart.length; i++) {
                StatsBarChart[i].wordCount = Math.random() * dataRange;

            }
            this.refresh(StatsBarChart);
        });

        this.refresh(StatsBarChart);
    }

    refresh(data) {

        const bars = d3.selectAll('g.bar')
            .data(data);

        bars.selectAll('rect.malebar')
            .transition();
        // .attr('width', d => total(d.wordCount));

        bars.selectAll('svg');
        // .attr('width', d => total(d.wordCount) + 10);


    }

    showForward() {

    }

    showBack() {

    }

    showAnimate() {

    }

    showCurrent() {

    }

}
