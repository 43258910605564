// ------------------------------------------
//  Routing Component
// ------------------------------------------
// This component defines the app routing
// ------------------------------------------

import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ButtonsComponent} from './buttons/buttons.component';
import {LoginComponent} from './login/login.component';
import {AboutbtComponent} from './aboutbt/aboutbt.component';
import {YourdataComponent} from './yourdata/yourdata.component';
import {AddbabyComponent} from './addbaby/addbaby.component';
import {AssignsurveyComponent} from './assignsurvey/assignsurvey.component';
import {BabydetailsComponent} from './babydetails/babydetails.component';
import {BabyeditComponent} from './babyedit/babyedit.component';
import {DiaryComponent} from './diary/diary.component';
import {CompletedsurveyComponent} from './completedsurvey/completedsurvey.component';
import {DeletebabyComponent} from './deletebaby/deletebaby.component';
import {FinishedComponent} from './finished/finished.component';
import {InfoComponent} from './info/info.component';
import {MybabyComponent} from './mybaby/mybaby.component';
import {NewbabyphotoComponent} from './newbabyphoto/newbabyphoto.component';
import {NewwordComponent} from './newword/newword.component';
import {NobabyComponent} from './nobaby/nobaby.component';
import {QuestionsComponent} from './questions/questions.component';
import {RecommendationComponent} from './recommendation/recommendation.component';
import {RegisterComponent} from './register/register.component';
import {SelectsurvComponent} from './selectsurv/selectsurv.component';
import {SettingsComponent} from './settings/settings.component';
import {ShowprogressComponent} from './showprogress/showprogress.component';
import {UserviewComponent} from './userview/userview.component';
import {ViewwordsComponent} from './viewwords/viewwords.component';
import {UploadPhotoComponent} from './upload-photo/upload-photo.component';
import {PersonaliseComponent} from './personalise/personalise.component';
import {FlashcardsComponent} from './flashcards/flashcards.component';
import {QuestionsResolverService} from './resolvers/questions-resolver.service';
import {PrivacyComponent} from './privacy/privacy.component';
import {TermsComponent} from './terms/terms.component';
import {ViewdiaryComponent} from './viewdiary/viewdiary.component';
import {WithdrawComponent} from './withdraw/withdraw.component';

const routes: Routes = [
    {path: '', redirectTo: 'buttons', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'auth', component: LoginComponent},
    {path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
    {path: 'buttons', component: ButtonsComponent},
    {path: 'aboutbt', component: AboutbtComponent},
    {path: 'yourdata', component: YourdataComponent},
    {path: 'addbaby', component: AddbabyComponent},
    {path: 'assignsurvey', component: AssignsurveyComponent},
    {path: 'babydetails', component: BabydetailsComponent},
    {path: 'babyedit', component: BabyeditComponent},
    {path: 'browsediary', component: DiaryComponent},
    {path: 'completedsurvey', component: CompletedsurveyComponent},
    {path: 'delbaby', component: DeletebabyComponent},
    {path: 'diary', component: DiaryComponent},
    {path: 'finished', component: FinishedComponent},
    {path: 'flashcards', component: FlashcardsComponent},
    { path: 'info', component: InfoComponent },
    { path: 'mybaby', component: MybabyComponent },
    { path: 'newphoto', component: NewbabyphotoComponent },
    { path: 'newword', component: NewwordComponent },
    { path: 'nobaby', component: NobabyComponent },
    { path: 'personalise', component: PersonaliseComponent },
    { path: 'questions', component: QuestionsComponent, resolve: { qmdata: QuestionsResolverService } },
    { path: 'recword', component: RecommendationComponent },
    {path: 'register', component: RegisterComponent},
    {path: 'selectsurv', component: SelectsurvComponent},
    {path: 'settings', component: SettingsComponent},
    {path: 'showprogress', component: ShowprogressComponent},
    {path: 'users', component: UserviewComponent},
    {path: 'uploadtest', component: UploadPhotoComponent},
    {path: 'viewdiary', component: ViewdiaryComponent},
    {path: 'viewwords', component: ViewwordsComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'terms', component: TermsComponent},
    {path: 'withdraw', component: WithdrawComponent},

    {path: 'newbabymodal', loadChildren: './mybaby/newbabymodal/newbabymodal.module#NewbabymodalPageModule'},
    {path: 'editbabymodal', loadChildren: './mybaby/editbabymodal/editbabymodal.module#EditbabymodalPageModule'},
    {path: 'viewwordlist', loadChildren: './viewwordlist/viewwordlist.module#ViewwordlistPageModule'},
    {path: 'map', loadChildren: './map/map.module#MapPageModule'},
    {path: 'tutorial', loadChildren: './tutorial/tutorial.module#TutorialPageModule'},
    {
        path: 'progress-doughnut-chart',
        loadChildren: './progress-doughnut-chart/progress-doughnut-chart.module#ProgressDoughnutChartPageModule'
    },
    {path: 'progress-pie-chart', loadChildren: './progress-pie-chart/progress-pie-chart.module#ProgressPieChartPageModule'},
    {path: 'download', loadChildren: './download/download.module#DownloadPageModule'},
    {path: 'personalise-images', loadChildren: './personalise-images/personalise-images.module#PersonaliseImagesPageModule'},






];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

