import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';

// Babytalk Client V2
// Component:  auth-guard service
// Purpose: Authentication Route Guard
// Porting Status: Ready

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {

    constructor(
        public authService: AuthenticationService
    ) {
    }

    /*    canActivate(): boolean {
            return this.authService.isAuthenticated();
        }*/

}
