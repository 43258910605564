import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {UserInfoService} from './user-info.service';
import {environment} from '../environments/environment';
import {ApiService} from './api.service';
import {NavController} from '@ionic/angular';


@Injectable()
export class AuthenticationService {

    public token: string;
    basesurl: string;
    baseAPIurl: string;
    loginUrl: string;
    babylisturl: string;
    userInfourl: string;

    private messageSource = new BehaviorSubject('default message');
    currentMessage = this.messageSource.asObservable();

    constructor(private http: HttpClient,
                public navCntl: NavController,
                private router: Router,
                private userInfoService: UserInfoService,
                private apiService: ApiService) {

        this.basesurl = 'https://' + environment.serverUrl;
        // this.loginUrl = 'https://team4.devhostserver.com/babytalk/public/api/authenticate';

        this.loginUrl = this.basesurl + '/api/authenticate';

        this.baseAPIurl = 'https://' + environment.serverUrl + '/v1/';

       // this.babylisturl = this.baseAPIurl + 'mybabies/0';
        this.userInfourl = 'https://team4.devhostserver.com/babytalk/public/v1/userdetails/0'


        
        // https://team4.devhostserver.com/babytalk/public/v1/userdetails/42

       
      
        // set token if saved in local storage
        //    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // const currentUser = localStorage.getItem('BTLK_currentUser');

        // if (environment.verboseDebugMode === true) { console.log(currentUser); }
        //  this.token = currentUser && currentUser.token;
        // this.token = currentUser;

        // this.router.events.pipe(
        //     filter(event => event instanceof NavigationEnd)
        // ).subscribe((event: any) => {
        //     event.url == '/auth' || '/login' ? this.apiService.menuButton = true : this.apiService.menuButton = false;
        // });

    }

    changeMessage(message: string) {
        this.messageSource.next(message);
    }

    login(email: string, password: string) {
        return this.http.post<any>(this.loginUrl, {email, password}).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                console.log('login success');
                if (environment.verboseDebugMode == true) {
                    console.log(user);
                }

                if (user && user.token) {
                    localStorage.setItem('BTLK_currentUser', user.token);
                    this.apiService.getMyUserData(this.userInfourl);
                    this.apiService.getBabyList();
                } else {
                    console.log('!(user && user.token)');
                }

                return user;
            }), catchError(this.handleError<any[]>('post', [])));
    }

    logout() {
        // remove currentUser (user token) from local storage to log user out
        localStorage.removeItem('BTLK_currentUser');
        localStorage.removeItem('BTLK_expires_at');
        localStorage.removeItem('token');
        this.token = null;

        // localStorage.clear();
        this.navCntl.navigateRoot('/login', {skipLocationChange: true});
    }

    /*private setSession(authResult) {
      if (environment.verboseDebugMode == true) { console.log(authResult); }
    //  const expiresAt = moment().add(authResult.expiresIn, 'second');
  
      localStorage.setItem('currentUser', authResult.token);
    //  localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
    }*/

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getExpiration() {
        const expiration = localStorage.getItem('BTLK_expires_at');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`Authentication service ${operation} operation failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


}
