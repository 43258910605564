import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-deletebaby',
    templateUrl: './deletebaby.component.html',
    styleUrls: ['./deletebaby.component.scss'],
    providers: []
})
export class DeletebabyComponent implements OnInit {
    // property type declarations
    PageTitle = 'Delete Baby';
    basesurl: string;
    message: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private newbabyid: string;
    private mybabyname: string;
    private delbabyurl: string;
    private assignSurvey: {};
    private babyname: string;
    private surveylisturl: string;

    wordBoost: boolean;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
        this.wordBoost = environment.wordboost;

    }

    // Initialisation routine
    ngOnInit() {
        this.newbabyid = this.userInfoService.getViewBabyID();
        this.mybabyname = this.userInfoService.getViewBabyName();
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.delbabyurl = this.basesurl + 'baby/' + this.newbabyid;
        this.assignSurvey = {};
        this.babyname = this.userInfoService.getBabyName();
        this.surveylisturl = this.basesurl + 'allsurveys/0';

        // selectSurveyToFinaliseService
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();
        this.message = '';
    }

    deletebaby(delbabyid) {
        this.delbabyurl = this.basesurl + 'baby/' + delbabyid;
        this.spinner.show();

        let dataobject = {};

        //  this.apiService.deleteObjectFromServer(this.delbabyurl, dataobject )

        this.apiService.deleteObjectFromServer(this.delbabyurl)
            .subscribe(
                data => {
                    // if (environment.verboseDebugMode === true) { console.log(data); }
                    if (data.substring(0, 7) == 'Not Sent') {
                        this.commserror = true;
                        this.commssuccess = false;
                        this.errormessage = 'New word ' + data;
                        this.successmessage = '';
                    } else {
                        this.commserror = false;
                        this.commssuccess = true;
                        this.errormessage = '';
                        this.successmessage = 'New word ' + data;
                    }
                    // alert(data);
                    this.spinner.hide();
                    console.log('POST call successful value returned in body', data);
                    this.message = 'Baby Deleted!';
                    // this.router.navigateByUrl('/mybaby');
                },
                error => {
                    console.log('new word POST call in error');
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                    this.errormessage = error;
                    this.commserror = true;
                    this.commssuccess = false;
                    this.spinner.hide();
                },
                () => {
                    console.log('The new wordPOST observable is now completed.');
                    // this.commssuccess = true;
                    this.spinner.hide();
                }
            );


    }

    showButtonsPage() {
        this.router.navigateByUrl('/buttons');
    }

    showBabyEditPage() {
        this.router.navigateByUrl('/babyedit');
    }

    showChildrenPage() {
        this.router.navigateByUrl('/mybaby');
    }

    refresh() {

    }
}
