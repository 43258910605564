import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: changephoto
// Purpose: change the photo on a child's profile
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-changephoto',
    templateUrl: './changephoto.component.html',
    styleUrls: ['./changephoto.component.scss'],
})
export class ChangephotoComponent implements OnInit {
    // property type declarations
    PageTitle = 'Change Photo';
    basesurl: string;
    message: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private newbabyid: string;
    private mybabyname: string;
    private photobabyurl: string;

    wordBoost: boolean;


    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
        this.wordBoost = environment.wordboost;
    }

    // Initialisation routine
    ngOnInit() {
        this.newbabyid = this.userInfoService.getViewBabyID();
        this.mybabyname = this.userInfoService.getViewBabyName();
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        // compsurveylisturl = basesurl + 'mycompsurveys/' + newbabyid;
        // babyphotourl = basesurl + 'baby/' + newbabyid;
    }

    // todo

    uploadFiles(file, errFiles, picbabyid) {

        /*    // if (environment.verboseDebugMode === true) { console.log(picbabyid); }
            // if (environment.verboseDebugMode === true) { console.log(file); }

            this.photobabyurl = this.basesurl + 'baby/newphoto/' + picbabyid;
            // if (environment.verboseDebugMode === true) { console.log(photobabyurl); }
            // this.spinner.show();

            let f;
            f = file;
            let errFile;
            errFile = errFiles && errFiles[0];
            if (file) {
                file.upload = Upload.upload({
                    url: this.photobabyurl,
                    data: {file: file}
                });
                // if (environment.verboseDebugMode === true) { console.log(photobabyurl); }
                file.upload.then(function(response) {
                    $timeout(function() {
                        file.result = response.data;
                    });
                }, function(response) {
                    if (response.status > 0) {
                        let errorMsg;
                        errorMsg = response.status + ': ' + response.data;
                    }
                }, function(evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total, 10));
                });
            }

            //   let dataobject = new Object();

            /*   $http({
             method: 'PUT',
             url: delbabyurl,
             data: dataobject
             })
             .then(function (headers) {
           // console.log('response  ', headers.status, headers.statusText);
           // console.log('response  ', headers);

             this.router.navigateByUrl('/mybaby');
             this.spinner.hide();
             } // http then
             ); // http
             */
        this.message = 'Photo Uploaded!';
        this.refresh();
    }


    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();

        // $window.location.reload(true);
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    showBabyEditPage() {
        this.router.navigateByUrl('/babyedit');
    }

    showNewBabyPhotoPage(babyid, babyname) {
        this.router.navigateByUrl('/sss');
    }

    assignQuestionnaire() {
    }

}
