import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {ApiService} from '../api.service';
import {UtilsService} from '../utils.service';
/* import {transition, trigger, useAnimation} from '@angular/animations';
import {bounce} from 'ng-animate';*/
import {transition, trigger, useAnimation} from '@angular/animations';
import {bounce} from 'ng-animate';
// import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {UserInfoLayout} from '../interfaces';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {environment} from '../../environments/environment';

/*-`
+

+
Babytalk Client V2
Component: buttons
Purpose: main menu
Porting Status: Ported from Ionic 1 (Javascript/AngularJS) to Ionic 5 (Typescript/Angular 7) Aug 2019
*/
@Component({
    selector: 'app-buttons',
    templateUrl: './buttons.component.html',
    styleUrls: ['./buttons.component.scss'],
    animations: [trigger('bounce', [transition('* => *', useAnimation(bounce))])],
    providers: [UserInfoService]
})
export class ButtonsComponent implements OnInit {
    /* property type declarations */
    basesurl: string; /* base server url loaderToShow: any;*/
    wordBoost: boolean;
    babylisturl: string;
    userInfourl: string;
    loggedinmessage: string;
    modemessage: string;
    imgloc = [];
    bounce: any;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    loggedinstatus: string;
    successmessage: string;
    bannermessage: string;
    emergencyMessage: string;
    practmode: boolean;
    featuremode = false;
    advisorButton: boolean;
    servname: string;

    private babyname: string;
    private data: any[];
    private imageLocations: any;
    private mybabies: any[];
    private userDetails: any[];
    private uidata: UserInfoLayout;
    private isAdvisorShown: boolean;
    private isAdvisorShownCode = false;

    /* constructor*/
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                public alertController: AlertController,
                public utils: UtilsService,
                private storage: Storage
                // private splashScreen: SplashScreen
    ) {

        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.wordBoost = environment.wordboost;
//      this.basesurl = 'https://' + 'www.babytalk.website/btsrv/public/v1/';
        this.babylisturl = this.basesurl + 'mybabies/0';
        this.userInfourl = this.basesurl + 'userdetails/0';
    }


    /* Initialisation routine*/

    ngOnInit() {
        console.log('ngOnInit');
        console.log('modemsg', this.modemessage);

        // this.loggedinmessage = localStorage.getItem('email');

        // if (this.userInfoService.getBabyID() != undefined) {
        //     this.babyname = this.userInfoService.getBabyName();
        // }
        // this.apiService.getMyBabyData(this.babylisturl);
        // this.apiService.getMyUserData(this.userInfourl);

        // if (!this.userInfoService.loggedIn) {
        //
        // } else {
        //     this.loggedinmessage = 'Logged In';
        //     this.modemessage = this.practmode === false ? 'Parental mode' :
        //         this.modemessage = this.practmode === true ? 'Practitioner Mode' : '';
        // }
    }

    ionViewWillEnter() {
        // console.log('In ion WIll Enter');
        this.getInfo();
        this.loggedinmessage = 'logged in as: ' + localStorage.getItem('Email');
        this.practmode = this.userInfoService.getPractitionerModeStatus();
        this.servname = environment.serverUrl;
        this.featuremode = this.userInfoService.getFeatureModeStatus();
        this.advisorButton = this.userInfoService.gethideAdvisorStatus();
        // added by andy 23/02/22
        console.log(this.apiService.getMOTDFromServer());
        this.bannermessage = this.userInfoService.getMOTD();
        this.emergencyMessage = this.apiService.getEmergencyMessage();

        console.clear();
        console.log('practmode', this.practmode);
        console.log('featuremode', this.featuremode);

        // added by andy 29/10/19
        //  this.apiService.getMyBabyData(this.babylisturl);
        //  this.apiService.getMyUserData(this.userInfourl);


        if (environment.verboseDebugMode === true) {
            console.log(this.practmode);
        }
        console.log('verboseDebugMode', environment.verboseDebugMode);

        // console.log("Practmode",this.practmode);
        // console.log("Featuremode",this.featuremode);
        // console.log("AdvisorStatus",this.AdvisorStatus);

    }

    /* oninit cssClass: 'custom-loader-class'*/

    openMenu() {
        document.querySelector('ion-menu-controller').open();
    }

    showNewPage(url: string, animation: string, element: string) {/* animate button and navigate to new location*/
        const self = this;
        this.animateCSS(element, animation, () => {
            if (environment.verboseDebugMode === true) {
                console.log(url);
            }
            self.router.navigateByUrl(url);
        });
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
    }

    login() {
        this.router.navigateByUrl('/auth');
    }

    getInfo() {
        console.log('In getInfo');
        // Or to get a key/value pair
        this.errormessage = '';
        this.babyname = '';
        this.storage.get('selectedbaby').then(val => {
            this.spinner.hide();

            if (val && val.length > 0) {
                this.babyname = val[0].baby_name;
                if (environment.verboseDebugMode === true) {
                    console.log(this.babyname);
                }
            } else {
                // Handle the error case here
                console.log('No selected baby or value is not an array');
                this.errormessage = 'Cannot find selected baby! Please Log out and back in again.';
                this.router.navigateByUrl('/auth');
            }
        }).catch(error => {
            // interpret error and maybe display something on the UI
            console.log('Error getting user info from storage');
            console.log(error);

        }).finally(() => {
            if (!this.babyname) {
                console.log('No child info');
                this.errormessage = 'Please Log out and back in again.';
                this.router.navigateByUrl('/auth');
            }

        });

        /* this.storage.get('isResearchKey').then(val => {
             this.isAdvisorShownCode = val;
             console.log('AdvisorShownCode', this.isAdvisorShownCode);
         });

         this.storage.get('isResearchKey').then(val => {
             this.isAdvisorShown = val;
             console.log('isAdvisorShown', this.isAdvisorShown);
         });
 */
        console.log('getting user info from storage...');

        this.storage.get('userInfo').then((val) => {
            // this.uid = val.UserID;
            this.uidata = val;
            console.log('USER INFO', this.uidata);
        }).catch(error => {
            // interpret error and maybe display something on the UI
            console.log('Error getting user info from storage');
            console.log(error);

        }).finally(() => {
            if (!this.uidata) {
                console.log('No user info');
                this.errormessage = 'Cannot find user data! Please Log out and back in again.';
            }
            console.log('Error getting user info from storage');
            console.log(Error);
        });
        // this.loggedinmessage = this.uidata.UserID;

    }


    async logout() {
        const alert = await this.alertController.create({
            header: 'Confirm!',
            message: 'Message <strong>text</strong>!',
            buttons: [{
                text: 'Cancel', role: 'cancel', cssClass: 'secondary', handler: (blah) => {
                    console.log('Confirm Cancel: blah');
                }
            }, {
                text: 'Okay', handler: () => {
                    localStorage.removeItem('satellizer_token');
                    this.userInfoService.clearAllLocalStorage();
                    this.router.navigateByUrl('/auth');
                }
            }]
        });
        await alert.present();

        /* $window.location.reload(true);*/
    }


    animateCSS(element, animationName, callback) {
        const node = document.querySelector(element);
        node.classList.add('animated', animationName);

        function handleAnimationEnd() {
            node.classList.remove('animated', animationName);
            node.removeEventListener('animationend', handleAnimationEnd);
            if (typeof callback === 'function') {
                callback();
            }
        }

        node.addEventListener('animationend', handleAnimationEnd);
    }
} 
