import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: addbaby
// Purpose: Show completed survey
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

interface QuestionMetaDataLayout {
    survey_name?: string;
    section_title?: string;
    last_question_num?: number;
    subsection_title?: string;
}

@Component({
    selector: 'app-completedsurvey',
    templateUrl: './completedsurvey.component.html',
    styleUrls: ['./completedsurvey.component.scss'],
})

export class CompletedsurveyComponent implements OnInit {
    // property type declarations
    PageTitle = 'Completed Survey';
    basesurl: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;

    private surveynumber: string;
    private titlemessage: string;
    private baseaurl: string;
    private surveyurl: string;
    private babytalkserver: string;
    private questionnumber: number;
    private metaurl: string;
    private answerurl: string;
    private questionsmeta: any;
    private surveyname: any;
    private lastquestion: any;
    private sectiontitle: string;
    private subsectiontitle: string;
    private answers: any[];
    private questions: any;
    private textanswers: any[];

    wordBoost: boolean;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
        this.wordBoost = environment.wordboost;

    }

    // Initialisation routine
    ngOnInit() {
        this.spinner.show();
        this.surveynumber = this.userInfoService.getViewCompleteSurveyID();
        this.titlemessage = this.userInfoService.getViewCompleteSurveyTitle();
        if (this.userInfoService.getCurrentReviewQuestionNumber() === null) {
            this.questionnumber = 1;
            this.userInfoService.setCurrentReviewQuestionNumber(this.questionnumber);
        } else {
            this.questionnumber = this.userInfoService.getCurrentReviewQuestionNumber();
        }
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.baseaurl = 'https://' + this.babytalkserver + '/v1/';
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;
        if (environment.verboseDebugMode === true) {
            console.log(this.metaurl);
        }

        // ------------------------
        // if (environment.verboseDebugMode === true) { console.log('...'); }
        // get the meta data for the question
        // survey name
        // section title
        // question title
        // total questions in survey

        this.apiService.getDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: QuestionMetaDataLayout[]) => {
                    this.questionsmeta = qmdata;
                    // TODO
                    /*  this.surveyname = qmdata.survey_name;
                      this.lastquestion = qmdata.last_question_num;
                      this.userInfoService.setFinalReviewQuestionNumber(this.lastquestion);

                      if (!qmdata[0].section_title) {
                          this.sectiontitle = '';
                      } else {
                          this.sectiontitle = qmdata[0].section_title.section_title;
                      }
                      if (!qmdata[0].subsection_title) {
                          this.subsectiontitle = '';
                      } else {
                          this.subsectiontitle = qmdata[0].subsection_title.subsection_title;
                      }*/
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });

// ----------------------------------


// -------------- get question data (includes current answer data) ---------------
        this.apiService.getDataFromServer(this.surveyurl)
            .subscribe(
                (data: any[]) => {

                    // getting question data from server...
                    this.questions = data;
                    this.spinner.hide();
                    // add the answer field to the model
                    this.addAnsField();
                    // console.log('got question data:');
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });
    }

    // ----------------- ForwardOneQuestion --------------------------
    forwardOneQuestion() {
        this.lastquestion = this.userInfoService.getFinalReviewQuestionNumber();
        this.questionnumber = this.questionnumber + 1;
        if (this.questionnumber >= this.lastquestion) {
            this.questionnumber = this.lastquestion;
        }
        this.userInfoService.setCurrentReviewQuestionNumber(this.questionnumber);
        this.spinner.show();

        // update new urls
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        // textanswerurl = this.baseaurl + 'textanswers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

        // update the question metadata
        // console.log('getting meta data (Forward one question)...');
        this.apiService.getDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: QuestionMetaDataLayout[]) => {
                    this.questionsmeta = qmdata;
                    // TODO
                    /*
                   this.surveyname = qmdata.survey_name;

                 this.sectiontitle = qmdata[0].section_title.section_title;
                   this.lastquestion = qmdata.last_question_num;
                   this.userInfoService.setFinalReviewQuestionNumber(this.lastquestion);
                   if (!qmdata[0].subsection_title) {
                       this.subsectiontitle = '';
                   } else {
                       this.subsectiontitle = qmdata[0].subsection_title.subsection_title;
                   }*/
                }
            );

        // getting q&a data (Forward one question)...
        this.apiService.getDataFromServer(this.surveyurl)
            .subscribe(
                (data: any[]) => {

                    this.questions = data;
                    this.addAnsField();
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                    // $broadcast('scroll.scrollTop');
                    // $ionicScrollDelegate.scrollTop(false);
                    this.spinner.hide();
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });
    }

    // ----------------- toStart --------------------------
    toStart() {
        // moving to the first question.
        this.questionnumber = 1; // set question number = 1
        this.userInfoService.setCurrentReviewQuestionNumber(this.questionnumber);
        this.spinner.show();

        // update new urls
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

        // update the question metadata
        this.apiService.getDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: any[]) => {
                    this.questionsmeta = qmdata;
                    // TODO
                    /* this.surveyname = qmdata.survey_name;
                     this.lastquestion = qmdata.last_question_num;
                     this.userInfoService.setFinalReviewQuestionNumber(this.lastquestion);
                     this.sectiontitle = qmdata[0].section_title.section_title;
                     if (!qmdata[0].subsection_title) {
                         this.subsectiontitle = '';
                     } else {
                         this.subsectiontitle = qmdata[0].subsection_title.subsection_title;
                     }*/
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });

        // console.log('getting q&a data (first question)...');
        this.apiService.getDataFromServer(this.surveyurl)
            .subscribe(
                (data: any[]) => {

                    this.questions = data;
                    this.addAnsField();
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                    // $broadcast('scroll.scrollTop');
                    // $ionicScrollDelegate.scrollTop(false);
                    this.spinner.hide();
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });
        // badresult = data;
        // alert('error?');
    }

    // ----------------- toEnd --------------------------
    toEnd() {
        // moving to the last question...
        this.lastquestion = this.userInfoService.getFinalReviewQuestionNumber();
        this.questionnumber = this.lastquestion;
        this.userInfoService.setCurrentReviewQuestionNumber(this.questionnumber);
        this.spinner.show();
        // update new urls
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;
        // update the question metadata
        this.apiService.getDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: any[]) => {
                    this.questionsmeta = qmdata;
                    // TODO
                    /* this.surveyname = qmdata.survey_name;
                     this.sectiontitle = qmdata[0].section_title.section_title;
                     this.lastquestion = qmdata.last_question_num;
                     this.userInfoService.setFinalReviewQuestionNumber(this.lastquestion);
                     if (!qmdata[0].subsection_title) {
                         this.subsectiontitle = '';
                     } else {
                         this.subsectiontitle = qmdata[0].subsection_title.subsection_title;
                     }*/
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });

        // console.log('getting q&a data (last question)...');
        this.apiService.getDataFromServer(this.surveyurl)
            .subscribe(
                (data: any[]) => {
                    // console.log('got q&a data (last question)...');
                    this.questions = data;
                    this.addAnsField();
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                    // $broadcast('scroll.scrollTop');
                    // $ionicScrollDelegate.scrollTop(false);

                    this.spinner.hide();

                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });
    }

    // ----------------- backOneQuestion --------------------------
    backOneQuestion() {
        // console.log('moving to the previous question...');
        // now move to the previous question
        this.questionnumber = this.questionnumber - 1;
        if (this.questionnumber == 0) {
            this.questionnumber = 1;
        }
        this.userInfoService.setCurrentReviewQuestionNumber(this.questionnumber);
        this.spinner.show();

        // update new urls
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

        // update the question metadata
        // console.log('getting meta data (back one question)...');
        this.apiService.getDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: any[]) => {
                    this.questionsmeta = qmdata;
                    // TODO
                    /*  this.surveyname = qmdata.survey_name;
                      this.sectiontitle = qmdata[0].section_title.section_title;
                      this.lastquestion = qmdata.last_question_num;
                      this.userInfoService.setFinalReviewQuestionNumber(this.lastquestion);
                      if (!qmdata[0].subsection_title) {
                          this.subsectiontitle = '';
                      } else {
                          this.subsectiontitle = qmdata[0].subsection_title.subsection_title;
                      }*/
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });

        // console.log('getting q&a data (back one question)...');
        this.apiService.getDataFromServer(this.surveyurl)
            .subscribe(
                (data: any[]) => {

                    // console.log('got q&a data (back one question)...');
                    this.questions = data;
                    this.addAnsField();
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                    // $broadcast('scroll.scrollTop');
                    // $ionicScrollDelegate.scrollTop(false);
                    this.spinner.hide();
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                });
    }

    refresh() {
        //    console.log('Show spinner');
        //  console.log('refresh screen');
        // this.spinner.show();
        // $window.location.reload(true);
    }

    trackByFunction(index, item) {
        if (!item) {
            return null;
        }
        return index;
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    showChildrenPage() {
        this.router.navigateByUrl('/mybaby');
    }

    showBabyDetailsPage() {
        // this.userInfoService.setViewBabyName(newbabyname);
        // this.userInfoService.setViewBabyID(newbabyid);
        this.router.navigateByUrl('/babydetails');
    }

    changeradioStatus(questionpartrespopts, optionid) {
        let radiooption;
        // we should be able to work out a bit number from the weight
        // for each option object
        for (radiooption of questionpartrespopts) {
            if (questionpartrespopts[radiooption].weight != (optionid)) {
                // set this one to 0
                // console.log(questionpartrespopts[radiooption].weight + ' : ' + questionpartrespopts[radiooption].ans);
                questionpartrespopts[radiooption].ans = 0;
                // console.log(questionpartrespopts[radiooption].weight + ' : ' + questionpartrespopts[radiooption].ans);
            }
        }
    }

    changeTextBox(txtbox) {
        // if (environment.verboseDebugMode === true) { console.log(checkbox[index]); }
        // for each respnose option, if opti.bool=false, make opti.ans=0. if opti.bool  = true, make ans=weight
        // txtbox.txt =
        // this.questions[index1].response_options[0].txt
        // console.log('changed CheckboxStatus.');
        // if (environment.verboseDebugMode === true) { console.log(option.ans); }
    }

    changeCheckboxStatus2(checkbox, index) {
        // if (environment.verboseDebugMode === true) { console.log(checkbox[index]); }
        // if (environment.verboseDebugMode === true) { console.log(index); }
        // console.log('changing CheckboxStatus...');
        // for each respnose option, if opti.bool=false, make opti.ans=0. if opti.bool  = true, make ans=weight
        if (checkbox.bool == true) {
            checkbox.ans = checkbox.weight;
        } else {
            checkbox.ans = 0;
        }

    }

    // change the checkbox status in the survey
    changeCheckboxStatus(weight, answer) {
        // console.log('changing CheckboxStatus.xxxx..');
        if (answer == true) {
            // console.log('tw:' + parseInt(weight, 10));
            // console.log('ta: ' + answer);
            answer = parseInt(weight, 10);
            // console.log('ta: ' + answer);
            // option.ans = answer;
            return (parseInt(weight, 10));
        } else {
            // console.log('fw:' + parseInt(weight, 10));
            // console.log('fa: ' + answer);
            answer = 0;
            // console.log('fa: ' + answer);
            // option.ans = answer;
            return (0);
        }
    }

    populateTextBoxes() {
        for (let ia = 0, lena = this.questions.length; ia < lena; ia++) {
            // [5].survey_answer['0'].additional_data.additional_data
            if (typeof this.questions[ia].survey_answer[0] != 'undefined') {
                if (this.questions[ia].survey_answer[0] != null) {
                    if (typeof this.questions[ia].survey_answer[0].additional_data != 'undefined') {
                        if (this.questions[ia].survey_answer[0].additional_data != null) {
                            // if (environment.verboseDebugMode === true) { console.log(this.questions[ia].survey_answer[0].additional_data); }
                            if (typeof this.questions[ia].survey_answer[0].additional_data.additional_data != 'undefined') {
                                if (typeof this.questions[ia].survey_answer[0].additional_data.additional_data != null) {
                                    // console.log(ia, this.questions[ia].survey_answer[0].additional_data.additional_data);
                                    this.questions[ia].response_options[0].txt =
                                        this.questions[ia].survey_answer[0].additional_data.additional_data;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    parseCheckboxes() {
        // console.log('parsing check boxes');
        // for each question
        // handling ANS fields
        for (let ia = 0, lena = this.questions.length; ia < lena; ia++) {
            // each response option
            // each response : should only be 1 survey answer
            if (this.questions[ia].survey_answer[0] != null) {
                for (let ic = 0, lenc = this.questions[ia].survey_answer[0].length; ic < lenc; ic++) {
                    // each option : may be none!
                    for (let id = 0, lend = this.questions[ia].survey_answer[0].answered_options.length; id < lend; id++) {
                        for (let ib = 0, lenb = this.questions[ia].response_options.length; ib < lenb; ib++) {
                            // each available response - does it match our answer?
                            if (this.questions[ia].survey_answer[ic].answered_options[id].id == this.questions[ia].response_options[ib].id) {
                                // if so, set the ans field
                                this.questions[ia].response_options[ic].ans = parseInt(this.questions[ia].survey_answer[ic].weight, 10);
                                // NaN!
                            }
                        }
                    }
                }
            }
        }

        // handle boolean fields
        for (let i = 0, len = this.questions.length; i < len; i++) {
            for (let ii = 0, leni = this.questions[i].response_options.length; ii < leni; ii++) {
                this.questions[i].response_options[ii].bool = false;
                // console.log('ans=', this.questions[i].response_options[ii].ans);
                if (this.questions[i].response_options[ii].ans > 0) {
                    this.questions[i].response_options[ii].bool = true;
                } else {
                    this.questions[i].response_options[ii].bool = false;
                }
            }
        }
        // console.log('parsed check boxes');
    }

    addAnsField() {
        // console.log('ADDING ANS FIELD');
        // add the ans field into the model
        let index1a = 0;

        let index2a = 0;
        let len1a = 0;
        let len2a = 0;
        let index3a = 0;
        let len3a = 0;
        // if (environment.verboseDebugMode === true) { console.log(questions); }
        // for each question object
        for (index1a = 0, len1a = this.questions.length; index1a < len1a; ++index1a) {
            // console.log(index1, this.questions.length);
            // for each possible response
            for (index2a = 0, len2a = this.questions[index1a].response_options.length; index2a < len2a; ++index2a) {
                this.questions[index1a].response_options[index2a]['ans'] = 0;
                // for each actual response
                // console.log('scope.questions[index1a].survey_answer.length', this.questions[index1a].survey_answer.length);
                if (this.questions[index1a].survey_answer.length > 0) {
                    for (index3a = 0, len3a = this.questions[index1a].survey_answer[0].answered_options.length; index3a < len3a; ++index3a) {
                        // console.log(index1a, ' ', index2a, ' ', this.questions[index1a].survey_answer[0].answered_options[index3a].id,
                        // '=', this.questions[index1a].response_options[index2a].id);
                        // if (environment.verboseDebugMode === true) { console.log(this.questions[index1a].response_options[index2a].id); }

                        // scan all responses; if the id of the response is equal to the id of the possible response, set the weight.
                        if ((this.questions[index1a].survey_answer[0].answered_options[index3a].id) == this.questions[index1a].response_options[index2a].id)
                        // make ans equal to the weight.
                        {
                            // this.questions[index1a].response_options[index2a]['ans']=2;
                            this.questions[index1a].response_options[index2a]['ans'] = parseInt(this.questions[index1a].response_options[index2a]['weight'], 10);
                            // console.log(index1a, 'SET ', parseInt(this.questions[index1a].response_options[index2a]['weight'], 10));
                        }
                    }
                }
                // console.log('set default ' + index1a + '(' + index2a + ')');
                // sum = sum ? sum : 0;
            }
        }
        // if (environment.verboseDebugMode === true) { console.log(questions); }
        // console.log('Added ANS FIELD');
    }

    encodeAnswers() {
        // console.log('encoding answer data...');
        this.answers = [];
        this.textanswers = [];
        let sum = 0;
        let index1 = 0;
        let index2 = 0;
        let len1 = 0;
        let len2 = 0;
        // if (environment.verboseDebugMode === true) { console.log(questions); }
        for (index1 = 0, len1 = this.questions.length; index1 < len1; ++index1) {
            // if (environment.verboseDebugMode === true) { console.log(len); }
            sum = 0;
            for (index2 = 0, len2 = this.questions[index1].response_options.length; index2 < len2; ++index2) {
                sum = sum + parseInt(this.questions[index1].response_options[index2].ans, 10);
                sum = sum ? sum : 0;
            }
            this.answers.push(this.questions[index1].id, sum);
            // console.log(index1, this.questions[index1]);
            // [5].response_options['0'].option_type
            if (this.questions[index1].response_options[0].option_type == 'text') {
                if (this.questions[index1].response_options[0].txt != null) {
                    this.textanswers.push(this.questions[index1].id, this.questions[index1].response_options[0].txt);
                } else {
                    this.textanswers.push(this.questions[index1].id, '');
                }
            }
        }
        // if (environment.verboseDebugMode === true) { console.log('encoded.'); }
        // if (environment.verboseDebugMode === true) { console.log(answers); }
        // if (environment.verboseDebugMode === true) { console.log(textanswers); }
    }

    showDatePretty(datetime) {
        if (datetime == undefined) {
            datetime = '';
        }
        // Split timestamp into [ Y, M, D, h, m, s ]
        let t = datetime.split(/[- :]/);
        // Apply each element to the Date function
        // let d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        let d = new Date(Date.UTC(t[0], t[1] - 1, t[2]));
        return (d);
    }

    decodeExistingAnswers() {
        // console.log('decoding existing answer data...');
        this.answers = [];
        let sum = 0;
        let index1 = 0;
        let index2 = 0;
        let len1 = 0;
        let len2 = 0;
        for (index1 = 0, len1 = this.questions.length; index1 < len1; ++index1) {
            // if (environment.verboseDebugMode === true) { console.log(len); }
            sum = 0;
            for (index2 = 0, len2 = this.questions[index1].response_options.length; index2 < len2; ++index2) {
                sum = sum + parseInt(this.questions[index1].response_options[index2].ans, 10);
                sum = sum ? sum : 0;
            }
            this.answers.push(this.questions[index1].id, sum);
        }
        // if (environment.verboseDebugMode === true) { console.log('decoded.'); }
    }

    // Called when the Question form is submitted - disabled for reviewing  ----------------------
    // submitAnswers(questionnumber) {
    submitAnswers() {
        // encode answers
        this.encodeAnswers();
        // console.log('============submitting. meta', metaurl);
        // console.log('answer', answerurl);
        // console.log('survey', surveyurl);
        // show spinner
        this.spinner.show();
        // taskModal.hide();
        // task.title = '';
        // alert('submit ' & this.questionnumber);
        // build object containing all answers
        // send object to server as JSON
        // stoken = '';
        // let stoken = '';

        let dataobject = {};
        dataobject['selections'] = this.answers;
        dataobject['text'] = this.textanswers;

        // console.log('dataobject', dataobject);

        // post answers to server
        /* $http({
         method: 'POST',
         url: answerurl,
         data: dataobject
         })
         .then(function (headers) {
       // console.log('===answers posted.');
       // console.log('response  ', headers.status, headers.statusText);
       // console.log('response  ', headers);
         this.spinner.hide();

         // now move to the next question
         this.questionnumber = this.questionnumber + 1;
         this.userInfoService.setCurrentReviewQuestionNumber(questionnumber);
         // update server

         // if that was the last question, go to 'finished' page
         if (questionsmeta.is_last) {

         // send finalised signal to server
         // updateServerProgress(surveynumber, this.questionnumber);

         selectSurveyToFinaliseService.addSurveySelection(surveynumber);
         finishsurveynum = this.surveynumber;

         // console.log('to finalise  ', this.surveynumber);

         this.router.navigateByUrl('/finished');


         }

         this.spinner.show();

         // update new urls
         surveyurl = basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
         answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
         metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

         // update the question metadata
         // console.log('===getting question metadata.');
         this.apiService.getDataFromServer(metaurl)
                .subscribe(
                    (data: any[]) => {
         (
         function (qmdata) {
       // console.log('===got question metadata.');
       // console.log('qmdata  ', qmdata);
         questionsmeta = qmdata;
         surveyname = qmdata.survey_name;
         lastquestion = qmdata.last_question_num;
         this.userInfoService.setFinalReviewQuestionNumber(lastquestion);
       // if (environment.verboseDebugMode === true) { console.log(lastquestion); }
         // sectiontitle = qmdata[0].section_title.section_title;

         if (!qmdata[0].section_title) {
         sectiontitle = '';
         }
         else {
         sectiontitle = qmdata[0].section_title.section_title;
         }

         if (!qmdata[0].subsection_title) {
         subsectiontitle = '';
         }
         else {
         subsectiontitle = qmdata[0].subsection_title.subsection_title;
         }

         }
         );

       // console.log('getting question+answer data from server (postsubmit)...');
         // get question data
         this.apiService.getDataFromServer(surveyurl)
                .subscribe(
                    (data: any[]) => {
         (
         function (data) {
       // console.log('===got question+answer data(postsubmit)');
         questions = data;
         // add the answer field to the model
         // if (environment.verboseDebugMode === true) { console.log(questions); }
       // console.log('adding ans field (postsubmit)...');
         this.addAnsField();
         this.parseCheckboxes();
         this.populateTextBoxes();
       // if (environment.verboseDebugMode === true) { console.log(questions); }
         $ionicScrollDelegate.scrollTop(false);
         this.spinner.hide();

         }
         );

         }, function (data, status, headers, config) {
         // this assumes invalid token but needs proper error handling - AR

         badresult = data;
       // console.log('error submitting answers', badresult['error']);

         // execute this code on error

         // error callback
         this.spinner.hide();
         switch (status) {
         case 400:
         alert('Error 400 : Bad Request');
         this.router.navigateByUrl('/buttons');
         break;
         case 401:
         this.router.navigateByUrl('/auth');
         break;
         case 403:
         alert('Error 403 : Forbidden');
         this.router.navigateByUrl('/buttons');
         break;
         case 404:
         alert('Error 404 : Not found');
         this.router.navigateByUrl('/buttons');
         break;
         case 405:
         alert('Error 405 : Method Not Allowed');
         this.router.navigateByUrl('/buttons');
         break;
         case 406:
         alert('Error 406 : Not Acceptable');
         this.router.navigateByUrl('/buttons');
         break;
         case 407:
         alert('Error 407 : Proxy Auth Error');
         this.router.navigateByUrl('/buttons');
         break;
         case 408:
         alert('Error 408 : Request Timeout');
         this.router.navigateByUrl('/buttons');
         break;
         case 500:
         alert('500: Internal Server Error');
         this.router.navigateByUrl('/buttons');
         break;
         case 501:
         alert('Error 501 : Not Implemented');
         this.router.navigateByUrl('/buttons');
         break;
         case 502:
         alert('Error 502 : Cannot reach server!');
         this.router.navigateByUrl('/buttons');
         break;
         case 503:
         alert('Error 503: Cannot reach server!');
         this.router.navigateByUrl('/buttons');
         break;
         case 504:
         alert('Server Error 504: Gateway Timeout');
         this.router.navigateByUrl('/buttons');
         break;

         default:
         alert('Error ' + status);
         this.router.navigateByUrl('/buttons');
         } // switch
         } // function
         // -------

         ) // then*/
        // console.log('===========end of submitting.');

    } // submitanswers function

}
