import {Component, Input, OnInit} from '@angular/core';
import * as D3 from 'd3';

declare let d3: any;

// Babytalk Client V2
// Component: wordcloud
// Purpose: component to generate a word cloud
// Porting Status: Ported

@Component({
    selector: 'app-wordcloud',
    template: `
        <div class="word-cloud"></div>`,
    styleUrls: ['./wordcloud.component.scss'],
})
export class WordCloudComponent implements OnInit {

    @Input() wordData;
    data = [];
    tempData = [];
// Some sample data - http://en.wikiquote.org/wiki/Opening_lines

    words = [
        'You dont know about me without you have read a book called The Adventures of Tom Sawyer but that aint no matter.',
        'The boy with fair hair lowered himself down the last few feet of rock and began to pick his way toward the lagoon.',
        'When Mr. Bilbo Baggins of Bag End announced that he would shortly be celebrating his eleventy-first birthday with a ' +
        'party of special magnificence, there was much talk and excitement in Hobbiton.',
        'It was inevitable: the scent of bitter almonds always reminded him of the fate of unrequited love.'
    ];
// Create a new instance of the word cloud visualisation.
    myWordCloud = this.wordCloud('body');

// Simple animated example of D3-cloud - https://github.com/jasondavies/D3-cloud
// Based on https://github.com/jasondavies/D3-cloud/blob/master/examples/simple.html
    private height: number;     // Component height
    private fillScale;          // D3 scale for text color

    constructor() {
    }

// Prepare one of the sample sentences by removing punctuation,

    ngOnInit() {
        // Start cycling through the demo data
        //  this.showNewWords(this.myWordCloud, 1);
    }

// This method tells the word cloud to redraw with a new set of words.
// In reality the new words would probably come from a server request,
// Encapsulate the word cloud functionality
    wordCloud(selector) {
        const fill = D3.scale.category20();

        // Construct the word cloud's SVG element
        const svg = D3.select(selector).append('svg')
            .attr('width', 500)
            .attr('height', 500)
            .append('g')
            .attr('transform', 'translate(250,250)');

        /*       function draw(words: any) {
                  console.log('draw function');
                  const cloud = svg.selectAll('g text')
                      .data(words, d => d.text);

                  // Entering words
                  cloud.enter()
                      .append('text')
                      .style('font-family', 'Impact')
                      .style('fill', (d, i) => fill(i))
                      .attr('text-anchor', 'middle')
                      .attr('font-size', 1)
                      .text(d => d.text);

                  // Entering and existing words
                  cloud.transition()
                      .duration(600)
                      .style('font-size', d => d.size + 'px')
                      .attr('transform', d => 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')')
                      .style('fill-opacity', 1);

                  // Exiting words
                  cloud.exit()
                      .transition()
                      .duration(200)
                      .style('fill-opacity', 1e-6)
                      .attr('font-size', 1)
                      .remove();
              }

              // Use the module pattern to encapsulate the visualisation code. We'll
              //  expose only the parts that need to be public.
              return {
                  // Recompute the word cloud for a new set of words. This method will
                  //  asycnhronously call draw when the layout has been computed.
                  // The outside world will need to call this function, so make it part
                  //  of the wordCloud return value.
                  update: words => {
                      if (environment.verboseDebugMode === true) { console.log('update'); }
                      d3.layout.cloud.size([500, 500])
                          .words(words)
                          .padding(5)
                          .rotate(() => ~~(Math.random() * 2) * 90)
                          .font('Impact')
                          .fontSize(d => d.size)
                          .on('end', draw)
                          .start();
                  }
              };
          }

      //  creating an array of words and computing a random size attribute.
          getWords(i) {
              return (this.words)[i]
                  .replace(/[!.,:;?]/g, '')
                  .split(' ')
                  .map(d => ({text: d, size: 10 + Math.random() * 60}));
          }

      //  user input or some other source.
          showNewWords(vis, i) {
              i = i || 0;

              vis.update(this.getWords(i++ % this.words.length));
              setTimeout(() => {
                  this.showNewWords(vis, i + 1);
              }, 2000);  */
    }
}

