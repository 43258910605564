import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {SelectSurveyService} from '../select-survey.service';
import {environment} from '../../environments/environment';
import {QuestionsLayout, QuestionsMetaLayout} from '../interfaces';
import {Storage} from '@ionic/storage';
import {UserInfoService} from '../user-info.service';
import {Platform} from '@ionic/angular';

// Babytalk Client V2
// Component: questions
// Purpose: render the current question page of the assigned survey
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {
    // property type declarations
    public PageTitle = 'Survey';
    public basesurl: string; // base server url
    public surveynumber: number;
    public surveyurl: string;
    public answerurl: string;
    public suffixurl: string;
    public metaurl: string;
    public baseaurl: string;
    public questionnumber: number;
    public questions: QuestionsLayout[];
    public questionsmeta: QuestionsMetaLayout;
    public qmdata: QuestionsMetaLayout;
    public surveyname: string;
    public lastquestion: number;
    public sectiontitle: string;
    public subsectiontitle: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: any;
    successmessage: string;
    selectedbaby: any;
    babydata: any;
    val: any;
    private answers: any[];
    private textanswers: any;
    private finishsurveynum: number | string;
    private badresult: any;
    private DATA_URL: string;
    private selectedBabyData: any;
    private babyname: string;
    private babyid: string;
    private screen_width: any;
    wordBoost: boolean;

    // constructor
    constructor(public router: Router,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                public selectSurveyToFinaliseService: SelectSurveyService,
                private route: ActivatedRoute,
                public storage: Storage,
                private userInfoService: UserInfoService,
                platform: Platform) {

        this.wordBoost = environment.wordboost;

        platform.ready().then((readySource) => {
            this.screen_width = platform.width();
            // console.log('Height: ' + platform.height());
        });
    }

    // Initialisation routine
    ngOnInit() {
        console.log('Questions: in OnInit');


        // get data from resolver
        // if (environment.verboseDebugMode === true) { console.log(this.route.snapshot.data); }
        this.storage.get('selectedbaby').then((val) => {
                this.selectedBabyData = val[0];

                // ---
                this.questionnumber = this.userInfoService.getCurrentQuestionNumber();
                this.lastquestion = this.userInfoService.getFinalQuestionNumber();
                console.log('currentq:', this.questionnumber);
                console.log('finalq:', this.lastquestion);


                // ----

                console.log('selectedBaby', val[0]);
                // this.setBabyData();
                this.babyname = this.selectedBabyData.baby_name;
                this.babyid = this.selectedBabyData.id;
                this.qmdata = this.route.snapshot.data.qmdata;
                console.log('QMdata', this.qmdata[0]);
                if (!this.qmdata[0]) {
                    console.log('Error with meta data');
                } else {
                    console.log('meta data');
                if (environment.verboseDebugMode === true) {
                    console.log(this.qmdata[0]);
                }
            }

            this.questionsmeta = this.route.snapshot.data.qmdata;
            console.log('Data :', this.questionsmeta[0]);

            this.PageTitle = this.questionsmeta.survey_name;
            this.lastquestion = this.qmdata.last_question_num;

            //  const que = this.questionsmeta[0].section_title;
            //  console.log('que', que);

            if (environment.verboseDebugMode === true) {
                console.log('>>>>>>>>>>>>>>');
            }
            if (environment.verboseDebugMode === true) {
                console.log(this.qmdata);
            }

            if (!this.questionsmeta[0].section_title) {
                this.sectiontitle = '';
            } else {
                this.sectiontitle = this.questionsmeta[0].section_title.section_title;
            }

            if (!this.questionsmeta[0].subsection_title.subsection_title) {
                console.log('If Condition');
                this.subsectiontitle = '?';
            } else {
                console.log('Else Condition');
                if (environment.verboseDebugMode === true) {
                    console.log(this.questionsmeta[0].subsection_title.subsection_title);
                }
                console.log('subsection_title', this.questionsmeta[0].subsection_title.subsection_title);
                this.subsectiontitle = this.questionsmeta[0].subsection_title.subsection_title;
            }

            if (environment.verboseDebugMode === true) {
                console.log(this.babyid);
            }
            // Is a baby selected?
            if (this.babyid != undefined) {
                // if so:
                // get current survey number and question number if they exist
                this.spinner.show();
                this.surveynumber = this.userInfoService.getCurrentSurvey();
                if (this.userInfoService.getCurrentQuestionNumber() === null) {
                    this.questionnumber = 1;
                    this.userInfoService.setCurrentQuestionNumber(this.questionnumber);
                } else {
                    this.questionnumber = this.userInfoService.getCurrentQuestionNumber();
                }
                console.log('Q num');
                if (environment.verboseDebugMode === true) {
                    console.log(this.questionnumber);
                }
                console.log('SRV num');
                if (environment.verboseDebugMode === true) {
                    console.log(this.surveynumber);
                }

                // define URLs
                this.basesurl = 'https://' + environment.serverUrl + '/v1/';
                this.baseaurl = 'https://' + environment.serverUrl + '/v1/';
                this.suffixurl = this.surveynumber + '?q=' + this.questionnumber;
                this.surveyurl = this.basesurl + 'surveys/' + this.suffixurl;
                this.answerurl = this.baseaurl + 'answers/' + this.suffixurl;
                this.metaurl = this.basesurl + 'qmetadata/' + this.suffixurl; // debugging note : these undefined?

                // get the meta data for the question
                // survey name
                // section title
                // question title
                // total questions in survey

                // ---------------------------------------------
                console.log('subscribing to metadata...');
                this.apiService.getQuestionMetaDataFromServer(this.metaurl)
                    .subscribe(
                        (qmdata: QuestionsMetaLayout) => {
                            this.spinner.hide();
                            console.log('META: ');
                            console.log(this.metaurl);
                            this.questionsmeta = qmdata;
                            // todo this.surveyname = qmdata.survey_name;
                            // todo this.lastquestion = qmdata.last_question_num;
                            this.userInfoService.setFinalQuestionNumber(this.lastquestion);

                            // avoid 'Cannot read property 'section_title' of undefined'
                            if (!this.questionsmeta[0].section_title.section_title) {
                                this.sectiontitle = '';
                            } else {
                                this.sectiontitle = this.questionsmeta[0].section_title.section_title;
                            }
                            if (!this.questionsmeta[0].subsection_title.subsection_title) {
                                this.subsectiontitle = '';
                            } else {
                                this.subsectiontitle = this.questionsmeta[0].subsection_title.subsection_title;
                            }

                        }, error => {
                            this.spinner.hide();
                            if (environment.verboseDebugMode === true) {
                                console.log(error);
                            }
                            // this.errors = error;
                        }
                    );

                // get question data (includes current answer data) ---------------
                this.apiService.getSurveyDataFromServer(this.surveyurl)
                    .subscribe(
                        (data: QuestionsLayout[]) => {
                            this.spinner.hide();
                            this.questions = data;
                            if (environment.verboseDebugMode === true) {
                                console.log('pppppppppppp');
                            }
                            if (environment.verboseDebugMode === true) {
                                console.log(this.questions);
                            }
                            if (environment.verboseDebugMode === true) {
                                console.log(this.surveyurl);
                            }
                            this.spinner.hide();
                            this.addAnsField();
                            this.parseCheckboxes();
                            this.populateTextBoxes();
                        }, error => {
                            this.spinner.hide();
                            if (environment.verboseDebugMode === true) {
                                console.log(error);
                            }
                            // this.errors = error;
                        }
                    );
            } else {
                // console.log('baby not selected');
                this.router.navigateByUrl('/nobaby');
            }
        }, (error) => {
            if (environment.verboseDebugMode === true) {
                console.log(error);
            }
            this.router.navigateByUrl('/nobaby');
        }
        );

    }

    ionViewWillEnter() {
        console.log('ionViewWillEnter ');
        this.getUserInfo();
    }


    getUserInfo() {
        //        this.storage.get('userInfo').then((val) => {
        //          this.uid = val.UserID;
        //        this.UserData = val
        //      console.log("USER INFO", this.UserData);
        // });


    }

    trackByFn(index, item) {
        return index; // or item.id
    }

    forwardOneQuestion() {
        this.lastquestion = this.userInfoService.getFinalQuestionNumber();
        this.questionnumber = this.questionnumber + 1;
        if (this.questionnumber >= this.lastquestion) {
            this.questionnumber = this.lastquestion;
        }
        this.userInfoService.setCurrentQuestionNumber(this.questionnumber);
        this.spinner.show();

        // update new urls
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

        // update the question metadata
        // console.log('getting meta data (Forward one question)...');

        this.apiService.getQuestionMetaDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: QuestionsMetaLayout) => {
                    this.questionsmeta = qmdata;
                    // todo this.surveyname = qmdata.survey_name;
                    // todo this.lastquestion = qmdata.last_question_num;
                    this.userInfoService.setFinalQuestionNumber(this.lastquestion);

                    // avoid 'Cannot read property 'section_title' of undefined'
                    if (!this.questionsmeta[0].section_title.section_title) {
                        this.sectiontitle = '';
                    } else {
                        this.sectiontitle = this.questionsmeta[0].section_title.section_title;
                    }
                    if (!this.questionsmeta[0].subsection_title.subsection_title) {
                        this.subsectiontitle = '';
                    } else {
                        this.subsectiontitle = this.questionsmeta[0].subsection_title.subsection_title;
                    }

                }
            );

        // console.log('getting q&a data (Forward one question)...');
        this.apiService.getSurveyDataFromServer(this.surveyurl)
            .subscribe(
                (data: QuestionsLayout[]) => {

                    // console.log('got q&a data (Forward one question)...');
                    this.questions = data;
                    this.addAnsField();
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                    // $broadcast('scroll.scrollTop');
                    // $ionicScrollDelegate.scrollTop(false);
                    this.spinner.hide();
                }
            );
        // badresult = data;
        // alert('error?');
    }

    toStart() {
        // console.log('moving to the first question...');
        this.questionnumber = 1;
        this.userInfoService.setCurrentQuestionNumber(this.questionnumber);
        this.spinner.show();

        // update new urls
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

        // update the question metadata
        // console.log('getting meta data (first question)...');
        this.apiService.getQuestionMetaDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: QuestionsMetaLayout) => {
                    this.surveyname = qmdata[0].survey_name;
                    this.questionsmeta = qmdata;
                    // todo this.surveyname = qmdata.survey_name;
                    // todo this.lastquestion = qmdata.last_question_num;
                    this.userInfoService.setFinalQuestionNumber(this.lastquestion);
                    this.lastquestion = qmdata.last_question_num;
                    this.userInfoService.setFinalQuestionNumber(this.lastquestion);
                    // avoid 'Cannot read property 'section_title' of undefined'
                    if (!this.questionsmeta[0].section_title.section_title) {
                        this.sectiontitle = '';
                    } else {
                        this.sectiontitle = this.questionsmeta[0].section_title.section_title;
                    }
                    if (!this.questionsmeta[0].subsection_title.subsection_title) {
                        this.subsectiontitle = '';
                    } else {
                        this.subsectiontitle = this.questionsmeta[0].subsection_title.subsection_title;
                    }

                }
            );

        // console.log('getting q&a data (first question)...');
        this.apiService.getSurveyDataFromServer(this.surveyurl)
            .subscribe(
                (data: QuestionsLayout[]) => {
                    this.questions = data;
                    this.addAnsField();
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                    // $ionicScrollDelegate.scrollTop(false);
                    this.spinner.hide();
                }
            );
        // badresult = data;
        // alert('error?');
    }

    toEnd() {
        // console.log('moving to the last question...');
        this.lastquestion = this.userInfoService.getFinalQuestionNumber();

        // console.log('moving to the next question...');
        this.questionnumber = this.lastquestion;
        this.userInfoService.setCurrentQuestionNumber(this.questionnumber);
        this.spinner.show();

        // update new urls
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

        // update the question metadata
        // console.log('getting meta data (last question)...');
        this.apiService.getQuestionMetaDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: QuestionsMetaLayout) => {

                    this.questionsmeta = qmdata;
                    // todo this.surveyname = qmdata.survey_name;
                    // todo this.lastquestion = qmdata.last_question_num;
                    this.userInfoService.setFinalQuestionNumber(this.lastquestion);

                    // avoid 'Cannot read property 'section_title' of undefined'
                    if (!this.questionsmeta[0].section_title.section_title) {
                        this.sectiontitle = '';
                    } else {
                        this.sectiontitle = this.questionsmeta[0].section_title.section_title;
                    }
                    if (!this.questionsmeta[0].subsection_title.subsection_title) {
                        this.subsectiontitle = '';
                    } else {
                        this.subsectiontitle = this.questionsmeta[0].subsection_title.subsection_title;
                    }

                }
            );

        // console.log('getting q&a data (last question)...');
        this.apiService.getSurveyDataFromServer(this.surveyurl)
            .subscribe(
                (data: QuestionsLayout[]) => {

                    // console.log('got q&a data (last question)...');
                    this.questions = data;
                    this.addAnsField();
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                    // $broadcast('scroll.scrollTop');
                    // $ionicScrollDelegate.scrollTop(false);
                    this.spinner.hide();
                }
            );
        // badresult = data;
        // alert('error?');
    }

    backOneQuestion() {
        // console.log('moving to the previous question...');
        // now move to the previous question
        this.questionnumber = this.questionnumber - 1;

        if (this.questionnumber == 0) {
            this.questionnumber = 1;
        }
        this.userInfoService.setCurrentQuestionNumber(this.questionnumber);
        this.spinner.show();

        // update new urls
        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

        // update the question metadata
        // console.log('getting meta data (back one question)...');
        this.apiService.getQuestionMetaDataFromServer(this.metaurl)
            .subscribe(
                (qmdata: QuestionsMetaLayout) => {
                    this.questionsmeta = qmdata;
                    // todo this.surveyname = qmdata.survey_name;
                    // todo this.lastquestion = qmdata.last_question_num;
                    this.userInfoService.setFinalQuestionNumber(this.lastquestion);

                    // avoid 'Cannot read property 'section_title' of undefined'
                    if (!this.questionsmeta[0].section_title.section_title) {
                        this.sectiontitle = '';
                    } else {
                        this.sectiontitle = this.questionsmeta[0].section_title.section_title;
                    }
                    if (!this.questionsmeta[0].subsection_title.subsection_title) {
                        this.subsectiontitle = '';
                    } else {
                        this.subsectiontitle = this.questionsmeta[0].subsection_title.subsection_title;
                    }

                }
            );

        // console.log('getting q&a data (back one question)...');
        this.apiService.getSurveyDataFromServer(this.surveyurl)
            .subscribe(
                (data: QuestionsLayout[]) => {

                    this.questions = data;
                    this.addAnsField();
                    this.parseCheckboxes();
                    this.populateTextBoxes();
                    // $broadcast('scroll.scrollTop');
                    // $ionicScrollDelegate.scrollTop(false);
                    this.spinner.hide();
                }
            );
        // badresult = data;
        // alert('error?');
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();
        // $window.location.reload(true);
    }

    showSelectSurveyPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/selectsurv');
    }

    changeradioStatus2(btn, questionpartrespopts, option) {
        if (environment.verboseDebugMode === true) {
            console.log('changeradioStatus2');
        }
        if (environment.verboseDebugMode === true) {
            console.log('btn=');
        }
        if (environment.verboseDebugMode === true) {
            console.log(btn);
        }
        if (environment.verboseDebugMode === true) {
            console.log(questionpartrespopts);
        }
        console.log(' changed Option ID:');
        if (environment.verboseDebugMode === true) {
            console.log(option.weight);
        }
        let radiooption;
        // for each option object
        for (radiooption of questionpartrespopts) {

            if (radiooption.weight != (option.weight)) {
                console.log('weight doesnt match optionid:');
                if (environment.verboseDebugMode === true) {
                    console.log(option.weight);
                }
                // set this one to 0
                //
                // questionpartrespopts[radiooption].ans = 0;
                console.log('setting ans to 0');
                radiooption.ans = 0;
                radiooption.bool = false;
            } else {
                console.log('weight matches option.id:');
                console.log('Setting radiooption.ans to ' + radiooption.weight);
                radiooption.bool = true;
                radiooption.ans = radiooption.weight;
            }


            console.log('now color is' + btn.color);
            if (environment.verboseDebugMode === true) {
                console.log('model:');
            }
            if (environment.verboseDebugMode === true) {
                console.log(radiooption);
            }


            /*  console.log('processing radiooption id = ' + radiooption.id);
              // if (questionpartrespopts[radiooption].weight != (optionid)) {
              console.log(optionid + '  ' + radiooption.weight)
              if (radiooption.weight != (optionid)) {
                  console.log('weight doesnt match optionid:');
                  if (environment.verboseDebugMode === true) { console.log(optionid); }
                  // set this one to 0
                  //                 //questionpartrespopts[radiooption].ans = 0;
                  console.log('setting ans to 0');
                  radiooption.ans = 0;

              } else {
                  console.log('weight matches optionid:');
                  console.log('Setting radiooption.ans to ' + radiooption.weight);

                  radiooption.ans = radiooption.weight;
              }*/
        }
    }

    changeradioStatus(questionpartrespopts, optionid) {
        if (environment.verboseDebugMode === true) {
            console.log('changeradioStatus');
        }
        console.log('question part response options=');
        if (environment.verboseDebugMode === true) {
            console.log(questionpartrespopts);
        }
        console.log(' changed Option ID:');
        if (environment.verboseDebugMode === true) {
            console.log(optionid);
        }

        let radiooption;
        // for each option object
        for (radiooption of questionpartrespopts) {
            console.log('processing radiooption id = ' + radiooption.id);
            // if (questionpartrespopts[radiooption].weight != (optionid)) {
            console.log(optionid + '  ' + radiooption.weight);
            if (radiooption.weight != (optionid)) {
                console.log('weight doesnt match optionid:');
                if (environment.verboseDebugMode === true) {
                    console.log(optionid);
                }
                // set this one to 0
                //                 //questionpartrespopts[radiooption].ans = 0;
                console.log('setting ans to 0');
                radiooption.ans = 0;

            } else {
                console.log('weight matches optionid:');
                console.log('Setting radiooption.ans to ' + radiooption.weight);

                radiooption.ans = radiooption.weight;
            }
        }
    }

    changeTextBox(txtbox) {
        if (environment.verboseDebugMode === true) {
            console.log('changeTextbox');
        }
        // if (environment.verboseDebugMode === true) { console.log(checkbox[index]); }
        // if (environment.verboseDebugMode === true) { console.log(index); }
        // console.log('changing textbox Status...');
        // if (environment.verboseDebugMode === true) { console.log(txtbox.txt); }
        // for each respnose option, if opti.bool=false, make opti.ans=0. if opti.bool  = true, make ans=weight
        // txtbox.txt =
        // questions[index1].response_options[0].txt
        // console.log('changed CheckboxStatus.');
        // if (environment.verboseDebugMode === true) { console.log(option.ans); }
    }

    //    changeCheckboxStatus2(checkbox, index) {
    changeCheckboxStatus2(checkbox) {
        if (environment.verboseDebugMode === true) {
            console.log('changeCheckboxStatus2');
        }


        // for each respnose option, if opti.bool=false, make opti.ans=0. if opti.bool  = true, make ans=weight
        if (checkbox.bool == true) {
            checkbox.ans = checkbox.weight;
        } else {
            checkbox.ans = 0;
        }
    }

    changeBtnStatus2(btn, modl) {
        if (environment.verboseDebugMode === true) {
            console.log('changeBtnStatus2');
        }
        // for each respnose option, if opti.bool=false, make opti.ans=0. if opti.bool  = true, make ans=weight
        console.log('current color is ' + btn.color);
        if (btn.color == 'light') {
            // set to selected
            if (environment.verboseDebugMode === true) {
                console.log('==light');
            }
            // btn.color = 'success';
            modl.ans = modl.weight;

            modl.bool = true;
        } else {
            // deselect
            if (environment.verboseDebugMode === true) {
                console.log('=success');
            }
            // btn.color = 'light';
            modl.ans = 0;
            modl.bool = false;
        }
        console.log('now color is' + btn.color);
        if (environment.verboseDebugMode === true) {
            console.log('model:');
        }
        if (environment.verboseDebugMode === true) {
            console.log(modl);
        }
        if (environment.verboseDebugMode === true) {
            console.log('btn:');
        }
        if (environment.verboseDebugMode === true) {
            console.log(btn);
        }

        /*   if (checkbox.bool == true) {
               checkbox.ans = checkbox.weight;
           } else {
               checkbox.ans = 0;
           } */
    }

    // change the checkbox status in the survey
    changeCheckboxStatus(weight, answer) {
        if (environment.verboseDebugMode === true) {
            console.log('changeCheckboxStatus');
        }
        if (answer == true) {
            //  answer = parseInt(weight, 10);
            return (parseInt(weight, 10));
        } else {
            // answer = 0;weight
            return (0);
        }
    }

    populateTextBoxes() {
        for (let ia = 0, lena = this.questions.length; ia < lena; ia++) {
            // [5].survey_answer['0'].additional_data.additional_data
            if (typeof this.questions[ia].survey_answer[0] != 'undefined') {
                if (this.questions[ia].survey_answer[0] != null) {
                    if (typeof this.questions[ia].survey_answer[0].additional_data != 'undefined') {
                        if (this.questions[ia].survey_answer[0].additional_data != null) {
                            // if (environment.verboseDebugMode === true) { console.log(this.questions[ia].survey_answer[0].additional_data); }
                            if (typeof this.questions[ia].survey_answer[0].additional_data.additional_data != 'undefined') {
                                if (typeof this.questions[ia].survey_answer[0].additional_data.additional_data != null) {
                                    // console.log(ia, this.questions[ia].survey_answer[0].additional_data.additional_data);
                                    this.questions[ia].response_options[0].txt = this.questions[ia].survey_answer[0].additional_data.additional_data;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    parseCheckboxes() {
        console.log('parsing check boxes');
        // for each question
        // console.log('handling ANS fields');
        for (let ia = 0, lena = this.questions.length; ia < lena; ia++) {
            // each response option
            // each response : should only be 1 survey answer
            if (this.questions[ia].survey_answer[0] != null) {
                for (let ic = 0, lenc = this.questions[ia].survey_answer[0].length; ic < lenc; ic++) {
                    // each option : may be none!
                    for (let id = 0, lend = this.questions[ia].survey_answer[0].answered_options.length; id < lend; id++) {
                        for (let ib = 0, lenb = this.questions[ia].response_options.length; ib < lenb; ib++) {
                            // each available response - does it match our answer?
                            if (this.questions[ia].survey_answer[ic].answered_options[id].id == this.questions[ia].response_options[ib].id) {
                                // if so, set the ans field
                                this.questions[ia].response_options[ic].ans = parseInt(this.questions[ia].survey_answer[ic].weight, 10);
                                // NaN!
                            }
                        }
                    }
                }
            }
        }

        // handling BOOL fields
        for (let i = 0, len = this.questions.length; i < len; i++) {
            for (let ii = 0, leni = this.questions[i].response_options.length; ii < leni; ii++) {
                this.questions[i].response_options[ii].bool = false;
                if (this.questions[i].response_options[ii].ans > 0) {
                    this.questions[i].response_options[ii].bool = true;
                } else {
                    this.questions[i].response_options[ii].bool = false;
                }
            }
        }
        console.log('parsed check boxes');
    }

    addAnsField() {

        // add the ans field into the model
        let index1a = 0;
        let index2a = 0;
        let len1a = 0;
        let len2a = 0;
        let index3a = 0;
        let len3a = 0;
        // for each question object
        console.log('addAnsField', this.questions);
        for (index1a = 0, len1a = this.questions.length; index1a < len1a; ++index1a) {
            // for each possible response
            for (index2a = 0, len2a = this.questions[index1a].response_options.length; index2a < len2a; ++index2a) {
                this.questions[index1a].response_options[index2a].ans = 0;
                // for each actual response
                if (this.questions[index1a].survey_answer.length > 0) {

                    for (index3a = 0, len3a = this.questions[index1a].survey_answer[0].answered_options.length; index3a < len3a; ++index3a) {
                        // scan all responses; if the id of the response is equal to the id of the possible response, set the weight.
                        if ((this.questions[index1a].survey_answer[0].answered_options[index3a].id) == this.questions[index1a].response_options[index2a].id) {
                            this.questions[index1a].response_options[index2a].ans = parseInt(this.questions[index1a].response_options[index2a].weight, 10);
                        }
                    }
                }
            }
        }
    }

    encodeAnswers() {
        // encode answers for sending to server
        if (environment.verboseDebugMode === true) {
            console.log('encodeAnswers');
        }
        this.answers = [];
        this.textanswers = [];
        let sum = 0;
        let index1 = 0;
        let index2 = 0;
        let len1 = 0;
        let len2 = 0;
        for (index1 = 0, len1 = this.questions.length; index1 < len1; ++index1) {
            sum = 0;
            for (index2 = 0, len2 = this.questions[index1].response_options.length; index2 < len2; ++index2) {
                sum = sum + parseInt(this.questions[index1].response_options[index2].ans, 10);
                sum = sum ? sum : 0;
            }
            this.answers.push(this.questions[index1].id, sum);
            // [5].response_options['0'].option_type
            if (this.questions[index1].response_options[0].option_type == 'text') {
                if (this.questions[index1].response_options[0].txt != null) {
                    this.textanswers.push(this.questions[index1].id, this.questions[index1].response_options[0].txt);
                } else {
                    this.textanswers.push(this.questions[index1].id, '');
                }
            }
        }
    }

    makeShortWordsBig(optionsObject) {
        // console.log(optionsObject);
        let totalCharacters = 0;
        // for (let i = 0, len = optionsObject.length; i < len; i++) {
        totalCharacters = totalCharacters + optionsObject.communication.length;
        // }
        // console.log('comm length=', totalCharacters);
        if (totalCharacters < 25) {
            return '1';
        } else {
            return '0';
        }
    }

    stackTitle() {

        if (this.screen_width < 500) {
            if (this.PageTitle.length + (this.subsectiontitle ? this.subsectiontitle.length : 0) + (this.sectiontitle ? this.sectiontitle.length : 0) > 30) {
                // console.log(this.PageTitle.length + this.subsectiontitle.length + this.sectiontitle.length);
                return ('1');
            }
        }
        return ('0');
    }

    renderVertically(optionsObject) {
        // Should these options be stacked vertically?

        // definitely never stack when doing words in the surveys
        // unless width of screen is less than 500px

        if ((optionsObject[0].caption == 'understands') ||
            (optionsObject[0].caption == 'Not yet')) {
            if (this.screen_width > 500) {
                return '0';
            }

        }
        let totalCharacters = 0;
        for (let i = 0, len = optionsObject.length; i < len; i++) {
            totalCharacters = totalCharacters + optionsObject[i].caption.length;
        }

        // over 500 wide
        // console.log(this.screen_width, ' ', totalCharacters);
        if (this.screen_width > 500) {
            // if total is over 20 chars, we stack.
            if (totalCharacters > 20) {
                return '1';
            }
            if ((totalCharacters > 9) && (totalCharacters < 21)) {
                return '0';
            }
        } else if ((this.screen_width > 400) && (this.screen_width < 500)) {
            // if total is over 20 chars, we stack.
            // partial stack of over 15.
            if ((totalCharacters > 15) && (totalCharacters < 32)) {
                //     console.log('2');
                return '2';
            }
            if (totalCharacters > 31) {
                //   console.log('1');
                return '1';

            }
            if ((totalCharacters > 9) && (totalCharacters < 16)) {
                // console.log('0');
                return '0';
            }

        } else if (this.screen_width < 400) {
            // always stack
            return '1';
        }
        return '0';
    }

    decodeExistingAnswers() {
        // console.log('decoding existing answer data...');
        this.answers = [];
        let sum = 0;
        let index1 = 0;
        let index2 = 0;
        let len1 = 0;
        let len2 = 0;
        for (index1 = 0, len1 = this.questions.length; index1 < len1; ++index1) {
            // if (environment.verboseDebugMode === true) { console.log(len); }
            sum = 0;
            for (index2 = 0, len2 = this.questions[index1].response_options.length; index2 < len2; ++index2) {
                sum = sum + parseInt(this.questions[index1].response_options[index2].ans, 10);
                sum = sum ? sum : 0;
            }
            this.answers.push(this.questions[index1].id, sum);
        }
    }

    // Called when the Question form is submitted ----------------------
    submitAnswers() {
        // encode answers
        this.encodeAnswers();
        // show spinner
        this.spinner.show();
        // taskModal.hide();
        // task.title = '';
        // alert('submit ' & questionnumber);
        // build object containing all answers
        // send object to server as JSON
        // stoken = '';
        // let stoken = '';++|


        const dataobject = {
            selections: this.answers,
            text: this.textanswers
        };

        //  dataobject['selections'] = this.answers;
        //  dataobject['text'] = this.textanswers;

        // post answers to server
        console.log(dataobject);
        console.log(this.answerurl);

        // this.apiService.postDataToServer(this.answerurl, dataobject)
        this.apiService.postDataToServer(this.answerurl, dataobject)
            .subscribe(
                data => {
                    // this.commserror = false;
                    // this.commssuccess = true;
                    // this.errormessage = '';
                    // this.successmessage = 'Data Sent!';
                    // console.log('POST call successful value returned in body',
                    //     data);
                    this.apiService.successData();
                    this.spinner.hide();

                    // now move to the next question
                    this.questionnumber = this.questionnumber + 1;
                    this.userInfoService.setCurrentQuestionNumber(this.questionnumber);
                    // update server

                    // if that was the last question, go to 'finished' page

                    if (this.questionsmeta.is_last) {
                        // console.log('sending finalise signal');
                        // send finalised signal to server
                        // updateServerProgress(this.surveynumber, questionnumber);
                        this.selectSurveyToFinaliseService.addSurveySelection(this.surveynumber);
                        this.finishsurveynum = this.surveynumber;
                        console.log('moving to finished page');
                        // console.log('to finalise  ', this.surveynumber);
                        this.router.navigateByUrl('/finished');
                    } else {
                        // not the last question.
                        console.log('debug tgb');
                        this.spinner.show();

                        // update new urls
                        this.surveyurl = this.basesurl + 'surveys/' + this.surveynumber + '?q=' + this.questionnumber;
                        this.answerurl = this.baseaurl + 'answers/' + this.surveynumber + '?q=' + this.questionnumber;
                        this.metaurl = this.basesurl + 'qmetadata/' + this.surveynumber + '?q=' + this.questionnumber;

                        // update the question metadata
                        this.apiService.getQuestionMetaDataFromServer(this.metaurl)
                            .subscribe(
                                (qmdata: QuestionsMetaLayout) => {
                                    this.questionsmeta = qmdata;
                                    // todo this.surveyname = qmdata.survey_name;
                                    // todo this.lastquestion = qmdata.last_question_num;


                                    this.userInfoService.setFinalQuestionNumber(this.lastquestion);
                                    if (environment.verboseDebugMode === true) {
                                        console.log(this.questionsmeta);
                                    }
                                    // avoid 'Cannot read property 'section_title' of undefined'
                                    if (!this.questionsmeta[0].section_title.section_title) {
                                        this.sectiontitle = '';
                                    } else {
                                        this.sectiontitle = this.questionsmeta[0].section_title.section_title;
                                    }
                                    if (!this.questionsmeta[0].subsection_title.subsection_title) {
                                        this.subsectiontitle = '';
                                    } else {
                                        this.subsectiontitle = this.questionsmeta[0].subsection_title.subsection_title;
                                    }

                                }
                            );

                        // console.log('getting question+answer data from server (postsubmit)...');
                        // get question data
                        this.apiService.getSurveyDataFromServer(this.surveyurl)
                            .subscribe(
                                (data: QuestionsLayout[]) => {
                                    // console.log('===got question+answer data(postsubmit)');
                                    this.questions = data;
                                    // add the answer field to the model
                                    this.addAnsField();
                                    this.parseCheckboxes();
                                    this.populateTextBoxes();
                                    // $ionicScrollDelegate.scrollTop(false);
                                    this.spinner.hide();
                                }
                            );
                    }
                },
                error => {
                    console.log('POST call in error:::', error);
                    if (environment.verboseDebugMode === true) {
                        console.log(JSON.stringify(error));
                    }
                    // this.errormessage = error;
                    // this.commserror = true;
                    // this.commssuccess = false;
                    this.apiService.errorDataMsg();
                    this.spinner.hide();
                },
                () => {
                    console.log('The POST observable is now completed.');
                    // this.commssuccess = true;
                }
            );

    } // submit answers

}
