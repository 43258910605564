import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: yourdata
// Purpose: Display an about screen (about the research)
// Porting Status: Ported to Ionic 5 on 09/09/19

@Component({
    selector: 'app-yourdata',
    templateUrl: './yourdata.component.html',
    styleUrls: ['./yourdata.component.scss'],
})
export class YourdataComponent implements OnInit {
    // property type declarations
    PageTitle = 'Your Data';
    basesurl: string; // base server url
    shownGroup = null;
    shownGroupPurpose = null;
    shownGroupAspects = null;
    shownGroupWork = null;
    shownGroupContact = null;
    wordBoost: boolean;


    // constructor
    constructor(public router: Router) {
        if (environment.wordboost) {
            this.wordBoost = true;
        } else {
            this.wordBoost = false;
        }
    }

// Initialisation routine
    ngOnInit() {
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {

    }
    toggleGroup(index) {
        if (this.isGroupShown(index)) {
              this.shownGroup = null;
          } else {
              this.shownGroup = index;
          }
      };
  
      isGroupShown(index) {
          return this.shownGroup === index;
      };
  
      toggleGroupPurpose(Purpose) {
          if (this.isGroupShownPurpose(Purpose)) {
                this.shownGroupPurpose = null;
            } else {
                this.shownGroupPurpose = Purpose;
            }
        };
    
        isGroupShownPurpose(Purpose) {
            return this.shownGroupPurpose === Purpose;
        };
  
        toggleGroupAspects(Aspects) {
          if (this.isGroupShownAspects(Aspects)) {
                this.shownGroupAspects = null;
            } else {
                this.shownGroupAspects = Aspects;
            }
        };
    
        isGroupShownAspects(Aspects) {
            return this.shownGroupAspects === Aspects;
        };
  
        toggleGroupWork(Work) {
          if (this.isGroupShownWork(Work)) {
                this.shownGroupWork = null;
            } else {
                this.shownGroupWork = Work;
            }
        };
    
        isGroupShownWork(Work) {
            return this.shownGroupWork === Work;
        };
  
        toggleGroupContact(Contact) {
          if (this.isGroupShownContact(Contact)) {
                this.shownGroupContact = null;
            } else {
                this.shownGroupContact = Contact;
            }
        };
    
        isGroupShownContact(Contact) {
            return this.shownGroupContact === Contact;
        };
}
