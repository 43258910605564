import {Component, OnInit} from '@angular/core';
import {ActionSheetController, LoadingController, ModalController, NavController} from '@ionic/angular';
import {FormGroup} from '@angular/forms';
import {ApiService} from '../api.service';
// import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {File} from '@ionic-native/file/ngx';
import {Storage} from '@ionic/storage';

import {environment} from '../../environments/environment';


@Component({
    selector: 'app-registeration',
    templateUrl: './registeration.component.html',
    styleUrls: ['./registeration.component.scss'],
})
export class RegisterationComponent implements OnInit {

    registerForm: FormGroup;
    myImageUrl: any;
    isImage = false;
    wordBoost: boolean;
    imageOption: boolean;

    constructor(public modal: ModalController,
                public loadingController: LoadingController,
                public actionSheetCtrl: ActionSheetController,
                private file: File,
                // private camera: Camera,
                public apiService: ApiService,
                public navCtrl: NavController,
                private storage: Storage
    ) {
        this.wordBoost = environment.wordboost;
        this.imageOption = environment.imageoption;
    }

    ngOnInit() {
    }

    closeModal() {
        this.modal.dismiss();
    }

    async register(form) {

        const loading = await this.loadingController.create({
            message: 'Please wait',
            duration: 2000
        });
        await loading.present();


        if (environment.verboseDebugMode === true) {
            console.log('Registration', form.value.givenName);
            console.log(form.value.givenName + '' + form.value.surName);

        }
        localStorage.setItem('userName', form.value.givenName);
        const regForm: any = {
            access: 'user',
            active: 1,
            isdel: 0,
            photo: this.myImageUrl,
            givenName: form.value.givenName,
            surName: form.value.surName,
            userName: form.value.userName,
            email: form.value.email,
            password: form.value.password,
            password2: form.value.password2,
            name: form.value.givenName + ' ' + form.value.surName,
            organisation: form.value.organisation,
            postcode: form.value.postcode
        };
        this.apiService.registerUser(regForm).subscribe(async (res: any) => {
            if (environment.verboseDebugMode === true) {
                console.log('response', res);
            }
            loading.dismiss();
            if (res.replyStatus == 'success') {
                this.apiService.showToast(res.replyMsg);
                this.modal.dismiss();

            }
            // else{
            //   this.apiService.showToast(res.replyMsg)
            //  }
        }, err => {
            loading.dismiss();
            this.apiService.showToast('Try Again');
            if (err.status == 401) {
                this.apiService.tokenExpired();
            } else {
                console.log('Error', err);
            }
        });
    }


    async showActionSheet() {
        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Select Photo From',
            buttons: [{
                text: 'Camera',
                handler: () => {
                    this.photoOption(1);
                }
            }, {
                text: 'Gallery',
                handler: () => {
                    this.photoOption(2);

                }
            }, {
                text: 'Cancel',
                handler: () => {
                    console.log('Cancel clicked');
                }
            }]
        });
        await actionSheet.present();
    }


    photoOption(params) {
// removed as no camera
        /* if (params == '1') {
             params = this.camera.PictureSourceType.CAMERA;
         } else {
             params = this.camera.PictureSourceType.PHOTOLIBRARY;
         }

         const options: CameraOptions = {
             quality: 100,
             destinationType: this.camera.DestinationType.DATA_URL,
             encodingType: this.camera.EncodingType.JPEG,
             mediaType: this.camera.MediaType.PICTURE,
             sourceType: params,
             saveToPhotoAlbum: false,
             correctOrientation: true,
             allowEdit: true,
         };

         this.camera.getPicture(options).then((imageData) => {
             this.myImageUrl != '' ? this.isImage = false : this.isImage = true;
             console.log('ImageData', this.isImage);
             this.myImageUrl = 'data:image/jpeg;base64,' + imageData;
         }, (err) => {
             if (environment.verboseDebugMode === true) {
                 console.log(err);
             }
         });*/

        // this.ImageUpload();
    }


}
