import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: AboutBT
// Purpose: Display an About screen.
// Porting Status: Ported to Ionic 5 on 09/09/19

@Component({
    selector: 'app-aboutbt',
    templateUrl: './aboutbt.component.html',
    styleUrls: ['./aboutbt.component.scss'],
})
export class AboutbtComponent implements OnInit {
    // property type declarations

    basesurl: string; // base server url
    PageTitle = 'About Babytalk';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    shownGroup = null;
    shownGroupPurpose = null;
    shownGroupVersion = null;
    shownGroupFeatures = null;
    shownGroupContact = null;
    shownGroupBackground = null;

    wordBoost: boolean;

// constructor
    constructor(public router: Router) {
        if (environment.wordboost) {
            this.wordBoost = true;
            this.PageTitle = 'About WordBoost';
        } else {
            this.wordBoost = false;
        }

    }

// Initialisation routine
    ngOnInit() {
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    toggleGroup(index) {
        if (this.isGroupShown(index)) {
            this.shownGroup = null;
        } else {
            this.shownGroup = index;
        }
    }

    isGroupShown(index) {
        return this.shownGroup === index;
    }

    toggleGroupPurpose(Purpose) {
        if (this.isGroupShownPurpose(Purpose)) {
            this.shownGroupPurpose = null;
        } else {
            this.shownGroupPurpose = Purpose;
        }
    }

    isGroupShownPurpose(Purpose) {
        return this.shownGroupPurpose === Purpose;
    }

    toggleGroupVersion(Version) {
        if (this.isGroupShownVersion(Version)) {
            this.shownGroupVersion = null;
        } else {
            this.shownGroupVersion = Version;
        }
    }

    isGroupShownVersion(Version) {
        return this.shownGroupVersion === Version;
    }

    toggleGroupFeatures(Work) {
        if (environment.verboseDebugMode == true) {
            console.log('W');
        }
        if (this.isGroupShownFeatures(Work)) {
            this.shownGroupFeatures = null;
            if (environment.verboseDebugMode == true) {
                console.log('Y');
            }
        } else {
            this.shownGroupFeatures = Work;
            if (environment.verboseDebugMode == true) {
                console.log('X');
            }
        }
    }

    isGroupShownFeatures(Work) {
        return this.shownGroupFeatures === Work;
    }

    toggleGroupContact(Contact) {
        if (this.isGroupShownContact(Contact)) {
            this.shownGroupContact = null;
        } else {
            this.shownGroupContact = Contact;
        }
    }

    isGroupShownContact(Contact) {
        return this.shownGroupContact === Contact;
    }

    toggleGroupBackground(Background) {
        if (this.isGroupShownBackground(Background)) {
            this.shownGroupBackground = null;
        } else {
            this.shownGroupBackground = Background;
        }
    }

    isGroupShownBackground(Background) {
        return this.shownGroupBackground === Background;
    }


}
