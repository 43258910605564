import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {ActionSheetController, ModalController} from '@ionic/angular';
import {Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {Storage} from '@ionic/storage';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-newbabymodal',
    templateUrl: './newbabymodal.page.html',
    styleUrls: ['./newbabymodal.page.scss'],
})


export class NewbabymodalPage implements OnInit {
    registerForm: FormGroup;
    wordBoost: boolean;
    myImageUrl: any;
    isImage: any;
    addBabyForm: FormGroup;
    gender: any;
    babyData: any = [{id: '', baby_name: '', profilePic: '', notes: '', gender: '', dob: ''}];
    private imageOption: boolean;
    public dob: string = new Date().toISOString();
    public token: any;

    public header = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + this.token
        }
    };


    constructor(// private camera: Camera,
        public actionSheetCtrl: ActionSheetController,
        public router: Router,
        private modalCtrl: ModalController,
        private storage: Storage,
        public apiService: ApiService) {
        this.wordBoost = environment.wordboost;
        this.imageOption = environment.imageoption;
    }

    ngOnInit() {
        this.getUserInfo();
    }

    ionViewWillEnter() {
        this.getUserInfo();
    }

    ongenderChange(val) {
        console.log('gender ' + val);
        if (val == 1) {
            this.gender = 'M';
        } else if (val == 0) {
            this.gender = 'F';
        } else {
            this.gender = 'N';
        }
        // console.log("OnChangeVa", this.gender);
    }


    onFormSubmit(addBabyForm) {

        /*        if (this.myImageUrl == undefined) {
                    this.apiService.showToast('Please Select Photo');
                } else {*/
        if (this.gender == undefined) {
            this.gender = 'M';
        }
        const addBaby: any = {
            // profilePic: this.myImageUrl,
            babyName: addBabyForm.value.babyName,
            notes: addBabyForm.value.notes,
            dob: addBabyForm.value.dob,
            gender: this.gender,
            selected: 0
        };

        this.apiService.addNewBaby(addBaby, this.header).subscribe((res: any) => {

            if (res.replyStatus == 'success') {

                this.storage.get('selectedbaby').then((val) => {
                    if (val == [] || val == undefined || val == '') {
                        this.babyData[0].id = res.data.id;
                        this.babyData[0].baby_name = res.data.babyName;
                        this.babyData[0].notes = res.data.notes;
                        this.babyData[0].gender = res.data.gender;
                        this.babyData[0].dob = res.data.dob;
                        // this.babyData[0].profile_pic = res.data.profilePic;
                        this.storage.set('selectedbaby', this.babyData);
                        this.modalCtrl.dismiss();
                    }
                });

                this.apiService.showToast(res.replyMsg);
                console.log(res.data);

            } else {
                this.apiService.showToast(res.replyMsg);
            }
        }, err => {

            if (err.status == 401) {
                this.apiService.tokenExpired();
            } else {
                this.apiService.showToast('Try Again');
            }
        });
        // }
    }

    /* async showActionSheet() {
         const actionSheet = await this.actionSheetCtrl.create({
             header: 'Select Photo From',
             buttons: [{
                 text: 'Camera',
                 handler: () => {
                     this.photoOption(1);
                 }
             }, {
                 text: 'Gallery',
                 handler: () => {
                     this.photoOption(2);

                 }
             }, {
                 text: 'Cancel',
                 handler: () => {
                     console.log('Cancel clicked');
                 }
             }]
         });
         await actionSheet.present();
     }


     photoOption(params) {

         if (params == '1') {
             params = this.camera.PictureSourceType.CAMERA;
         } else {
             params = this.camera.PictureSourceType.PHOTOLIBRARY;
         }

         const options: CameraOptions = {
             quality: 100,
             destinationType: this.camera.DestinationType.DATA_URL,
             encodingType: this.camera.EncodingType.JPEG,
             mediaType: this.camera.MediaType.PICTURE,
             sourceType: params,
             saveToPhotoAlbum: false,
             correctOrientation: true,
             allowEdit: true,
         };

         this.camera.getPicture(options).then((imageData) => {
             this.myImageUrl != '' ? this.isImage = false : this.isImage = true;
             console.log('ImageData', this.isImage);
             this.myImageUrl = 'data:image/jpeg;base64,' + imageData;
         }, (err) => {
             if (environment.verboseDebugMode === true) {
                 console.log(err);
             }
         });

         // this.ImageUpload();
     }
 */
    uploadImg() {
        console.log('Uploaded Image', this.myImageUrl);
    }

    register(form) {
        console.log('Baby name:', form.value.babyname,
            'DOB:', form.value.dob,
            'Notes:', form.value.notes,
            'Uploaded Image', this.myImageUrl);
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    closeModal() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalCtrl.dismiss({
            'dismissed': true
        });
    }

    getUserInfo() {
        this.storage.get('token').then((val) => {
            this.token = val;
            this.header.headers.Authorization = 'Bearer ' + val;

        });
    }

}
