// http interceptor - adds sends the authentication token along with the request

import {Injectable} from '@angular/core';
import {ErrorDialogService} from '../error-dialog.service';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private errorDialogService: ErrorDialogService) {

    }

    intercept(request: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        // attempt to populate token from localstorage
        const token: string = localStorage.getItem('token');
        console.log('(http interceptor is using BTLK_currentUser): ' + token)
        // if token in local storage, set Authorization header
        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + token)
            });

        } else {
            console.log('No stored token found');
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                headers: request.headers.set('Content-Type', 'application/json')
            });
        }

        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
        });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                catchError((error: HttpErrorResponse) => {
                    let data = {};
                    data = {
                        reason: error && error.error.reason ? error.error.reason : '',
                        status: error.status
                    };
                    this.errorDialogService.openDialog(data);
                    return throwError(error);
                });
                return event;
            }));
    }

}
