import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-img-modal-page',
  templateUrl: './img-modal-page.component.html',
  styleUrls: ['./img-modal-page.component.scss'],
})
export class ImgModalPageComponent implements OnInit {
  babyId: any
  public imageUrl: any;
  public allImages: any;
  public dataImages: any;

  constructor(public navParam: NavParams,
    public modalCtrl: ModalController,
    public storage: Storage
  ) {
    let babyImage = navParam.get('image');
    this.imageUrl = babyImage.baby_pics;
    this.allImages = navParam.get('allImages')

  }

  ngOnInit() { }

  closeModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


}
