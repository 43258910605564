import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from './user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from './api.service';

// Babytalk Client V2
// Component: SelectBaby service
// Purpose:

@Injectable({
    providedIn: 'root'
})
export class SelectBabyService {
    babySelection = [];

// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {


    }

    addBabySelection(newObj) {
        this.babySelection.push(newObj);
    }

    getBabySelection() {
        return this.babySelection.pop();
    }

    // return {addBabySelection: addBabySelection, getBabySelection: getBabySelection};
}
