import {Injectable} from '@angular/core';
import {UserInfoService} from './user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from './api.service';
import {Router} from '@angular/router';

// Babytalk Client V2
// Component: SelectSurveyToFinalise service
// Purpose: Lets user finalise a survey

@Injectable({
    providedIn: 'root'
})
export class SelectSurveyToFinaliseService {

// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
    }

// this service may be an unnecessary duplication of selectSurveyService

    // let surveySelection = [];

    addSurveySelection(newObj) {
//        surveySelection.push(newObj);
    }

    getSurveySelection() {
        //       return surveySelection.pop();
    }

    // return {addSurveySelection: addSurveySelection, getSurveySelection: getSurveySelection};
}
