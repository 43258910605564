import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ErrordialogComponent} from './errordialog/errordialog.component';

@Injectable()
export class ErrorDialogService {

    constructor(public dialog: MatDialog) {
    }

    openDialog(data): void {
        let dialogRef = this.dialog.open(ErrordialogComponent, {
            width: '300px',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
}
