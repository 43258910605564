import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';
import {UtilsService} from '../utils.service';
import {ModalController} from '@ionic/angular';
import {FormGroup} from '@angular/forms';

// Babytalk Client V2
// Component: addbaby
// Purpose: Register a new child on the system
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-addbaby',
    templateUrl: './addbaby.component.html',
    styleUrls: ['./addbaby.component.scss'],
})
export class AddbabyComponent implements OnInit {
    // property type declarations
    PageTitle = 'Add Child';
    basesurl: string;
    public message: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private newbabyid: string;
    private mybabyname: string;
    // TODO: replace this with an interface
    private newbaby: any = {};
    private newbabyurl: string;
    private brandnewbabyid: number;
    addBabyForm: FormGroup;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                public modal: ModalController,
                private utils: UtilsService) {


    }

    // Initialisation routine
    ngOnInit() {
    }

    registerNewBaby() {
        this.newbabyid = this.userInfoService.getViewBabyID();
        this.mybabyname = this.userInfoService.getViewBabyName();
        this.newbaby = {};
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.newbabyurl = this.basesurl + 'babies/register/0';

        
        // send object to server as JSON
        const dataobject = {};
        const newname = 'newname';
        const gender = 'gender';
        const dob = 'dob';

        dataobject[newname] = this.newbaby.name;
        dataobject[gender] = this.newbaby.gender;
        dataobject[dob] = this.newbaby.dob;

        // (new Date()).toISOString().substring(0, 19).replace('T', ' ');
        // console.log('dataobject ', dataobject);

        this.apiService.postDataToServer(this.newbabyurl, dataobject)
            .subscribe(
                data => {
                    // if (environment.verboseDebugMode === true) { console.log(data); }
                    if (data.substring(0, 7) == 'Not Sent') {
                        this.commserror = true;
                        this.commssuccess = false;
                        this.errormessage = 'New word ' + data;
                        this.successmessage = '';
                    } else {
                        this.commserror = false;
                        this.commssuccess = true;
                        this.errormessage = '';
                        this.successmessage = 'New word ' + data;
                    }
                    // alert(data);
                    // TODO
                    // this.brandnewbabyid = parseInt(headers.data, 10);


                    this.userInfoService.setViewBabyName(this.newbaby.name);
                    this.userInfoService.setViewBabyID(this.brandnewbabyid);
                    // select this baby
                    this.userInfoService.setBabyName(this.newbaby.name);
                    this.userInfoService.setBabyID(this.brandnewbabyid);
                    this.userInfoService.setBabyDOB(this.newbaby.dob);

                    // console.log('go to upload baby photo page');
                    this.router.navigateByUrl('/newphoto');
                    console.log('POST call successful value returned in body', data);
                },
                error => {
                    console.log('newbaby POST call in error');
                    if (environment.verboseDebugMode == true) {
                        console.log(error);
                    }
                    this.errormessage = error;
                    this.commserror = true;
                    this.commssuccess = false;
                    this.spinner.hide();

                    if (error.status == 401) {
                        this.apiService.access401();
                    }

                },
                () => {
                    console.log('The new wordPOST observable is now completed.');
                    // this.commssuccess = true;
                    this.spinner.hide();
                });


    }

    showDatePretty(datetime) {
        if (datetime == undefined) {
            datetime = '';
        }

        // Split timestamp into [ Y, M, D, h, m, s ]
        const t = datetime.split(/[- :]/);

        // Apply each element to the Date function
        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

        return (d);

    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    closeModal(){
        this.modal.dismiss();
    }

    refresh() {
        console.log('refresh screen');
        // this.spinner.show();
    }

}
