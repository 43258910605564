import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';
import {AlertController, ModalController} from '@ionic/angular';
import {NewbabymodalPage} from './newbabymodal/newbabymodal.page';
import {EditbabymodalPage} from './editbabymodal/editbabymodal.page';
import {Storage} from '@ionic/storage';

// Babytalk Client V2
// Component: mybaby
// Purpose: show information about the babies on the user's account
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-mybaby',
    templateUrl: './mybaby.component.html',
    styleUrls: ['./mybaby.component.scss'],
})
export class MybabyComponent implements OnInit {
    // property type declarations
    PageTitle = 'My Children';
    basesurl: string; // base server url
    babylisturl: string;
    userfirstname: string;
    usersurname: string;
    userid: string;
    mybabies: any[];
    nochildren: boolean;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    buttonColor: string;
    clickedbuttonColor: string;
    babyData: any = [{ id: '', baby_name: '', profile_pic: '', notes: '', gender: '', dob: '' }];
    private babyselecturl: string;
    private userInfourl: string;
    wordBoost: boolean;

    Colors: Array<any> = ['#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1'];

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                private alertController: AlertController,
                public apiService: ApiService,
                public storage: Storage,
                public modalController: ModalController) {
        this.wordBoost = environment.wordboost;

    }

    // Initialisation routine
    ngOnInit() {
        // this.nochildren = true;
        this.buttonColor = '#ecf0f1';
        this.clickedbuttonColor = 'rgb(170,170,170)';

        // get the babies assigned to this user
        // http://www.babytalk.website/btsrv/public/v1/mybabies/0

        this.basesurl = 'https://' + environment.serverUrl + '/v1/';

        // this.babylisturl = this.basesurl + 'mybabies/0';

        this.userfirstname = this.userInfoService.getFirstName();
        this.usersurname = this.userInfoService.getSurname();
        this.userid = this.userInfoService.getUserID();
    }

    ionViewWillEnter() {

        if (environment.verboseDebugMode === true) {
            console.log('ionViewWillEnter');
        }
        this.getBabyList();
    }

    getBabyList() {

        this.apiService.showLoader();
        // console.log("Test baby List");
        this.apiService.getBabyList().subscribe((data: any[]) => {
            this.apiService.hideLoader();
            this.mybabies = data;
            console.log('MYBABYLIST', this.mybabies);
        }, error => {
            this.apiService.hideLoader();
            if (environment.verboseDebugMode === true) {
                console.log(error);
            }
            // this.spinner.hide();
            if (error.status == 401) {
                this.apiService.tokenExpired();
            } else if (error.status == 500) {
                this.apiService.showToast('Server configuration error 500');
            } else if (error.status == 0) {
                this.apiService.showToast('Cannot contact server!');
            } else {
                this.apiService.showToast('Error ' + error.status);
                console.log('Error', error);
            }
        });
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {

    }

    showAge(dob: any) {

    }

    showBabyDetailsPage(id: any, baby_name: any) {

    }

    showBabyEditPage(id: any, baby_name: any) {

    }

    getButtonColor(index) {
        const num = this.getnumber(index);
        return this.Colors[num];
    }

    getnumber(data) {
        let i = data;
        if (i > this.Colors.length - 1) {
            i = i - this.Colors.length;
            if (i < this.Colors.length) {
                return i;
            } else {
                this.getnumber(i);
            }
        } else {
            return i;
        }
    }
    selectBaby(newbabyid: any) {
        // console.log("Test Selected Baby");
        const data: any = {babyId: newbabyid.toString()};
        const babyId = data;
        console.log('BabyId', babyId);


        this.apiService.selectedBabyChange(babyId).subscribe((res: any) => {
            console.log('REsponse', res);

            if (res.replyStatus == 'success') {
                this.apiService.showToast(res.replyMsg);

                this.babyData[0].id = res.data.babyId;
                this.babyData[0].baby_name = res.data.baby_name;
                this.babyData[0].notes = res.data.notes;
                this.babyData[0].gender = res.data.gender;
                this.babyData[0].dob = res.data.dob;
                this.babyData[0].profile_pic = res.data.profile_pic;

                this.storage.set('selectedbaby', this.babyData);
                this.getBabyList();
            } else {
                this.apiService.showToast(res.replyMsg);
            }

        }, err => {

            if (err.status == 401) {
                this.apiService.access401();
                // this.apiService.tokenExpired();
            } else if (err.status == 500) {
                this.apiService.showToast('Server configuration error 500');
            } else {
                this.apiService.showToast('Error ' + err.status);
                console.log('Error', err);
            }
        });


    }

    async deleteBaby(baby) {
        if (baby.selected == '1') {
            this.apiService.showToast('You can\'t delete this baby. To delete this baby,please select other baby first');
        } else {
            const alert = await this.alertController.create({
                header: 'Delete Baby!',
                message: 'Do you want to delete this baby?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: (blah) => {
                            console.log('Confirm Cancel: blah');
                        }
                    }, {
                        text: 'Okay',
                        handler: () => {
                            this.deleteBabyIdWise(baby.id);
                        }
                    }
                ]
            });

            await alert.present();
        }
    }

    deleteBabyIdWise(babyId) {

        this.apiService.deleteBaby(babyId).subscribe((res: any) => {
            if (res.replyStatus == 'success') {
                this.apiService.showToast(res.replyMsg);
                this.getBabyList();
            } else {
                
                this.apiService.showToast(res.replyMsg);
            }

        }, err => {
            if (err.status == 401) {
                this.apiService.access401();
            }
            this.apiService.showToast('Try Again');
        });
    }

    async EditBaby(baby) {
        if (environment.verboseDebugMode === true) {
            console.log(baby);
        }
        const modal = await this.modalController.create({
            component: EditbabymodalPage,
            componentProps: {
                BabyName: baby.baby_name,
                DOB: baby.dob,
                notes: baby.notes,
                image: baby.profile_pic,
                id: baby.id,
                profile_pic: baby.profile_pic
            }
        });
        modal.onDidDismiss()
            .then((data: any) => {
                this.getBabyList();
            });
        return await modal.present();

    }


    showAddBabyPage() {

    }


    async OpenModal() {
        // console.log("Add Baby TEst");
        const modal = await this.modalController.create({
            component: NewbabymodalPage
        });
        modal.onDidDismiss()
            .then((data: any) => {

                this.getBabyList();
            });
        return await modal.present();

    }
}

