import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {environment} from '../environments/environment';
// import {Storage} from '@ionic/storage';

// Babytalk Client V2
// Component: userinfo service
// Purpose: provides an interface to browser localstorage to store use info.

// local storage key names
const KEY_BABYDOB = 'BTLK_babyDOB';
const KEY_BABYID = 'BTLK_babyID';
const KEY_BABYNAME = 'BTLK_babyName';
const KEY_BABYRECORDID = 'BTLK_babyRecordID';
const KEY_CURRENTQUESTION = 'BTLK_currentQuestion';
const KEY_CURRENTREVIEWQUESTIONNUMBER = 'BTLK_currentReviewQuestionNumber';
const KEY_CURRENTSURVEY = 'BTLK_currentSurvey';
const KEY_FINALQUESTIONNUMBER = 'BTLK_finalQuestionNumber';
const KEY_FINALREVIEWQUESTIONNUMBER = 'BTLK_finalReviewQuestionNumber';
const KEY_ORGNAME = 'BTLK_orgName';
const KEY_USERFIRSTNAME = 'BTLK_userFirstName';
const KEY_USERID = 'BTLK_userID';
const KEY_USERSURNAME = 'BTLK_userSurname';
const KEY_VIEWBABYID = 'BTLK_viewBabyID';
const KEY_VIEWBABYNAME = 'BTLK_viewBabyName';
const KEY_VIEWCOMPLETESURVEYID = 'BTLK_viewCompleteSurveyID';
const KEY_VIEWCOMPLETESURVEYTITLE = 'BTLK_viewCompleteSurveyTitle';
const KEY_PRACMODE = 'BTLK_pracMode';
const KEY_FETUREMODE = 'BTLK_feature';
const KEY_AdvisorMode = 'BTLK_advisor';
const KEY_MOTD = 'BTLK_MOTD';

@Injectable()
export class UserInfoService {


// constructor
    // constructor(@Inject(LOCAL_STORAGE) private storage: Storage) {
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
    }


    // $localStorage = $localStorage.$default({
    //     userID: ''
    //  });
    // loggedIn

    // ----------- Setters --------------------
    public setBabyName(newObj): string {
        this.storage.set(KEY_BABYNAME, newObj);
        return this.storage.get(KEY_BABYNAME);
    }

    public setBabyID(newObj): string {
        this.storage.set(KEY_BABYID, newObj);
        return this.storage.get(KEY_BABYID);
    }

    public setBabyRecordID(newObj): string {
        this.storage.set(KEY_BABYRECORDID, newObj);
        return this.storage.get(KEY_BABYRECORDID);
    }

    public setViewBabyName(newObj): string {
        this.storage.set(KEY_VIEWBABYNAME, newObj);
        return this.storage.get(KEY_VIEWBABYNAME);
    }

    public setViewBabyID(newObj): string {
        this.storage.set(KEY_VIEWBABYID, newObj);
        return this.storage.get(KEY_VIEWBABYID);
    }

    public setCurrentQuestionNumber(newObj): number {
        this.storage.set(KEY_CURRENTQUESTION, newObj);
        return this.storage.get(KEY_CURRENTQUESTION);
    }

    public setCurrentSurvey(newObj): number {
        this.storage.set(KEY_CURRENTSURVEY, newObj);
        return this.storage.get(KEY_CURRENTSURVEY);
    }

    public setSurname(newObj): string {
        this.storage.set(KEY_USERSURNAME, newObj);
        return this.storage.get(KEY_USERSURNAME);
    }

    public setFirstName(newObj): string {
        this.storage.set(KEY_USERFIRSTNAME, newObj);
        return this.storage.get(KEY_USERFIRSTNAME);
    }

    public setUserID(newObj): string {
        this.storage.set(KEY_USERID, newObj);
        return this.storage.get(KEY_USERID);
    }

    public setOrgName(newObj): string {
        this.storage.set(KEY_ORGNAME, newObj);
        return this.storage.get(KEY_ORGNAME);
    }

    public setBabyDOB(newObj): string {
        this.storage.set(KEY_BABYDOB, newObj);
        return this.storage.get(KEY_BABYDOB);
    }

    public setFinalQuestionNumber(newObj): number {
        this.storage.set(KEY_FINALQUESTIONNUMBER, newObj);
        return this.storage.get(KEY_FINALQUESTIONNUMBER);
    }

    public setFinalReviewQuestionNumber(newObj): number {
        this.storage.set(KEY_FINALREVIEWQUESTIONNUMBER, newObj);
        return this.storage.get(KEY_FINALREVIEWQUESTIONNUMBER);
    }

    public setViewCompleteSurveyID(newObj): string {
        this.storage.set(KEY_VIEWCOMPLETESURVEYID, newObj);
        return this.storage.get(KEY_VIEWCOMPLETESURVEYID);
    }

    public setViewCompleteSurveyTitle(newObj): string {
        this.storage.set(KEY_VIEWCOMPLETESURVEYTITLE, newObj);
        return this.storage.get(KEY_VIEWCOMPLETESURVEYTITLE);
    }

    public setCurrentReviewQuestionNumber(newObj): number {
        this.storage.set(KEY_CURRENTREVIEWQUESTIONNUMBER, newObj);
        return this.storage.get(KEY_CURRENTREVIEWQUESTIONNUMBER);
    }

    public setPractitionerMode(newObj): boolean {
        console.log('NewObj', newObj);
        this.storage.set(KEY_PRACMODE, newObj);
        return this.storage.get(KEY_PRACMODE);
    }

    public getFeatureMode(newObj): boolean {
        this.storage.set(KEY_FETUREMODE, newObj);
        return this.storage.get(KEY_FETUREMODE);
    }

    public getAdvisorMode(newObj): boolean {
        console.log('in User', newObj);
        this.storage.set(KEY_AdvisorMode, newObj);
        return this.storage.get(KEY_AdvisorMode);
    }

    public setMOTD(newObj): string {
        this.storage.set(KEY_MOTD, newObj);
        return this.storage.get(KEY_MOTD);
    }


    // ---------------- Getters ---------------------

    public getBabyName(): string {
        // return $localStorage.babyName;
        return this.storage.get(KEY_BABYNAME);
    }

    public getBabyID(): string {
        // if (environment.verboseDebugMode === true) { console.log("INFoID",KEY_BABYID); }
        return this.storage.get(KEY_BABYID);
    }

    public getBabyRecordID(): string {
        return this.storage.get(KEY_BABYRECORDID);
    }

    public getViewBabyName(): string {
        return this.storage.get(KEY_VIEWBABYNAME);
    }

    public getViewBabyID(): string {
        return this.storage.get(KEY_VIEWBABYID);
    }

    public getCurrentQuestionNumber(): number {
        console.log('XYZ', this.storage.get(KEY_CURRENTQUESTION));

        if (this.storage.get(KEY_CURRENTQUESTION) == undefined) {
            if (environment.verboseDebugMode === true) {
                console.log('udef');
            }
            return (1);
        }
        // if (environment.verboseDebugMode === true) { console.log("XYZ",this.storage.get(KEY_CURRENTQUESTION)); }
        return (parseInt(this.storage.get(KEY_CURRENTQUESTION), 10));
    }

    public getCurrentSurvey(): number {
        console.log('DEBUG: getcurrentsurvey');
        return this.storage.get(KEY_CURRENTSURVEY);

        // return (parseInt(this.storage.get(KEY_CURRENTSURVEY), 10));
    }

    public getSurname(): string {
        return this.storage.get(KEY_USERSURNAME);
    }

    public getFirstName(): string {
        return this.storage.get(KEY_USERFIRSTNAME);
    }

    public getUserID(): string {
        return this.storage.get(KEY_USERID);
    }

    public getOrgName(): string {
        return this.storage.get(KEY_ORGNAME);
    }

    public getBabyDOB(): string {
        return this.storage.get(KEY_BABYDOB);
    }

    public loggedIn(): boolean {
        return this.storage.get(KEY_USERID) !== undefined;
    }

    public getFinalQuestionNumber(): number {
        return (parseInt(this.storage.get(KEY_FINALQUESTIONNUMBER), 10));
    }

    public getFinalReviewQuestionNumber(): number {
        return (parseInt(this.storage.get(KEY_FINALREVIEWQUESTIONNUMBER), 10));
    }

    public getViewCompleteSurveyID(): string {
        return this.storage.get(KEY_VIEWCOMPLETESURVEYID);
    }

    public getViewCompleteSurveyTitle(): string {
        return this.storage.get(KEY_VIEWCOMPLETESURVEYTITLE);
    }

    public getCurrentReviewQuestionNumber(): number {
        // return this.storage.get(KEY_CURRENTREVIEWQUESTIONNUMBER);
        return (parseInt(this.storage.get(KEY_CURRENTREVIEWQUESTIONNUMBER), 10));
    }

    public getPractitionerModeStatus(): boolean {
        return this.storage.get(KEY_PRACMODE);
    }


    public getFeatureModeStatus(): boolean {
        return this.storage.get(KEY_FETUREMODE);
    }

    public gethideAdvisorStatus(): boolean {
        return this.storage.get(KEY_AdvisorMode);
    }

    public getMOTD(): string {
        return this.storage.get(KEY_MOTD);
    }


    // ---------- clear all ------------
    public clearAllLocalStorage(): void {
        this.storage.clear();
    }

    /*
      return {
      setUserID: setUserID,
      getUserID: getUserID,
      getFirstName: getFirstName,
      setFirstName: setFirstName,
      getSurname: getSurname,
      setSurname: setSurname,
      getCurrentSurvey: getCurrentSurvey,
      setCurrentSurvey: setCurrentSurvey,
      getCurrentQuestionNumber: getCurrentQuestionNumber,
      setCurrentQuestionNumber: setCurrentQuestionNumber,
      getBabyRecordID: getBabyRecordID,
      setBabyRecordID: setBabyRecordID,
      getBabyID: getBabyID,
      setBabyID: setBabyID,
      getViewBabyID: getViewBabyID,
      setViewBabyID: setViewBabyID,
      getBabyName: getBabyName,
      setBabyName: setBabyName,
      getViewBabyName: getViewBabyName,
      setViewBabyName: setViewBabyName,
      getBabyDOB: getBabyDOB,
      setBabyDOB: setBabyDOB,
      getOrgName: getOrgName,
      setOrgName: setOrgName,
      setFinalQuestionNumber: setFinalQuestionNumber,
      getFinalQuestionNumber: getFinalQuestionNumber,
      setFinalReviewQuestionNumber: setFinalReviewQuestionNumber,
      getFinalReviewQuestionNumber: getFinalReviewQuestionNumber,
      getViewCompleteSurveyID: getViewCompleteSurveyID,
      setViewCompleteSurveyTitle: setViewCompleteSurveyTitle,
      getViewCompleteSurveyTitle: getViewCompleteSurveyTitle,
      setViewCompleteSurveyID: setViewCompleteSurveyID,
      getCurrentReviewQuestionNumber: getCurrentReviewQuestionNumber,
      setCurrentReviewQuestionNumber: setCurrentReviewQuestionNumber,
      clearAllLocalStorage: clearAllLocalStorage
    };
    */

}
