import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';

// Babytalk Client V2
// Component: showusers
// Purpose: Show user account information
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-userview',
    templateUrl: './userview.component.html',
    styleUrls: ['./userview.component.scss'],
})
export class UserviewComponent implements OnInit {
    // property type declarations
    PageTitle = 'Show User Info';
    basesurl: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;

    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
    }

    ngOnInit() {
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }
}
