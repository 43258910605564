import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: assignsurvey
// Purpose:  assign survey to baby (this is for development purposes)
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

// interface for the Assign Survey data object
interface AssignmentLayout {
    assignedSurvey?: string;
    dateAvailable?: string;
    babyid?: number;
    understandssays?: boolean;
    date_available?: string;
}

@Component({
    selector: 'app-assignsurvey',
    templateUrl: './assignsurvey.component.html',
    styleUrls: ['./assignsurvey.component.scss'],
    providers: [UserInfoService]
})

export class AssignsurveyComponent implements OnInit {
    // property type declarations
    PageTitle = 'PAGETITLE';
    basesurl: string; // base server url
    delbabyurl: string;
    userInfourl: string;
    newbabyid: string;
    mybabyname: string;
    babyname: string;
    babyselected: string;
    assign: any;
    mydate: any;
    message: string;
    assignSurveyURL: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private babylisturl: string;
    private availablesurveys: any[];
    private surveylisturl: string;
    private mysurveys: any;
    wordBoost: boolean;


    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {

        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.babylisturl = this.basesurl + 'mybabies/0';
        if (environment.wordboost) {
            this.wordBoost = true;

        } else {
            this.wordBoost = false;
        }
    }

    // Initialisation routine
    ngOnInit() {
        this.newbabyid = this.userInfoService.getViewBabyID();
        this.mybabyname = this.userInfoService.getViewBabyName();
        this.delbabyurl = this.basesurl + 'baby/' + this.newbabyid;
        this.babyname = this.userInfoService.getBabyName();
        this.babyselected = this.userInfoService.getBabyID();
        this.surveylisturl = this.basesurl + 'mysurveys/' + this.babyselected;

        this.spinner.show();

        // list user's current surveys ====
        this.apiService.getDataFromServer(this.surveylisturl)
            .subscribe((
                availablesurveys: any[]) => {
                    this.spinner.hide();
                    // This gets us the list as an array of objects
                    this.availablesurveys = availablesurveys;
                }
            );

        // show spinner
        this.spinner.show();

// -------------- get assigned surveys list ---------------
        this.apiService.getDataFromServer(this.surveylisturl)
            .subscribe(
                (data: any[]) => {
                    // console.log('getting assigned surveys list...');
                    this.mysurveys = data;
                    this.spinner.hide();
                    // add the answer field to the model
                    // addAnsField();
                    // console.log('got surveys data.');
                    // if (environment.verboseDebugMode === true) { console.log(mysurveys); }
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                    // this.errors = error;
                }
            );

        //  set the date to today
        const d = new Date();
        const year = d.getFullYear().toString();
        const month = d.getMonth() + 1;
        let smonth: string;
        let sday: string;

        if (month < 10) {
            smonth = '0' + month.toString();
        } else {
            smonth = month.toString();
        }

        const day = d.getDate();
        if (day < 10) {
            sday = '0' + day.toString();
        } else {
            sday = day.toString();
        }

        const dateobject = new Date(year + ' ' + smonth + ' ' + sday);
        this.mydate.available = dateobject;

        this.surveylisturl = this.basesurl + 'allsurveys/0';

// -------------- now get available surveys list and populate dropdown ---------

        this.spinner.show();
        this.apiService.getDataFromServer(this.surveylisturl)
            .subscribe((
                availablesurveys: any[]) => {
                    this.spinner.hide();
                    // This gets us the list as an array of objects
                    this.availablesurveys = availablesurveys;
                }
            );


// .buttons

        this.apiService.getDataFromServer(this.surveylisturl).subscribe(
            (data: any[]) => {
                this.availablesurveys = data;
                // if (environment.verboseDebugMode === true) { console.log('availablesurveys:'); }
                // if (environment.verboseDebugMode === true) { console.log(availablesurveys); }
                // if (environment.verboseDebugMode === true) { console.log(assignSurvey); }
                this.spinner.hide();
            }, error => {
                this.spinner.hide();
                if (environment.verboseDebugMode === true) {
                    console.log(error);
                }
                // this.errors = error;
            });
    }

    /*getSurveysList(): Observable<any> {
        const URLstring = this.backend_url + 'surveylisturl';
    return (this.apiService.getDataFromServer(<any[]>(URLstring));*/


// -------------------


    /*    this.apiService.getDataFromServer(compsurveylisturl)
                .subscribe(
                    (data: any[]) => {
     (
     function (cdata) {
     console.log('getting surveys list...');
     mycompletedsurveys = cdata;
     if (environment.verboseDebugMode === true) { console.log(cdata); }
     }
     ).error(function (response) {
     // error callback
     console.error(response);

     this.spinner.hide();
     alert('Problem getting surveys data!');
     this.router.navigateByUrl('/buttons');
     });
     // -------------------

     this.apiService.getDataFromServer(upcomsurveylisturl)
                .subscribe(
                    (data: any[]) => {
     (
     function (data) {
     console.log('Contacting server...');
     myupcomingsurveys = data;
     this.spinner.hide();
     // add the answer field to the model
     // addAnsField();
     console.log('got surveys data.');
     if (environment.verboseDebugMode === true) { console.log(data); }
     }
     ).error(function (response) {
     // error callback
     this.spinner.hide();
     console.error(response);
     console.log('error getting surveys data');
     alert('Problem getting surveys data!');
     this.router.navigateByUrl('/buttons');
     });
     // -------------------
     */

    showDatePretty(datetime) {
        if (datetime == undefined) {
            datetime = '';
        }
        // Split timestamp into [ Y, M, D, h, m, s ]
        const t = datetime.split(/[- :]/);
        // Apply each element to the Date function
        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        return (d);
    }

    assignQuestionnaire() {
        let babyid;
        let babyname;

        this.spinner.show();

        // assign the questionnaire to this baby
        //     Route::post('mysurveys/{selectedbabyid}', 'SurveyController@assignasurvey'); // assign a survey

        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.assignSurveyURL = this.basesurl + 'mysurveys/' + this.userInfoService.getBabyID();

        babyid = this.userInfoService.getBabyID();
        babyname = this.userInfoService.getBabyName();

        const dataobject: AssignmentLayout = {};

        dataobject.assignedSurvey = this.assign.survey;
        dataobject.dateAvailable = this.mydate.available;
        dataobject.babyid = babyid;

        // dataobject.understandssays = newword.us;
        // dataobject.date_available = (new Date()).toISOString().substring(0, 19).replace('T', ' ');

        // call api service function
        this.apiService.postDataToServer(this.assignSurveyURL, dataobject)
            .subscribe(
                data => {
                    // if (environment.verboseDebugMode === true) { console.log(data); }
                    if (data.substring(0, 7) == 'Not Sent') {
                        this.commserror = true;
                        this.commssuccess = false;
                        this.errormessage = 'New word ' + data;
                        this.successmessage = '';
                    } else {
                        this.commserror = false;
                        this.commssuccess = true;
                        this.errormessage = '';
                        this.successmessage = 'New word ' + data;
                    }
                    // alert(data);
                    this.spinner.hide();
                    console.log('POST call successful value returned in body', data);
                },
                error => {
                    console.log('new word POST call in error');
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                    this.errormessage = error;
                    this.commserror = true;
                    this.commssuccess = false;
                    this.spinner.hide();
                },
                () => {
                    console.log('The new wordPOST observable is now completed.');
                    // this.commssuccess = true;
                    this.spinner.hide();
                }
            );

        this.message = 'Thank you!';
    }

    refresh() {
        this.spinner.show();
        this.message = '';
    }

    showDaysLeft(datetime): string {
        if (datetime == undefined) {
            datetime = '';
        }
        const now = new Date(); // Todays Date

        // Split timestamp into [ Y, M, D, h, m, s ]
        const t = datetime.split(/[- :]/);

        // Apply each element to the Date function
        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

        let expire = new Date();
        expire = d;
        const hour1 = expire.getHours();
        expire.setTime(expire.getTime() + (7 * 86400000));
        const hour2 = expire.getHours();

        if (hour1 != hour2) {
            d.setTime(d.getTime() + (hour1 - hour2) * 3600000);
        }

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        // Discard the time and time-zone information.
        const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
        const utc2 = Date.UTC(expire.getFullYear(), expire.getMonth(), expire.getDate());
        const daysleft = Math.floor((utc2 - utc1) / _MS_PER_DAY);
        let message = 'To be completed in ' + daysleft + ' days.';
        if (daysleft < 0) {
            message = 'Survey is overdue.';
        }
        return (message);

    }

    deletebaby(delbabyid) {
        this.delbabyurl = this.basesurl + 'baby/' + delbabyid;
        this.spinner.show();
        const dataobject = {};

        this.apiService.deleteObjectFromServer(this.delbabyurl)
            .subscribe(
                data => {
                    // if (environment.verboseDebugMode === true) { console.log(data); }
                    if (data.substring(0, 7) == 'Not Sent') {
                        this.commserror = true;
                        this.commssuccess = false;
                        this.errormessage = 'New word ' + data;
                        this.successmessage = '';
                    } else {
                        this.commserror = false;
                        this.commssuccess = true;
                        this.errormessage = '';
                        this.successmessage = 'New word ' + data;
                    }
                    // alert(data);
                    this.spinner.hide();
                    console.log('POST call successful value returned in body', data);
                    this.message = 'Baby Deleted!';
                    // this.router.navigateByUrl('/mybaby');
                },
                error => {
                    console.log('new word POST call in error');
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                    this.errormessage = error;
                    this.commserror = true;
                    this.commssuccess = false;
                    this.spinner.hide();
                },
                () => {
                    console.log('The new wordPOST observable is now completed.');
                    // this.commssuccess = true;
                    this.spinner.hide();
                }
            );
        // this.message = 'Baby Deleted!';
    }

    showNewBabyPhotoPage(newbabyid, newbabyname) {
        this.userInfoService.setViewBabyName(newbabyname);
        this.userInfoService.setViewBabyID(newbabyid);
        this.router.navigateByUrl('/newphoto');
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    showBabyEditPage() {
        this.router.navigateByUrl('/babyedit');
    }


}
