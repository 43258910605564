import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../api.service';
import {HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-addtag',
    templateUrl: './addtag.component.html',
    styleUrls: ['./addtag.component.scss'],
})
export class AddtagComponent implements OnInit {

    tagName: any;
    urlString: any;
    tagData: any = { tag: '' };
    constructor(private modal: ModalController, 
        public apiService: ApiService,
        public httpClient: HttpClient) {
    }

    ngOnInit() {

    }

    closeModal() {
        this.modal.dismiss()
    }

    addTag() {

      

        if (this.tagName == undefined) {
            this.apiService.showToast("Tag Name can't be empty")
        }
        else {
            this.tagData.tag = this.tagName;
            const data = JSON.stringify(this.tagData);
            console.log("Data: ", data)
            
            this.apiService.addTag(data).subscribe(res => {
                if (res.replyStatus == 'success') {
                    this.apiService.showToast(res.replyMsg);
                    this.modal.dismiss();
                } else {
                    this.apiService.showToast(res.replyMsg);
                }
            }, err => {
               if(err.status == 401){
                   this.apiService.access401();
               }
            })
        }
    }
}