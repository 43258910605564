import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: babyedit
// Purpose: edit child on the system
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-babyedit',
    templateUrl: './babyedit.component.html',
    styleUrls: ['./babyedit.component.scss'],
})

export class BabyeditComponent implements OnInit {
    // property type declarations
    PageTitle = 'PAGETITLE';
    basesurl: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private surveylisturl: string;
    private newbabyid: string;
    private mybabyname: string;
    private delbabyurl: string;
    private assignSurvey: {};
    private babyname: string;
    private mysurveys: any;
    wordBoost: boolean;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
        if (environment.wordboost) {
            this.wordBoost = true;

        } else {
            this.wordBoost = false;
        }
    }

    // Initialisation routine
    ngOnInit() {
        this.newbabyid = this.userInfoService.getViewBabyID();
        this.mybabyname = this.userInfoService.getViewBabyName();
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.delbabyurl = this.basesurl + 'baby/' + this.newbabyid;

        this.assignSurvey = {};

        //  set the date to today
        const d = new Date();
        const year = d.getFullYear().toString();
        const month = d.getMonth() + 1;
        let smonth: string;
        let sday: string;

        if (month < 10) {
            smonth = '0' + month.toString();
        } else {
            smonth = month.toString();
        }

        const day = d.getDate();
        if (day < 10) {
            sday = '0' + day.toString();
        } else {
            sday = day.toString();
        }

        // todo
        // this.assignSurvey.date_available = new Date(year + ' ' + smonth + ' ' + sday);


        // when page is loaded, populate the drop down with activity tags
        // get the list of tags from the server

        /*
        taglist = {};
         taglisturl = basesurl + 'diary/activitytags/0';
         userfirstname = this.userInfoService.getFirstName();
         usersurname = this.userInfoService.getSurname();
         userid = this.userInfoService.getUserID();
         this.spinner.show();
        */

        // let babyselected = this.userInfoService.getBabyID();

        this.babyname = this.userInfoService.getBabyName();
        this.surveylisturl = this.basesurl + 'allsurveys/0';
        this.spinner.show();


        // -------------- get available surveys list ---------------
        this.apiService.getDataFromServer(this.surveylisturl)
            .subscribe(
                (data: any[]) => {

                    this.mysurveys = data;
                    // if (environment.verboseDebugMode === true) { console.log(mysurveys); }
                    this.spinner.hide();
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode == true) {
                        console.log(error);
                    }
                    // this.errors = error;

                });
    }

    showChildrenPage() {
    }

    refresh() {
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    showNewBabyPhotoPage(newbabyid: string, mybabyname: string) {

    }

    showAssignSurveyPage(newbabyid: string, mybabyname: string) {

    }

    showBabyDeletePage(newbabyid: string, mybabyname: string) {

    }
}
