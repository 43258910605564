import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {Platform} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {Storage} from '@ionic/storage';
// Babytalk Client V2
// Component: settings
// Purpose: Settings page
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {


    // property type declarations
    basesurl: string; // base server url
    name: string;


    PageTitle = 'Information & Settings';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;

    selectedFile: File;
    platform: string;
    practmode: boolean;
    advisorButton = false;
    hideAdvisorButton = false;
    rtl: boolean;
    landcsape: boolean;
    portrait: boolean;
    width: number;
    height: number;
    url: string;
    babyname: any;
    babyId: any;
    AdvisorStatus: boolean;
    isUnlocked = false;
    isUserMode = false;
    wordBoost: boolean;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                private http: HttpClient,
                public plt: Platform,
                private storage: Storage
    ) {
        this.wordBoost = environment.wordboost;

    }

    // Initialisation routine
    ngOnInit() {

        // environment.serverUrl = 'www.babytalk.website/btsrv/public';
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.practmode = this.userInfoService.getPractitionerModeStatus();
        this.platform = '';
        this.plt.ready().then(() => {
            if (this.plt.is('android')) {
                this.platform = this.platform + ' ' + 'Android';
            }
            if (this.plt.is('ios')) {
                this.platform = this.platform + ' ' + 'ios';
            }
            if (this.plt.is('mobileweb')) {
                this.platform = this.platform + ' ' + 'mobile browser';
            }
            if (this.plt.is('capacitor')) {
                this.platform = this.platform + ' ' + 'capacitor';
            }
            if (this.plt.is('cordova')) {
                this.platform = this.platform + ' ' + 'cordova';
            }
            if (this.plt.is('hybrid')) {
                this.platform = this.platform + ' ' + 'hybrid';
            }
            if (this.plt.is('electron')) {
                this.platform = this.platform + ' ' + 'electron';
            }
            if (this.plt.is('ipad')) {
                this.platform = this.platform + ' ' + 'ipad';
            }
            if (this.plt.is('iphone')) {
                this.platform = this.platform + ' ' + 'iphone';
            }
            if (this.plt.is('phablet')) {
                this.platform = this.platform + ' ' + 'phablet';
            }
            if (this.plt.is('mobile')) {
                this.platform = this.platform + ' ' + 'mobile';
            }
            if (this.plt.is('pwa')) {
                this.platform = this.platform + ' ' + 'pwa';
            }
            if (this.plt.is('tablet')) {
                this.platform = this.platform + ' ' + 'tablet';
            }
        });

        // this.rtl = this.plt.isRTL();
        this.landcsape = this.plt.isLandscape();
        this.portrait = this.plt.isPortrait();
        this.width = this.plt.width();
        this.height = this.plt.height();
        this.url = this.plt.url();
    }

    ionViewWillEnter() {
        this.getInfo();
    }

    onFeatureMode() {
        console.log(this.name);
        console.log('AdvisorButton', this.advisorButton);

        if (this.name == 'research') {
            this.storage.set('isResearchKey', true);
            this.userInfoService.getAdvisorMode(true);
            this.userInfoService.getFeatureMode(true);

            const data: any = {ucode: 1};
            this.apiService.addCode(data).subscribe(res => {
                if (res.replyMsg == 'success') {
                    this.apiService.showToast(res.replyMsg);
                }
            }, err => {
                if (err.status == 401) {
                    this.apiService.access401();
                }
            });

            this.apiService.showToast('Successfully unlocked feature mode, now you can access advisor page');
        } else {
            this.apiService.showToast('Unrecognised Code');
            this.userInfoService.getFeatureMode(false);
            this.userInfoService.getAdvisorMode(false);
        }
    }

    
    refresh() {
        // console.log('scope refresh');
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();
        // $window.location.reload(true);
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    unlock_expt() {

    }

    togglePractMode(event) {
        console.clear();
        console.log(event.detail.checked);
        if (event.detail.checked) {

            this.practmode = true;
            this.storage.set('isUserMode', true);
            localStorage.setItem('mode', 'Practitioner Mode');

            if (this.isUnlocked) {
                this.userInfoService.getAdvisorMode(true);
                this.userInfoService.getFeatureMode(true);
            } else {
                this.userInfoService.getAdvisorMode(false);
                this.userInfoService.getFeatureMode(false);
            }

            this.userInfoService.setPractitionerMode(false);
        } else {
            this.practmode = false;
            this.storage.set('isUserMode', false);
            this.userInfoService.getAdvisorMode(true);
            localStorage.setItem('mode', 'Parental Mode');
            this.userInfoService.setPractitionerMode(true);
        }

        //    console.log("Mode is",this.practmode);
        // if (this.practmode == true) {
        //     localStorage.setItem('mode', "Practitioner Mode");
        //     this.userInfoService.getAdvisorMode(false);
        //     this.userInfoService.setPractitionerMode(false);
        // } else {
        //     this.userInfoService.getAdvisorMode(true);
        //     localStorage.setItem('mode', "Parental Mode");
        //     this.userInfoService.setPractitionerMode(true);
        // }
    }

    getInfo() {
        this.storage.get('isResearchKey').then(val => {
            this.isUnlocked = val;
        });

        this.storage.get('isUserMode').then(val => {
            this.isUserMode = val;
        });

        this.storage.get('selectedbaby').then(val => {
            this.babyId = val[0].id;
            this.babyname = val[0].baby_name;
        });

        this.storage.get('isAdvisiorShown').then(val => {
            this.advisorButton = val;
            console.log(this.advisorButton);
        });
    }
    // notify(event) {
    //     if (event.detail.checked) {
    //         this.userInfoService.getFeatureMode(true);
    //         this.storage.set("isAdvisiorShown", true)
    //     } else {
    //         this.userInfoService.getFeatureMode(false);
    //         this.storage.set("isAdvisiorShown", false)
    //     }
    // }



    toggleServer() {
        if (environment.serverUrl == 'www.babytalk.website/btsrv/public') {
            environment.serverUrl = 'php4.shaligraminfotech.com/babytalk/public';

        } else {
            environment.serverUrl = 'www.babytalk.website/btsrv/public';
        }

        this.basesurl = 'https://' + environment.serverUrl + '/v1/';

    }

    onFileChanged(event) {
        this.selectedFile = event.target.files[0];
    }

    onUpload() {
        const uploadData = new FormData();
        uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
        this.http.post('my-backend.com/file-upload', uploadData, {
            reportProgress: true,
            observe: 'events'
        })
            .subscribe(event => {
                console.log(event); // handle event here
            });
    }

    hideAdvisor() {
        console.log('AdvisorStatus', this.advisorButton);
// TODO
        return;
        if (this.advisorButton == true) {
            this.storage.set('isAdvisiorShown', true);
            this.userInfoService.getAdvisorMode(true);
            console.log('Hide');
        } else {
            this.storage.set('isAdvisiorShown', false);
            this.userInfoService.getAdvisorMode(false);
            console.log('Display');
        }
    }
}

