import {Component} from '@angular/core';
import {AlertController, MenuController, ModalController, NavController, Platform} from '@ionic/angular';
// import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {UserInfoService} from './user-info.service';
import {Storage} from '@ionic/storage';
import {FCM} from '@ionic-native/fcm/ngx';
import {ApiService} from './api.service';
import {NewbabymodalPage} from './mybaby/newbabymodal/newbabymodal.page';
import {environment} from '../environments/environment';

// import {AppUpdate} from '@ionic-native/app-update/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    wordBoost: boolean;
    doAutoUpdate: boolean;
    updateUrl: string;
    wordBoostPages = [

        /* {
             title: 'Tutorial',
             url: '/tutorial',
             icon: 'school'
         }, */
        {
            title: 'Home',
            url: '/buttons',
            icon: 'home'
        },
        {
            title: 'About Wordboost',
            url: '/aboutbt',
            icon: 'information-circle'
        },
        {
            title: 'Your Data',
            url: '/yourdata',
            icon: 'information-circle'
        },


        {
            title: 'Your Consent',
            url: '/privacy',
            icon: 'information-circle'
        },
        {
            title: 'Study Information',
            url: '/terms',
            icon: 'ios-create'
        },
        {
            title: 'Withdraw from Study',
            url: '/withdraw',
            icon: 'ios-download'
        },

    ];
    pages = [];

    babytalkPages = [

        {
            title: 'Tutorial',
            url: '/tutorial',
            icon: 'school'
        },
        {
            title: 'Home',
            url: '/buttons',
            icon: 'home'
        },
        {
            title: 'About Babytalk',
            url: '/aboutbt',
            icon: 'information-circle'
        },
        {
            title: 'About LuCiD',
            url: '/yourdata',
            icon: 'information-circle'
        },
        {
            title: 'Privacy',
            url: '/privacy',
            icon: 'lock'
        },
        {
            title: 'Terms and Conditions',
            url: '/terms',
            icon: 'ios-create'
        },
        {
            title: 'Download',
            url: '/download',
            icon: 'ios-download'
        },
        {
            title: 'Settings',
            url: '/settings',
            icon: 'settings'
        },
    ];
    tokenId: any;
    value: any;
    token: any;

    // constructor
    constructor(
        private platform: Platform,
        public router: Router,
        // private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public menuCtrl: MenuController,
        private alertController: AlertController,
        private userinfoservice: UserInfoService,
        public storage: Storage,
        public navCntl: NavController,
        public api: ApiService,
        private fcm: FCM,
        public modalController: ModalController
        // private appUpdate: AppUpdate

    ) {
        this.initializeApp();
        this.wordBoost = environment.wordboost;
        this.doAutoUpdate = environment.autoupdate;
        this.updateUrl = environment.appupdateurl;
        if (this.wordBoost) {
            this.pages = this.wordBoostPages;
        } else {
            this.pages = this.babytalkPages;
        }
    }


    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            // this.splashScreen.hide();
            this.notificationSetup();
            this.getSlider();
            // this.checkUpdate();
        });
    }

    ngOnInit() {

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {

                this.pages.map(p => {
                    return p['active'] = (event.url === p.url);

                });
            }
        });


    }


    showNewPage(url: string) {

        // shut menu and navigate to new location
        this.closeMenu();
        this.router.navigateByUrl(url);
    }

    closeMenu() {
        document.querySelector('ion-menu-controller')
            .close();
    }


    async logout() {
        const alert = await this.alertController.create({
            header: 'Logout',
            message: 'Are you sure?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Logout',
                    handler: () => {
                        this.menuCtrl.toggle();
                        this.storage.clear();
                        localStorage.removeItem('satellizer_token');
                        localStorage.removeItem('token');
                        this.userinfoservice.clearAllLocalStorage();
                        this.storage.set('IsTravel', true);
                        this.router.navigateByUrl('/login');
                    }
                }
            ]
        });

        await alert.present();

        // $window.location.reload(true);
    }

    getinfo() {

        //    let token = localStorage.getItem('token');
        //    if(token ==  null){
        //     this.navCntl.navigateRoot('/login', { skipLocationChange: true });  
        //    }else{
        //     this.router.navigateByUrl('/buttons', { skipLocationChange: true });
        //    }

    }

    // Notification Toast
    public notificationSetup() {
        this.fcm.onNotification().subscribe(data => {
            if (data.wasTapped) {
                this.api.showToast(data);
                this.router.navigateByUrl('/mybaby');
            } else {
                this.api.showToast(data.title);
                // alert("Data Forfround");
                alert(data.title);
                this.api.showToast(data.title);

            }

        });

        this.fcm.onTokenRefresh().subscribe(token => {
            // Register your new token in your back-end if you want
            // backend.registerToken(token);
        });

    }

    // checkUpdate() {
    //     if (this.doAutoUpdate === true) {
    //         console.log('doupdate=true');
    //
    //         this.appUpdate.checkAppUpdate(this.updateUrl).then(update => {
    //                 alert('Update Status:  ' + update.msg);
    //                 console.log('update: ', update);
    //                 console.log('app update available');
    //             }).catch(error => {
    //                 console.log('error: ', error);
    //                 if (error === 'cordova_not_available') {
    //                     alert('Cannot check for app updates (cordova not available)');
    //                 } else {
    //                     alert('Error: ' + error.msg);
    //                 }
    //
    //             });
    //
    //     } else {
    //         console.log('doupdate=false');
    //     }
    //
    //
    // }

    getSlider() {
        this.token = localStorage.getItem('token');
        console.log('token', this.token);
        this.storage.get('IsTravel').then((val) => {
            console.log('Value', val);
            console.log('Token', this.token);

            if (val) {
                if (this.token == '' || this.token == null || this.token == undefined) {
                    this.router.navigateByUrl('/login');
                } else {

                    this.storage.get('selectedbaby').then(async (val) => {
                        if (val == [] || val == undefined || val == '') {
                            const modal = await this.modalController.create({
                                component: NewbabymodalPage
                            });
                            modal.onDidDismiss()
                                .then((data: any) => {
                                    // this.navCntl.navigateRoot('/buttons', {skipLocationChange: true});
                                });
                            return await modal.present();
                        } else {
                            this.router.navigateByUrl('/buttons');
                        }
                    });

                }
            } else {
                this.router.navigateByUrl('/buttons');
                // this.router.navigateByUrl('/tutorial');
            }
        });
    }

    //   if(this.value == true)
    //   {
    //       console.log("Slider value - true");
    //     this.router.navigateByUrl('/login');
    //   }
    //   else if(this.value == false)
    //   {
    //       console.log("Slider Value - False");
    //     this.router.navigateByUrl('/tutorial');
    //   }
    // });


}
