// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    wordboost: true,
    clientUrl: 'localhost:4200',
    verboseDebugMode: true,
    serverUrl: 'wordboost.org.uk',
    autoupdate: false,
    appupdateurl: 'https://wordboost.org.uk/APK/update.xml',
    // points to 'https://wordboost.org.uk/APK/wordboost.apk'
    imageoption: false

    // serverUrl: 'wordboostapi.local'

    // wordboostapi.local for local dev server
    // wordboost.org.uk for prod server

    // serverUrl: 'www.babytalk.website/btsrv/public'
    // serverUrl: 'team4.devhostserver.com/babytalk/public'
    // serverUrl: 'team4.devhostserver.com/babytalk/public'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/* Building project

Usual way of doing this is with:

~prompt$ ng build

Without any options above command will use default env file. For production environment:

~prompt$ ng build --env=prod

This is good now we are built system with production variables, but angular-cli offers one more option:

~prompt$ ng build --prod --env=prod

This prod option will minify all files and do all other sorts of cool things which are not so important right now. It is good to mention
that one of my builds went from 7MB to 2.3MB when I used this option.

 */
