import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx'; // rem
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {SettingsComponent} from './settings/settings.component';
import {AboutbtComponent} from './aboutbt/aboutbt.component';
import {CompletedsurveyComponent} from './completedsurvey/completedsurvey.component';
import {BabydetailsComponent} from './babydetails/babydetails.component';
import {BabyeditComponent} from './babyedit/babyedit.component';
import {YourdataComponent} from './yourdata/yourdata.component';
import {AddbabyComponent} from './addbaby/addbaby.component';
import {AssignsurveyComponent} from './assignsurvey/assignsurvey.component';
import {AuthviewComponent} from './authview/authview.component';
import {InfoComponent} from './info/info.component';
import {ButtonsComponent} from './buttons/buttons.component';
import {WithdrawComponent} from './withdraw/withdraw.component';
import {ChangephotoComponent} from './changephoto/changephoto.component';
import {FinishedComponent} from './finished/finished.component';
import {DeletebabyComponent} from './deletebaby/deletebaby.component';
import {DiaryComponent} from './diary/diary.component';
import {NewwordComponent} from './newword/newword.component';
import {LogoutComponent} from './logout/logout.component';
import {LoginComponent} from './login/login.component';
import {SelectsurvComponent} from './selectsurv/selectsurv.component';
import {RecommendationComponent} from './recommendation/recommendation.component';
import {MybabyComponent} from './mybaby/mybaby.component';
import {ShowprogressComponent} from './showprogress/showprogress.component';
import {NobabyComponent} from './nobaby/nobaby.component';
import {ViewdiaryComponent} from './viewdiary/viewdiary.component';
import {RecprogressComponent} from './recprogress/recprogress.component';
import {RegisterComponent} from './register/register.component';
import {ViewwordsComponent} from './viewwords/viewwords.component';
import {NewbabyphotoComponent} from './newbabyphoto/newbabyphoto.component';
import {UserviewComponent} from './userview/userview.component';
import {QuestionsComponent} from './questions/questions.component';
import {UserInfoService} from './user-info.service';
import {SelectBabyService} from './select-baby.service';
import {SelectSurveyService} from './select-survey.service';
import {ApiService} from './api.service';
import {UtilsService} from './utils.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BarplotComponent} from './barplot/barplot.component';
import {WordCloudComponent} from './wordcloud/wordcloud.component';
import {AuthenticationService} from './authentication.service';
import {AuthGuardService} from './auth-guard.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicStorageModule} from '@ionic/storage';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';
import {ErrorDialogService} from './error-dialog.service';
import {ErrordialogComponent} from './errordialog/errordialog.component';
import {MatDialogModule} from '@angular/material';
import {FlashcardsComponent} from './flashcards/flashcards.component';
import {FlashCardComponent} from './flash-card/flash-card.component';
import {PersonaliseComponent} from './personalise/personalise.component';
import {MenuControllerComponent} from './menu-controller/menu-controller.component';
import {Animation} from '@ionic/core';
import {PrivacyComponent} from './privacy/privacy.component';
import {TermsComponent} from './terms/terms.component';
import {TimelineComponent, TimelineItemComponent, TimelineTimeComponent} from './timeline/timeline.component';
import {NgxUploaderModule} from 'ngx-uploader';
import {ChartsModule} from 'ng2-charts';
import {UploadPhotoComponent} from './upload-photo/upload-photo.component';

// this should prevent the 'not found' error from occurring when refreshing the page on a browser
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

// native plugins
// import { AppUpdate } from '@ionic-native/app-update/ngx';
import {MenuitemComponent} from './menuitem/menuitem.component';
import {Media} from '@ionic-native/media/ngx';
import {AddtagComponent} from './addtag/addtag.component';
import {RegisterationComponent} from './registeration/registeration.component';
import {NewbabymodalPage} from './mybaby/newbabymodal/newbabymodal.page';
import {EditbabymodalPage} from './mybaby/editbabymodal/editbabymodal.page';
import {FCM} from '@ionic-native/fcm/ngx';
import {Base64} from '@ionic-native/base64/ngx';
import {MediaCapture} from '@ionic-native/media-capture/ngx';
import {AngularFireModule} from 'angularfire2';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
// import { GoogleMaps } from '@ionic-native/google-maps';
import {AngularFirestore} from '@angular/fire/firestore';
import {EvidenceInfoModalComponent} from './evidence-info-modal/evidence-info-modal.component';
import {FlashCardGridComponent} from './flash-card-grid/flash-card-grid.component';
// import { Camera } from '@ionic-native/camera/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {ImgModalPageComponent} from './img-modal-page/img-modal-page.component';
import {IonicImageViewerModule} from 'ionic-img-viewer';

// firebase configuration
const firebase = {
    // your firebase web config
    apiKey: 'AIzaSyDoElSXYrk9g17GgHrPxeryBr6VrKvcmFc',
    authDomain: 'babytalk-6ce3f.firebaseapp.com',
    databaseURL: 'https://babytalk-6ce3f.firebaseio.com',
    projectId: 'babytalk-6ce3f',
    storageBucket: '',
    messagingSenderId: '833789306073',
    appId: '1:833789306073:android:9396f3912727ca2579cac8'
};



@NgModule({
    declarations: [
        AppComponent,
        SettingsComponent,
        AddtagComponent,
        AboutbtComponent,
        RegisterationComponent,
        YourdataComponent,
        AddbabyComponent,
        AssignsurveyComponent,
        AuthviewComponent,
        BabydetailsComponent,
        BabyeditComponent,
        BarplotComponent,
        ButtonsComponent,
        ChangephotoComponent,
        CompletedsurveyComponent,
        DeletebabyComponent,
        DiaryComponent,
        WithdrawComponent,
        FinishedComponent,
        InfoComponent,
        LoginComponent,
        LogoutComponent,
        NewbabyphotoComponent,
        NewwordComponent,
        NobabyComponent,
        QuestionsComponent,
        RecommendationComponent,
        RecprogressComponent,
        RegisterComponent,
        SelectsurvComponent,
        SettingsComponent,
        ShowprogressComponent,
        UserviewComponent,
        UploadPhotoComponent,
        ViewdiaryComponent,
        ViewwordsComponent,
        WordCloudComponent,
        ErrordialogComponent,
        MybabyComponent,
        FlashcardsComponent,
        MenuControllerComponent,
        PersonaliseComponent,
        PrivacyComponent,
        TermsComponent,
        TimelineComponent,
        TimelineItemComponent,
        MenuitemComponent,
        TimelineTimeComponent,
        NewbabymodalPage,
        ImgModalPageComponent,
        FlashCardComponent,
        FlashCardGridComponent,
        EditbabymodalPage,
        EvidenceInfoModalComponent],


    entryComponents: [AddtagComponent,
        RegisterationComponent,
        NewbabymodalPage,
        EditbabymodalPage,
        EvidenceInfoModalComponent,
        ImgModalPageComponent],
    imports: [
        IonicImageViewerModule,
        BrowserModule,
        IonicStorageModule.forRoot(),
        IonicModule.forRoot({
            backButtonText: '',
            mode: 'md',
            navAnimation: (AnimationC: Animation): Promise<Animation> => Promise.resolve(new AnimationC())
        }),

        AngularFireModule.initializeApp(firebase),
        AppRoutingModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        MatDialogModule,
        NgxSpinnerModule,
        NgxUploaderModule,
        ChartsModule],

    providers: [
        File,
        FileTransfer,
        // Camera,
        FilePath,
        FCM,
        UserInfoService,
        AuthenticationService,
        AuthGuardService,
        ApiService,
        UtilsService,
        SelectSurveyService,
        SelectBabyService,
        StatusBar,
        // SplashScreen,
        AuthGuardService,
        ErrorDialogService,
        Media,
        Base64,
        MediaCapture,
        {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        // Geolocation,
        // NativeGeocoder,
        // GoogleMaps,
        AngularFirestore

//        AppUpdate

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

