import {Component, EventEmitter, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {humanizeBytes, UploaderOptions, UploadFile, UploadInput} from 'ngx-uploader';
import {environment} from '../../environments/environment';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {File} from '@ionic-native/file/ngx';
import {ActionSheetController, NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';

/* Babytalk Client V2 
Component: settings 
Purpose: Settings page Porting 
Status: Ported from Ionic 1 (Javascript/AngularJS) to Ionic 5 (Typescript/Angular 7) Aug 2019*/

@Component({selector: 'app-personalise', templateUrl: './personalise.component.html', styleUrls: ['./personalise.component.scss'],})
export class PersonaliseComponent implements OnInit {
    /* property type declarations*/
    basesurl: string;
    selectedUploadFilefor: string;
    /* base server url*/
    settingsUserID: string;
    settingsName: string;
    AuntURL: string;
    UncleURL: string;
    DadURL: string;
    MumURL: string;
    BroURL: string;
    SisURL: string;
    PetURL: string;
    GrmaURL: string;
    GrpaURL: string;
    HomeURL: string;
    PageTitle = 'Personalise';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    selectedFile: File;
    url = '';
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    options: UploaderOptions;
    babypicid: string;
    myImageUrl: any;
    uploadUrl: any; // Server Response;
    isImage: boolean = false;
    selectedProfileImage: any;
    picName: any;
    babyId: any;
    wordBoost: boolean;

    constructor(public router: Router,
        private userInfoService: UserInfoService,
        private spinner: NgxSpinnerService,
        public actionSheetCtrl: ActionSheetController,
        private file: File, private camera: Camera,
        public storage: Storage,
        public navCntl: NavController,
        public apiService: ApiService) {
        this.options = {concurrency: 1, maxUploads: 3};
        this.files = [];

        this.wordBoost = environment.wordboost;


        /* local uploading files array*/
        this.uploadInput = new EventEmitter<UploadInput>();
        /* input events, we use this to emit data to ngx-uploader*/
        this.humanizeBytes = humanizeBytes;
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.settingsUserID = 'User ID: ' + this.userInfoService.getUserID();
        this.settingsName = 'Name: ' + this.userInfoService.getFirstName() + ' ' + this.userInfoService.getSurname();
        this.babypicid = this.userInfoService.getBabyID();
        this.AuntURL = this.basesurl + 'baby/auntpic/';
        this.UncleURL = this.basesurl + 'baby/unclepic/';
        this.DadURL = this.basesurl + 'baby/dadpic/';
        this.MumURL = this.basesurl + 'baby/mumpic/';
        this.BroURL = this.basesurl + 'baby/bropic/';
        this.SisURL = this.basesurl + 'baby/sispic/';
        this.PetURL = this.basesurl + 'baby/petpic/';
        this.GrmaURL = this.basesurl + 'baby/grmapic/';
        this.GrpaURL = this.basesurl + 'baby/grpapic/';
        this.HomeURL = this.basesurl + 'baby/homepic/';
    }

    /* Initialisation routine*/
    ngOnInit() {
        this.getUserInfo();
        if (environment.verboseDebugMode === true) {
            console.log('pers');
        }
        this.picName = new Date().toISOString();
        console.log('picName', this.picName);
    }

    ngOnDestroy() {
        this.apiService.hideLoader();
    }



    showButtonsPage() {
        /* navigate to the buttons screen*/
        this.router.navigateByUrl('/buttons');
    }


    async showActionSheet() {
        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Select Photo From',
            buttons: [{
                text: 'Camera',
                handler: () => {
                    this.photoOption(1);
                }
            }, {
                text: 'Gallery',
                handler: () => {
                    this.photoOption(2);

                }
            }, {
                text: 'Cancel',
                handler: () => {
                    console.log('Cancel clicked');
                }
            }]
        });
        await actionSheet.present();
    }


    photoOption(params) {

        if (params == '1') {
            params = this.camera.PictureSourceType.CAMERA;
        } else {
            params = this.camera.PictureSourceType.PHOTOLIBRARY;
        }

        const options: CameraOptions = {
            quality: 100,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            sourceType: params,
            saveToPhotoAlbum: false,
            correctOrientation: true,
            allowEdit: true,
        };

        this.camera.getPicture(options).then((imageData) => {
            this.myImageUrl != '' ? this.isImage = false : this.isImage = true;
            console.log('ImageData', this.isImage);
            this.myImageUrl = 'data:image/jpeg;base64,' + imageData;
        }, (err) => {
            if (environment.verboseDebugMode === true) {
                console.log(err);
            }
        });

        // this.ImageUpload();
    }

    uploadImg() {

        (this.selectedUploadFilefor == 'aunt') ? this.url = this.AuntURL + this.babypicid :
            (this.selectedUploadFilefor == 'uncle') ? this.url = this.UncleURL + this.babypicid :
                (this.selectedUploadFilefor == 'dad') ? this.url = this.DadURL + this.babypicid :
                    (this.selectedUploadFilefor == 'mum') ? this.url = this.MumURL + this.babypicid :
                        (this.selectedUploadFilefor == 'bro') ? this.url = this.BroURL + this.babypicid :
                            (this.selectedUploadFilefor == 'sis') ? this.url = this.SisURL + this.babypicid :
                                (this.selectedUploadFilefor == 'pet') ? this.url = this.PetURL + this.babypicid :
                                    (this.selectedUploadFilefor == 'grandma') ? this.url = this.PetURL + this.babypicid :
                                        (this.selectedUploadFilefor == 'grandpa') ? this.url = this.GrpaURL + this.babypicid :
                                            (this.selectedUploadFilefor == 'home') ? this.url = this.HomeURL + this.babypicid : null;


        if (this.selectedUploadFilefor == undefined || this.selectedUploadFilefor == '') {
            this.apiService.showToast('Please select Image upload for');
        } else if (this.myImageUrl == undefined || this.myImageUrl == '') {
            this.apiService.showToast('Please select Image');
        } else {
            const fileParams = { data: this.myImageUrl, rel: this.selectedUploadFilefor, babyId: this.babyId };
            console.log('FileParam', fileParams);

            if (environment.verboseDebugMode === true) {
                console.log(fileParams);
            }
            this.apiService.userImageUpload(fileParams)
                .subscribe((res: any) => {
                    if (environment.verboseDebugMode === true) {
                        console.log(res);
                    }
                    if (res) {
                        this.apiService.showToast('File Uploaded');
                        this.navCntl.pop();
                    }
                }, (err: any) => {
                    if (err.status == 401) {
                        this.apiService.access401();
                    }
                    this.apiService.showToast('Try again');
                });
        }
    }

    getUserInfo() {
        this.storage.get('selectedbaby').then((val) => {
            this.babyId = val[0].id;
            console.log('selectedBaby', this.babyId);
        });
    }
    personalise_img() {
        console.log('Personalise Image Gallery');
        this.router.navigateByUrl('/personalise-images');
    }

}
