import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';

// Babytalk Client V2
// Component: authview
// Purpose:

interface AuthLayout {
    email?: string;
    password?: string;
    organisation?: string;
}

@Component({
    selector: 'app-authview',
    templateUrl: './authview.component.html',
    styleUrls: ['./authview.component.scss'],
})
export class AuthviewComponent implements OnInit {

    // property type declarations
    PageTitle = 'PAGETITLE';
    basesurl: string; // base server url
    // vm ={};
    auth: AuthLayout = {};

    // Form data for the login modal
    registerData = {};

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
    }

    // Initialisation routine
    ngOnInit() {
    }

}
