import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: AboutBT
// Purpose: Display an About screen.
// Porting Status: Ported to Ionic 5 on 09/09/19

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
    // property type declarations

    anonymous_version: boolean;
    basesurl: string; // base server url
    PageTitle = 'Participant information';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    shownGroup: any;
    shownGroupIntroduction: any;
    shownGroupProperty: any;
    shownGroupRestrictions: any;
    shownGroupContent: any;
    shownGroupWarranties: any;
    shownGroupLimitation: any;
    shownGroupIndemnification: any;
    shownGroupSeverability: any;
    shownGroupVariation: any;
    shownGroupAssignment: any;
    shownGroupAgreement: any;
    shownGroupLaw: any;
    wordBoost: boolean;

// constructor
    constructor(public router: Router) {
        this.anonymous_version = true;
        if (environment.wordboost) {
            this.wordBoost = true;
            this.PageTitle = 'Information for study participants';
        } else {
            this.wordBoost = false;
        }
    }

// Initialisation routine
    ngOnInit() {
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    
    toggleGroup(index) {
        if (this.isGroupShown(index)) {
              this.shownGroup = index;
          } else {
              this.shownGroup = null;
          }
      };
  
      isGroupShown(index) {
          return this.shownGroup === null;
      };
      
      toggleGroupProperty(Property) {
        if (this.isGroupShownProperty(Property)) {
              this.shownGroupProperty = Property;
          } else {
              this.shownGroupProperty = null;
          }
      };
  
      isGroupShownProperty(Property) {
          return this.shownGroupProperty === null;
      };

      toggleGroupRestrictions(Restrictions) {
        if (this.isGroupShownRestrictions(Restrictions)) {
              this.shownGroupRestrictions = Restrictions;
          } else {
              this.shownGroupRestrictions = null;
          }
      };
  
      isGroupShownRestrictions(Restrictions) {
          return this.shownGroupRestrictions === null;
      };

      toggleGroupContent(Content) {
        if (this.isGroupShownContent(Content)) {
              this.shownGroupContent = Content;
          } else {
              this.shownGroupContent = null;
          }
      };
  
      isGroupShownContent(Content) {
          return this.shownGroupContent === null;
      };

      toggleGroupWarranties(Warranties) {
        if (this.isGroupShownWarranties(Warranties)) {
              this.shownGroupWarranties = Warranties;
          } else {
              this.shownGroupWarranties = null;
          }
      };
  
      isGroupShownWarranties(Warranties) {
          return this.shownGroupWarranties === null;
      };

      toggleGroupLimitation(Limitation) {
        if (this.isGroupShownLimitation(Limitation)) {
              this.shownGroupLimitation = Limitation;
          } else {
              this.shownGroupLimitation = null;
          }
      };
  
      isGroupShownLimitation(Limitation) {
          return this.shownGroupLimitation === null;
      };

      toggleGroupIndemnification(Indemnification) {
        if (this.isGroupShownIndemnification(Indemnification)) {
              this.shownGroupIndemnification = Indemnification;
          } else {
              this.shownGroupIndemnification = null;
          }
      };
  
      isGroupShownIndemnification(Indemnification) {
          return this.shownGroupIndemnification === null;
      };

      toggleGroupSeverability(Severability) {
        if (this.isGroupShownSeverability(Severability)) {
              this.shownGroupSeverability = Severability;
          } else {
              this.shownGroupSeverability = null;
          }
      };
  
      isGroupShownSeverability(Severability) {
          return this.shownGroupSeverability === null;
      };

      toggleGroupVariation(Variation) {
        if (this.isGroupShownVariation(Variation)) {
              this.shownGroupVariation = Variation;
          } else {
              this.shownGroupVariation = null;
          }
      };
  
      isGroupShownVariation(Variation) {
          return this.shownGroupVariation === null;
      };

      toggleGroupAssignment(Assignment) {
        if (this.isGroupShownAssignment(Assignment)) {
              this.shownGroupAssignment = Assignment;
          } else {
              this.shownGroupAssignment = null;
          }
      };
  
      isGroupShownAssignment(Assignment) {
          return this.shownGroupAssignment === null;
      };

      toggleGroupAgreement(Agreement) {
        if (this.isGroupShownAgreement(Agreement)) {
              this.shownGroupAgreement = Agreement;
          } else {
              this.shownGroupAgreement = null;
          }
      };
  
      isGroupShownAgreement(Agreement) {
          return this.shownGroupAgreement === null;
      };

      toggleGroupLaw(Law) {
        if (this.isGroupShownLaw(Law)) {
              this.shownGroupLaw = Law;
          } else {
              this.shownGroupLaw = null;
          }
      };
  
      isGroupShownLaw(Law) {
          return this.shownGroupLaw === null;
      };

}
