import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {SelectSurveyToFinaliseService} from '../select-survey-to-finalise.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: finished
// Purpose: handle the end of a survey
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-finished',
    templateUrl: './finished.component.html',
    styleUrls: ['./finished.component.scss'],
})
export class FinishedComponent implements OnInit {
    // property type declarations
    PageTitle = 'And Finally...';
    basesurl: string; // base server url
    finalisesurveynum: any;
    surveyname: string;
    surveyid: any;
    sectiontitle = 'End of survey';
    subsectiontitle = 'Thank you!';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    finalurl: string;
    wordBoost: boolean;


// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                public selectSurveyToFinaliseService: SelectSurveyToFinaliseService) {

        this.wordBoost = environment.wordboost;
        this.surveyid = this.userInfoService.getCurrentSurvey();
        this.surveyname = this.userInfoService.getViewCompleteSurveyTitle();
        // this.finalisesurveynum = this.selectSurveyToFinaliseService.getSurveySelection();
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.finalurl = this.basesurl + 'finalise/' + this.surveyid;

    }

// Initialisation routine
    ngOnInit() {
    }

    finaliseSurvey() {
        this.spinner.show();
        // this.finalisesurveynum = this.selectSurveyToFinaliseService.getSurveySelection();
        // here we signal to the server that we wish to finalise this survey


        this.apiService.getDataObjectFromServer(this.finalurl)
            .subscribe(
                (finresponsedata: any[]) => {
                    // questionsmeta = qmdata;
                    this.spinner.hide();
                    this.apiService.successFinalData();
                    console.log('finresponsedata  ', finresponsedata);
                    // surveyname = qmdata.survey_name;
                    // sectiontitle = qmdata[0].section_title.section_title;
                    // console.log('title   ', sectiontitle);
                    // console.log('surveyname  ',surveyname);
                    this.router.navigateByUrl('/selectsurv');
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                    // this.errors = error;
                    // console.log('error finalising survey!');
                    // alert('error getting survey metadata " + response.data);
                    // go back to home screen
                    this.router.navigateByUrl('/buttons');
                });
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    backOneQuestion() {

    }
}
