import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: babydetails
// Purpose: Displays baby details for editing (via the My Children page)
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-babydetails',
    templateUrl: './babydetails.component.html',
    styleUrls: ['./babydetails.component.scss'],
})
export class BabydetailsComponent implements OnInit {
    // property type declarations
    PageTitle = 'Child Details';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private newbabyid: string;
    private mybabyname: string;
    private compsurveylisturl: string;
    private upcomsurveylisturl: string;
    private mycompletedsurveys: any;
    private titlemessage: string;
    private myupcomingsurveys: any;
// base server url
    private basesurl: string;

    wordBoost: boolean;
// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
        if (environment.wordboost) {
            this.wordBoost = true;

        } else {
            this.wordBoost = false;
        }

    }

// Initialisation routine
    ngOnInit() {
        this.newbabyid = this.userInfoService.getViewBabyID();
        this.mybabyname = this.userInfoService.getViewBabyName();
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.compsurveylisturl = this.basesurl + 'mycompsurveys/' + this.newbabyid;
        this.upcomsurveylisturl = this.basesurl + 'myupcomsurveys/' + this.newbabyid;
        this.spinner.show();

        // Ajax call to get surveys list--
        console.log(this.compsurveylisturl);
        this.apiService.getDataFromServer(this.compsurveylisturl)
            .subscribe(
                (data: any[]) => {
                    this.mycompletedsurveys = data;
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                    // this.errors = error;

                });
        // -------------------

        this.apiService.getDataFromServer(this.upcomsurveylisturl)
            .subscribe(
                (data: any[]) => {

                    // console.log('Contacting server...');
                    this.myupcomingsurveys = data;
                    this.spinner.hide();
                    // add the answer field to the model
                    // addAnsField();
                    // console.log('got surveys data.');
                    // if (environment.verboseDebugMode === true) { console.log(data); }
                }, error => {
                    this.spinner.hide();
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                    // this.errors = error;

                });
        // -------------------

    }

    showDatePretty(datetime) {
        if (datetime == undefined) {
            datetime = '';
        }
        // Split timestamp into [ Y, M, D, h, m, s ]
        const t = datetime.split(/[- :]/);
        // Apply each element to the Date function
        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2]));
        return (d);
    }

    showDateSmallPretty(datetime) {
        if (datetime == undefined) {
            datetime = '';
        }
        // Split timestamp into [ Y, M, D, h, m, s ]
        const t = datetime.split(/[- :]/);
        // Apply each element to the Date function
        const d = t[2] + '/' + t[1] + '/' + t[0] + ' ' + t[3] + ':' + t[4] + ':' + t[5];
        return (d);
    }

    showMyChildrenPage() {
        this.router.navigateByUrl('/mybaby');
    }

    showButtonsPage() {
        this.router.navigateByUrl('/buttons');
    }

    showCompletedSurvey(surveyid) {
        this.userInfoService.setCurrentReviewQuestionNumber(1);
        this.userInfoService.setViewCompleteSurveyID(surveyid);
        for (const key of this.mycompletedsurveys) {
            if (this.mycompletedsurveys.hasOwnProperty(key)) {
                const obj = this.mycompletedsurveys[key];
                if (obj.id == surveyid) {
                    this.titlemessage = obj.survey_version[0].name_of_survey + ' \n( ' + this.showDateSmallPretty(obj.finished_at) + ')';
                }
            }
        }
        this.userInfoService.setViewCompleteSurveyTitle(this.titlemessage);
        this.router.navigateByUrl('/showcompletedsurvey');
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        // this.spinner.show();
    }

}
