import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: newbabyphoto
// Purpose: handle a new photo on child's profile
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-newbabyphoto',
    templateUrl: './newbabyphoto.component.html',
    styleUrls: ['./newbabyphoto.component.scss'],
})
export class NewbabyphotoComponent implements OnInit {
    // property type declarations
    PageTitle = 'New Child Photo';
    basesurl: string;
    mybabyname: any;
    errorMsg: any;
    newbabyid: any;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    // base server url
// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
    }

// Initialisation routine
    ngOnInit() {
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {

    }

    showBabyEditPage() {

    }
}
