import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {environment} from '../../environments/environment';
import {transition, trigger, useAnimation} from '@angular/animations';
import {bounce} from 'ng-animate';

@Component({
    selector: 'app-showprogress',
    templateUrl: './showprogress.component.html',
    styleUrls: ['./showprogress.component.scss'],
    animations: [trigger('bounce', [transition('* => *', useAnimation(bounce))])],
})

export class ShowprogressComponent implements OnInit {
    bounce: any;
    selectedbabyId: any;
    selectedbabyName: any;
    selectedbabyDob: any;
    selectedbabyPic: any;
    PageTitle = 'Your Child\'s Progress';
    wordBoost: boolean;

    // constructor
    constructor(
        public router: Router,
        public storage: Storage
    ) {
        this.wordBoost = environment.wordboost;
    }

    ionViewWillEnter() {
        console.log('In ion WIll Enter');
        this.getBabyInfo();
    }

    getBabyInfo() {
        console.log('In GEt Info');
        // Or to get a key/value pair
        this.selectedbabyName = '';
        this.storage.get('selectedbaby').then(val => {
            this.selectedbabyName = val[0].baby_name;
            if (environment.verboseDebugMode === true) {
                console.log(this.selectedbabyName);
            }
        });
    }

    // Initialisation routine
    ngOnInit() {
    }

    showButtonsPage() {
        this.router.navigateByUrl('/buttons');
    }

    doughnut() {

        const self = this;
        this.animateCSS('.byCategorybutton', 'tada', () => {
            self.router.navigateByUrl('/progress-doughnut-chart');
        });


    }

    barchart() {

        const self = this;
        this.animateCSS('.growthbutton', 'tada', () => {

            self.router.navigateByUrl('/progress-pie-chart');
        });

    }

    onChange(event) {
        if (environment.verboseDebugMode === true) {
            console.log(event);
        }
    }

    animateCSS(element, animationName, callback) {
        const node = document.querySelector(element);
        node.classList.add('animated', animationName);

        function handleAnimationEnd() {
            node.classList.remove('animated', animationName);
            node.removeEventListener('animationend', handleAnimationEnd);
            if (typeof callback === 'function') {
                callback();
            }
        }

        node.addEventListener('animationend', handleAnimationEnd);
    }
}
