import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {transition, trigger, useAnimation} from '@angular/animations';
import {bounce} from 'ng-animate';
import {FlashCardsLayout} from '../interfaces';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: Flashcards
// This displays the flashcards page
// Purpose: general word recognition exercise
// Added Aug 2019

@Component({
    selector: 'app-flashcards',
    templateUrl: './flashcards.component.html',
    styleUrls: ['./flashcards.component.scss'],
    animations: [
        trigger('bounce', [transition('* => *', useAnimation(bounce))])
    ],
})
export class FlashcardsComponent implements OnInit {
    [x: string]: any;

    // base server url
    basesurl: string;
    // property type declarations
    PageTitle = 'Word Flashcards';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    bounce: any;
    flashCardsArray = new Array<FlashCardsLayout>();
    flashCardsSelection = new Array<FlashCardsLayout>();
    categoryfilter: string[];
    flipped: any;
    shownGroupWork: any;
    shownGroupContact: any;
    private babyname: string;
    wordBoost: boolean;
    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
        this.wordBoost = environment.wordboost;

    }

    // Initialisation routine
    ngOnInit() {

        // $(document).ready(function () {
        //     alert("J")
        //     jQuery('.hover').on('click', function () {
        //         jQuery(this).toggleClass('flipped');
        //     });
        // });

        this.categoryfilter = [];
        // 'ACTIVITES', 'FOOD', 'TOYS', 'ANIMALS', 'HOME', 'COLOURS', 'CLOTHES', 'TRANSPORT', 'BODYPARTS', 'JOBS', FAMILY'];

        // create a list of all flashcards
        // May as well do this locally rather than via the server

        // initialise flashcards array

        this.flashCardsArray.push({
            name: 'beach',
            filename: '../../assets/flashcards/activities/beach.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'camping',
            filename: '../../assets/flashcards/activities/camping.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'church *',
            filename: '../../assets/flashcards/activities/church.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'cinema / pictures',
            filename: '../../assets/flashcards/activities/cinema.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'circus',
            filename: '../../assets/flashcards/activities/circus.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'countryside',
            filename: '../../assets/flashcards/activities/countryside.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'farm',
            filename: '../../assets/flashcards/activities/farm.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'flower',
            filename: '../../assets/flashcards/activities/flower.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'garden',
            filename: '../../assets/flashcards/activities/garden.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'grass',
            filename: '../../assets/flashcards/activities/grass.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'home',
            filename: '../../assets/flashcards/activities/home.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'house',
            filename: '../../assets/flashcards/activities/house.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'moon',
            filename: '../../assets/flashcards/activities/moon.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'outside',
            filename: '../../assets/flashcards/activities/outside.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'park',
            filename: '../../assets/flashcards/activities/park.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'party',
            filename: '../../assets/flashcards/activities/party.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'petrol station',
            filename: '../../assets/flashcards/activities/petrol station.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'picnic',
            filename: '../../assets/flashcards/activities/picnic.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'playground',
            filename: '../../assets/flashcards/activities/playground.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'rain',
            filename: '../../assets/flashcards/activities/rain.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'road',
            filename: '../../assets/flashcards/activities/road.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'school',
            filename: '../../assets/flashcards/activities/school.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'seaside',
            filename: '../../assets/flashcards/activities/beach.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'shop',
            filename: '../../assets/flashcards/activities/shop.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sky',
            filename: '../../assets/flashcards/activities/sky.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'slide',
            filename: '../../assets/flashcards/activities/slide.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'snow',
            filename: '../../assets/flashcards/activities/snow.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'star',
            filename: '../../assets/flashcards/activities/star.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'stone',
            filename: '../../assets/flashcards/home/rock.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sun',
            filename: '../../assets/flashcards/activities/sun.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'swing',
            filename: '../../assets/flashcards/activities/swing.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'town',
            filename: '../../assets/flashcards/activities/town.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'tree',
            filename: '../../assets/flashcards/activities/tree.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'wall',
            filename: '../../assets/flashcards/activities/wall.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'woods',
            filename: '../../assets/flashcards/activities/woods.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'work',
            filename: '../../assets/flashcards/activities/work.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'zoo',
            filename: '../../assets/flashcards/activities/zoo.png',
            category: 'ACTIVITIES',
            ToddlerCDI: true,
            CDI: false
        });

        this.flashCardsArray.push({
            name: 'alligator',
            filename: '../../assets/flashcards/animals/alligator.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'ant',
            filename: '../../assets/flashcards/animals/ant.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'bear',
            filename: '../../assets/flashcards/animals/bear.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bee',
            filename: '../../assets/flashcards/animals/bee.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bird',
            filename: '../../assets/flashcards/animals/bird.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bug/ beastie',
            filename: '../../assets/flashcards/animals/bug.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bunny/ rabbit',
            filename: '../../assets/flashcards/animals/bunny.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'butterfly',
            filename: '../../assets/flashcards/animals/butterfly.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cat/ pussycat',
            filename: '../../assets/flashcards/animals/cat.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'chicken',
            filename: '../../assets/flashcards/animals/chicken.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cockerel',
            filename: '../../assets/flashcards/animals/cockerel.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'cow',
            filename: '../../assets/flashcards/animals/cow.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'deer',
            filename: '../../assets/flashcards/animals/deer.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'dog/ doggie',
            filename: '../../assets/flashcards/animals/dog.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'donkey',
            filename: '../../assets/flashcards/animals/donkey.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'duck',
            filename: '../../assets/flashcards/animals/duck.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'elephant',
            filename: '../../assets/flashcards/animals/elephant.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'fish',
            filename: '../../assets/flashcards/animals/fish.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'fly',
            filename: '../../assets/flashcards/animals/fly.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'frog',
            filename: '../../assets/flashcards/animals/frog.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'giraffe',
            filename: '../../assets/flashcards/animals/giraffe.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'goose',
            filename: '../../assets/flashcards/animals/goose.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'hen',
            filename: '../../assets/flashcards/animals/hen.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'horse',
            filename: '../../assets/flashcards/animals/horse.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'kitten/ kitty',
            filename: '../../assets/flashcards/animals/kitten.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'lamb',
            filename: '../../assets/flashcards/animals/lamb.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'lion',
            filename: '../../assets/flashcards/animals/lion.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'monkey',
            filename: '../../assets/flashcards/animals/monkey.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'moose',
            filename: '../../assets/flashcards/animals/moose.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'mouse',
            filename: '../../assets/flashcards/animals/mouse.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'owl',
            filename: '../../assets/flashcards/animals/owl.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'penguin',
            filename: '../../assets/flashcards/animals/penguin.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pig',
            filename: '../../assets/flashcards/animals/pig.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pony',
            filename: '../../assets/flashcards/animals/pony.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'puppy',
            filename: '../../assets/flashcards/animals/puppy.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sheep',
            filename: '../../assets/flashcards/animals/sheep.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'snake',
            filename: '../../assets/flashcards/animals/snake.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'spider',
            filename: '../../assets/flashcards/animals/spider.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'squirrel',
            filename: '../../assets/flashcards/animals/squirrel.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tiger',
            filename: '../../assets/flashcards/animals/tiger.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'turkey',
            filename: '../../assets/flashcards/animals/turkey.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'turtle',
            filename: '../../assets/flashcards/animals/turtle.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'wolf',
            filename: '../../assets/flashcards/animals/wolf.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'zebra',
            filename: '../../assets/flashcards/animals/zebra.png',
            category: 'ANIMALS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'ankle',
            filename: '../../assets/flashcards/bodyparts/ankle.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'arm',
            filename: '../../assets/flashcards/bodyparts/arm.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'back',
            filename: '../../assets/flashcards/bodyparts/back.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'belly button/ tummy button',
            filename: '../../assets/flashcards/bodyparts/belly button.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'buttocks/ bum/ bottom',
            filename: '../../assets/flashcards/bodyparts/buttocks.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cheek',
            filename: '../../assets/flashcards/bodyparts/cheek.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'chin',
            filename: '../../assets/flashcards/bodyparts/chin.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'ear',
            filename: '../../assets/flashcards/bodyparts/ear.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'ear/ tab',
            filename: '../../assets/flashcards/bodyparts/ear.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'elbow',
            filename: '../../assets/flashcards/bodyparts/elbow.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'eye',
            filename: '../../assets/flashcards/bodyparts/eye.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'face',
            filename: '../../assets/flashcards/bodyparts/face.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'feet',
            filename: '../../assets/flashcards/bodyparts/feet.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'finger',
            filename: '../../assets/flashcards/bodyparts/finger.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'foot',
            filename: '../../assets/flashcards/bodyparts/foot.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'hair',
            filename: '../../assets/flashcards/bodyparts/hair.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'hand',
            filename: '../../assets/flashcards/bodyparts/hand.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'head',
            filename: '../../assets/flashcards/bodyparts/head.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'knee',
            filename: '../../assets/flashcards/bodyparts/knee.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'leg',
            filename: '../../assets/flashcards/bodyparts/leg.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'lips',
            filename: '../../assets/flashcards/bodyparts/lips.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'mouth',
            filename: '../../assets/flashcards/bodyparts/mouth.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'neck',
            filename: '../../assets/flashcards/bodyparts/neck.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'nose',
            filename: '../../assets/flashcards/bodyparts/nose.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'shoulder',
            filename: '../../assets/flashcards/bodyparts/shoulder.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'toe',
            filename: '../../assets/flashcards/bodyparts/toe.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tongue',
            filename: '../../assets/flashcards/bodyparts/tongue.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tooth',
            filename: '../../assets/flashcards/bodyparts/tooth.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'tooth/ teeth',
            filename: '../../assets/flashcards/bodyparts/tooth.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tummy',
            filename: '../../assets/flashcards/bodyparts/tummy.png',
            category: 'BODY PARTS',
            ToddlerCDI: true,
            CDI: false
        });

        this.flashCardsArray.push({
            name: 'belt',
            filename: '../../assets/flashcards/clothes/belt.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'bib',
            filename: '../../assets/flashcards/clothes/bib.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'boot',
            filename: '../../assets/flashcards/clothes/boot.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'boots',
            filename: '../../assets/flashcards/clothes/boots.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cardigan',
            filename: '../../assets/flashcards/clothes/cardigan.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'coat',
            filename: '../../assets/flashcards/clothes/coat.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'dress',
            filename: '../../assets/flashcards/clothes/dress.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'glasses/ specs',
            filename: '../../assets/flashcards/clothes/glasses.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'gloves',
            filename: '../../assets/flashcards/clothes/gloves.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'hat',
            filename: '../../assets/flashcards/clothes/hat.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'jacket',
            filename: '../../assets/flashcards/clothes/jacket.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'jeans',
            filename: '../../assets/flashcards/clothes/jeans.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'jumper',
            filename: '../../assets/flashcards/clothes/jumper.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'mittens',
            filename: '../../assets/flashcards/clothes/mittens.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'nappy',
            filename: '../../assets/flashcards/clothes/nappy.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'necklace',
            filename: '../../assets/flashcards/clothes/necklace.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pants/ underpants',
            filename: '../../assets/flashcards/clothes/underpants.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pyjamas/ PJs/ jim jams',
            filename: '../../assets/flashcards/clothes/pyjamas.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'raincoat',
            filename: '../../assets/flashcards/clothes/raincoat.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'scarf',
            filename: '../../assets/flashcards/clothes/scarf.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'shirt',
            filename: '../../assets/flashcards/clothes/shirt.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'shoe',
            filename: '../../assets/flashcards/clothes/shoe.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'shoes',
            filename: '../../assets/flashcards/clothes/shoes.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'shorts',
            filename: '../../assets/flashcards/clothes/shorts.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'slippers',
            filename: '../../assets/flashcards/clothes/slippers.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'sock',
            filename: '../../assets/flashcards/clothes/sock.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tights',
            filename: '../../assets/flashcards/clothes/tights.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'trainers',
            filename: '../../assets/flashcards/clothes/trainers.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'trousers/ pants/ britches',
            filename: '../../assets/flashcards/clothes/trousers.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'vest',
            filename: '../../assets/flashcards/clothes/vest.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'zip',
            filename: '../../assets/flashcards/clothes/zip.png',
            category: 'CLOTHING',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'black',
            filename: '../../assets/flashcards/colours/black.png',
            category: 'COLOURS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'blue',
            filename: '../../assets/flashcards/colours/blue.png',
            category: 'COLOURS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'brown',
            filename: '../../assets/flashcards/colours/brown.png',
            category: 'COLOURS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'green',
            filename: '../../assets/flashcards/colours/green.png',
            category: 'COLOURS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'red',
            filename: '../../assets/flashcards/colours/red.png',
            category: 'COLOURS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'white',
            filename: '../../assets/flashcards/colours/white.png',
            category: 'COLOURS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'yellow',
            filename: '../../assets/flashcards/colours/yellow.png',
            category: 'COLOURS',
            ToddlerCDI: true,
            CDI: true
        });

        this.flashCardsArray.push({
            name: 'aunt',
            filename: '../../assets/flashcards/family/woman.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'baby',
            filename: '../../assets/flashcards/family/baby.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'uncle',
            filename: '../../assets/flashcards/family/man.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'dad',
            filename: '../../assets/flashcards/family/man.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'mum',
            filename: '../../assets/flashcards/family/woman.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'grandma',
            filename: '../../assets/flashcards/family/woman.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'grandpa',
            filename: '../../assets/flashcards/family/man.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'brother',
            filename: '../../assets/flashcards/family/boy.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sister',
            filename: '../../assets/flashcards/family/girl.png',
            category: 'FAMILY',
            ToddlerCDI: true,
            CDI: true
        });

        this.flashCardsArray.push({
            name: 'apple',
            filename: '../../assets/flashcards/food/apple.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'banana',
            filename: '../../assets/flashcards/food/banana.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'beans',
            filename: '../../assets/flashcards/food/beans.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'biscuit',
            filename: '../../assets/flashcards/food/biscuit.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bread',
            filename: '../../assets/flashcards/food/bread.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'butter',
            filename: '../../assets/flashcards/food/butter.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'butter/ spread/ marge',
            filename: '../../assets/flashcards/food/butter.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cake',
            filename: '../../assets/flashcards/food/cake.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'carrot',
            filename: '../../assets/flashcards/food/carrot.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'carrots',
            filename: '../../assets/flashcards/food/carrots.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cereal',
            filename: '../../assets/flashcards/food/cereal.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cheese',
            filename: '../../assets/flashcards/food/cheese.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'chewing gum/ bubble gum',
            filename: '../../assets/flashcards/food/chewing gum.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'chips',
            filename: '../../assets/flashcards/food/chips.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'chocolate',
            filename: '../../assets/flashcards/food/chocolate.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'chocolate biscuit',
            filename: '../../assets/flashcards/food/chocolate biscuit.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'coffee',
            filename: '../../assets/flashcards/food/coffee.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'coke',
            filename: '../../assets/flashcards/food/cola.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'cornflakes',
            filename: '../../assets/flashcards/food/cornflakes.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'cracker',
            filename: '../../assets/flashcards/food/cracker.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'crisps',
            filename: '../../assets/flashcards/food/crisps.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'doughnut',
            filename: '../../assets/flashcards/food/doughnut.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'drink',
            filename: '../../assets/flashcards/food/drink.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'egg',
            filename: '../../assets/flashcards/food/egg.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'food',
            filename: '../../assets/flashcards/food/food.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'grapes',
            filename: '../../assets/flashcards/food/grapes.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'green beans',
            filename: '../../assets/flashcards/food/green beans.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'hamburger/ burger',
            filename: '../../assets/flashcards/food/burger.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'ice',
            filename: '../../assets/flashcards/food/ice.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'ice cream',
            filename: '../../assets/flashcards/food/ice cream.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'ice lolly',
            filename: '../../assets/flashcards/food/ice lolly.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'jam',
            filename: '../../assets/flashcards/food/jam.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'jelly',
            filename: '../../assets/flashcards/food/jelly.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'juice',
            filename: '../../assets/flashcards/food/juice.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'lollipop/ lolly',
            filename: '../../assets/flashcards/food/lollipop.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'meat',
            filename: '../../assets/flashcards/food/meat.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'melon',
            filename: '../../assets/flashcards/food/melon.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'milk',
            filename: '../../assets/flashcards/food/milk.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'muffin/ bun',
            filename: '../../assets/flashcards/food/muffin.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'nuts',
            filename: '../../assets/flashcards/food/nuts.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'orange',
            filename: '../../assets/flashcards/food/orange.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pancake',
            filename: '../../assets/flashcards/food/pancake.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pasta',
            filename: '../../assets/flashcards/food/pasta.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pasta/ spaghetti',
            filename: '../../assets/flashcards/food/pasta.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'peanut butter',
            filename: '../../assets/flashcards/food/peanut butter.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'peas',
            filename: '../../assets/flashcards/food/peas.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pickle',
            filename: '../../assets/flashcards/food/pickle.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pizza',
            filename: '../../assets/flashcards/food/pizza.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'plum',
            filename: '../../assets/flashcards/food/plum.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pop',
            filename: '../../assets/flashcards/food/pop.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'popcorn',
            filename: '../../assets/flashcards/food/popcorn.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'potato',
            filename: '../../assets/flashcards/food/potato.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'potato/ spud/ tattie',
            filename: '../../assets/flashcards/food/potato.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pudding',
            filename: '../../assets/flashcards/food/pudding.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pumpkin',
            filename: '../../assets/flashcards/food/pumpkin.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'raisin',
            filename: '../../assets/flashcards/food/raisin.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'salt',
            filename: '../../assets/flashcards/food/salt.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'sandwich',
            filename: '../../assets/flashcards/food/sandwich.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'sandwich/ butty',
            filename: '../../assets/flashcards/food/sandwich.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sauce',
            filename: '../../assets/flashcards/food/sauce.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'sausages',
            filename: '../../assets/flashcards/food/sausages.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'soup',
            filename: '../../assets/flashcards/food/soup.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'strawberry',
            filename: '../../assets/flashcards/food/strawberry.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'sweets',
            filename: '../../assets/flashcards/food/sweets.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'tea/ brew (drink)',
            filename: '../../assets/flashcards/food/tea.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'toast',
            filename: '../../assets/flashcards/food/toast.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tomato sauce/ ketchup',
            filename: '../../assets/flashcards/food/sauce.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'tuna',
            filename: '../../assets/flashcards/food/tuna.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'vanilla',
            filename: '../../assets/flashcards/food/vanilla.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'vitamins',
            filename: '../../assets/flashcards/food/vitamins.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'water',
            filename: '../../assets/flashcards/food/water.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'yoghurt',
            filename: '../../assets/flashcards/food/yoghurt.png',
            category: 'FOOD',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'backyard',
            filename: '../../assets/flashcards/home/backyard.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'bag',
            filename: '../../assets/flashcards/home/bag.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bathroom',
            filename: '../../assets/flashcards/home/bathroom.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bathtub/ bath',
            filename: '../../assets/flashcards/home/bath tub.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bed',
            filename: '../../assets/flashcards/home/bed.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bedroom',
            filename: '../../assets/flashcards/home/bedroom.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bench',
            filename: '../../assets/flashcards/home/bench.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'bin',
            filename: '../../assets/flashcards/home/bin.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'blanket',
            filename: '../../assets/flashcards/home/blanket.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'bottle',
            filename: '../../assets/flashcards/home/bottle.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bowl',
            filename: '../../assets/flashcards/home/bowl.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'box',
            filename: '../../assets/flashcards/home/box.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'broom',
            filename: '../../assets/flashcards/home/broom.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'brush',
            filename: '../../assets/flashcards/home/brush.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bucket',
            filename: '../../assets/flashcards/home/bucket.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'camera',
            filename: '../../assets/flashcards/home/camera.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'can / tin',
            filename: '../../assets/flashcards/home/can.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'cellar',
            filename: '../../assets/flashcards/home/cellar.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'chair',
            filename: '../../assets/flashcards/home/chair.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'clock',
            filename: '../../assets/flashcards/home/clock.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cloud',
            filename: '../../assets/flashcards/home/cloud.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'comb',
            filename: '../../assets/flashcards/home/comb.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'computer/ laptop',
            filename: '../../assets/flashcards/home/computer.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cooker',
            filename: '../../assets/flashcards/home/cooker.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cot',
            filename: '../../assets/flashcards/home/cot.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cup',
            filename: '../../assets/flashcards/home/cup.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'cupboard',
            filename: '../../assets/flashcards/home/cupboard.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'dining room',
            filename: '../../assets/flashcards/home/dining room.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'dish',
            filename: '../../assets/flashcards/home/dish.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'door',
            filename: '../../assets/flashcards/home/door.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'downstairs',
            filename: '../../assets/flashcards/home/downstairs.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'drawer',
            filename: '../../assets/flashcards/home/drawer.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'dryer',
            filename: '../../assets/flashcards/home/dryer.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'dummy/ dodie/ soother',
            filename: '../../assets/flashcards/home/dummy.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'flag',
            filename: '../../assets/flashcards/home/flag.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'fork',
            filename: '../../assets/flashcards/home/fork.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'garage',
            filename: '../../assets/flashcards/home/garage.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'glass',
            filename: '../../assets/flashcards/home/glass.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'hammer',
            filename: '../../assets/flashcards/home/hammer.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'high chair',
            filename: '../../assets/flashcards/home/highchair.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'hoover / vacuum',
            filename: '../../assets/flashcards/home/hoover.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'hose-pipe',
            filename: '../../assets/flashcards/home/hosepipe.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'jar',
            filename: '../../assets/flashcards/home/jar.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'key',
            filename: '../../assets/flashcards/home/key.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'keys',
            filename: '../../assets/flashcards/home/keys.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'kitchen',
            filename: '../../assets/flashcards/home/kitchen.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'knife',
            filename: '../../assets/flashcards/home/knife.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'ladder',
            filename: '../../assets/flashcards/home/ladder.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'lamp',
            filename: '../../assets/flashcards/home/lamp.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'lamp/ light',
            filename: '../../assets/flashcards/home/lamp.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'lawn mower',
            filename: '../../assets/flashcards/home/lawnmower.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'light',
            filename: '../../assets/flashcards/home/light.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'living room',
            filename: '../../assets/flashcards/home/living room.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'living room/ lounge/ dining room',
            filename: '../../assets/flashcards/home/living room.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'medicine',
            filename: '../../assets/flashcards/home/medicine.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'money',
            filename: '../../assets/flashcards/home/money.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'mop',
            filename: '../../assets/flashcards/home/mop.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'nail',
            filename: '../../assets/flashcards/home/nail.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'napkin',
            filename: '../../assets/flashcards/home/napkin.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'oven',
            filename: '../../assets/flashcards/home/cooker.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'paper',
            filename: '../../assets/flashcards/home/paper.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'path',
            filename: '../../assets/flashcards/home/path.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'penny',
            filename: '../../assets/flashcards/home/penny.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'phone/ telephone/ mobile',
            filename: '../../assets/flashcards/home/phone.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'picture',
            filename: '../../assets/flashcards/home/picture.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pillow',
            filename: '../../assets/flashcards/home/pillow.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'plant',
            filename: '../../assets/flashcards/home/plant.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'plate',
            filename: '../../assets/flashcards/home/plate.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'play pen',
            filename: '../../assets/flashcards/home/playpen.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pond',
            filename: '../../assets/flashcards/home/pond.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'porch',
            filename: '../../assets/flashcards/home/porch.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'potty',
            filename: '../../assets/flashcards/home/potty.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'purse',
            filename: '../../assets/flashcards/home/purse.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'radio',
            filename: '../../assets/flashcards/home/radio.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'refrigerator/ fridge',
            filename: '../../assets/flashcards/home/refrigerator.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'rock / stone',
            filename: '../../assets/flashcards/home/rock.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'rocking chair',
            filename: '../../assets/flashcards/home/rocking chair.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'roof',
            filename: '../../assets/flashcards/home/roof.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'room',
            filename: '../../assets/flashcards/home/room.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'rubbish',
            filename: '../../assets/flashcards/home/rubbish.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sandpit',
            filename: '../../assets/flashcards/home/sandpit.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'scissors',
            filename: '../../assets/flashcards/home/scissors.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sellotape',
            filename: '../../assets/flashcards/home/sellotape.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'settee/ sofa/ couch',
            filename: '../../assets/flashcards/home/settee.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'shovel / spade',
            filename: '../../assets/flashcards/home/spade.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'shower',
            filename: '../../assets/flashcards/home/shower.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'sink',
            filename: '../../assets/flashcards/home/sink.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'snowman',
            filename: '../../assets/flashcards/home/snowman.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'soap',
            filename: '../../assets/flashcards/home/soap.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sofa / settee',
            filename: '../../assets/flashcards/home/settee.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'spoon',
            filename: '../../assets/flashcards/home/spoon.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'stair gate',
            filename: '../../assets/flashcards/home/stair gate.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'stairs',
            filename: '../../assets/flashcards/home/stairs.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'stick',
            filename: '../../assets/flashcards/home/stick.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'stove',
            filename: '../../assets/flashcards/home/stove.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'street',
            filename: '../../assets/flashcards/home/street.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'table',
            filename: '../../assets/flashcards/home/table.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tape',
            filename: '../../assets/flashcards/home/tape.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'telephone / phone',
            filename: '../../assets/flashcards/home/telephone.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'television/ telly/ TV',
            filename: '../../assets/flashcards/home/television.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tissue',
            filename: '../../assets/flashcards/home/tissue.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'toilet/ lav/ loo',
            filename: '../../assets/flashcards/home/toilet.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'toothbrush',
            filename: '../../assets/flashcards/home/toothbrush.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'towel',
            filename: '../../assets/flashcards/home/towel.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tray',
            filename: '../../assets/flashcards/home/tray.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'upstairs',
            filename: '../../assets/flashcards/home/upstairs.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'washing machine / washer',
            filename: '../../assets/flashcards/home/washing machine.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'watch',
            filename: '../../assets/flashcards/home/watch.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'wheelbarrow',
            filename: '../../assets/flashcards/home/wheelbarrow.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'wind',
            filename: '../../assets/flashcards/home/wind.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'window',
            filename: '../../assets/flashcards/home/window.png',
            category: 'HOME',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'nurse',
            filename: '../../assets/flashcards/jobs/nurse.png',
            category: 'JOBS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'doctor',
            filename: '../../assets/flashcards/jobs/doctor.png',
            category: 'JOBS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'fireman',
            filename: '../../assets/flashcards/jobs/fireman.png',
            category: 'JOBS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'clown',
            filename: '../../assets/flashcards/jobs/clown.png',
            category: 'JOBS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'teacher',
            filename: '../../assets/flashcards/jobs/teacher.png',
            category: 'JOBS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'police',
            filename: '../../assets/flashcards/jobs/police.png',
            category: 'JOBS',
            ToddlerCDI: true,
            CDI: true
        });

        this.flashCardsArray.push({
            name: 'ball',
            filename: '../../assets/flashcards/toys/ball.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'balloon',
            filename: '../../assets/flashcards/toys/balloon.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bat',
            filename: '../../assets/flashcards/toys/bat.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'block',
            filename: '../../assets/flashcards/toys/block.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'book',
            filename: '../../assets/flashcards/toys/book.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'brick',
            filename: '../../assets/flashcards/toys/brick.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bubble',
            filename: '../../assets/flashcards/toys/bubble.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'bubbles',
            filename: '../../assets/flashcards/toys/bubbles.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'chalk',
            filename: '../../assets/flashcards/toys/chalk.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'crayon',
            filename: '../../assets/flashcards/toys/crayon.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'doll',
            filename: '../../assets/flashcards/toys/doll.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'game',
            filename: '../../assets/flashcards/toys/game.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pen',
            filename: '../../assets/flashcards/toys/pen.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pencil',
            filename: '../../assets/flashcards/toys/pencil.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'play dough',
            filename: '../../assets/flashcards/toys/playdough.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'present',
            filename: '../../assets/flashcards/toys/present.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'puzzle',
            filename: '../../assets/flashcards/toys/puzzle.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'story',
            filename: '../../assets/flashcards/toys/story.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'teddy/ teddy bear',
            filename: '../../assets/flashcards/toys/teddy bear.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'toy',
            filename: '../../assets/flashcards/toys/toy.png',
            category: 'TOYS',
            ToddlerCDI: true,
            CDI: true
        });

        this.flashCardsArray.push({
            name: 'aeroplane/ plane',
            filename: '../../assets/flashcards/transport/aeroplane.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bicycle/ bike',
            filename: '../../assets/flashcards/transport/bicycle.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'boat',
            filename: '../../assets/flashcards/transport/boat.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'bus',
            filename: '../../assets/flashcards/transport/bus.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'car',
            filename: '../../assets/flashcards/transport/car.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'fire engine',
            filename: '../../assets/flashcards/transport/fireengine.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'helicopter',
            filename: '../../assets/flashcards/transport/helicopter.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'lorry/ truck',
            filename: '../../assets/flashcards/transport/lorry.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'motorbike',
            filename: '../../assets/flashcards/transport/motorbike.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'pram',
            filename: '../../assets/flashcards/transport/pram.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'pushchair/ buggy/ stroller',
            filename: '../../assets/flashcards/transport/pushchair.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'sleigh',
            filename: '../../assets/flashcards/transport/sleigh.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: false
        });
        this.flashCardsArray.push({
            name: 'tractor',
            filename: '../../assets/flashcards/transport/tractor.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'train',
            filename: '../../assets/flashcards/transport/train.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: true
        });
        this.flashCardsArray.push({
            name: 'tricycle',
            filename: '../../assets/flashcards/transport/tricycle.png',
            category: 'TRANSPORT',
            ToddlerCDI: true,
            CDI: false
        });

        // TODO: Now add any personalised flashcards to array
        //
        //


        // Now we choose 9 random flashcards to render this page
        let fcardindex;
        let x = 0;

        for (fcardindex = 0; fcardindex <= 8; fcardindex++) {
            const wordlistsize = this.flashCardsArray.length;
            x = Math.round((Math.random() * 100) * wordlistsize / 100);

            // TODO: is this one already in the selection array?
            // We don't want to duplicate a flashcard on the same page
            // so decrement the index and go again

            // if not already there, add it
            this.flashCardsArray[x].flipped = false;
            this.flashCardsSelection.push((this.flashCardsArray)[x]);

        }

        this.babyname = this.userInfoService.getBabyName();

    }

    // flipIt() {
    //     this.flipped = !this.flipped;
    //   }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {
        /// console.log('Show spinner');
        // console.log('refresh screen');
        this.spinner.show();
    }

    // reseting =

    refreshCards() {

        this.apiService.setFlipValue(false);
        // console.log('Data', this.flashCardsSelection);
        console.log('refresh cards');

        let fcardindex = 0;
        let x = 0;
        // clear selection
        for (fcardindex = 0; fcardindex < 9; fcardindex++) {
            this.flashCardsSelection.pop();
        }

        // populate selection
        for (fcardindex = 0; fcardindex < 9; fcardindex++) {
            // console.log('[[' + fcardindex + ']]');
            const wordlistsize = this.flashCardsArray.length;

            x = Math.round((Math.random() * 100) * wordlistsize / 100);
            //    console.log('x=' + x);
            if (environment.verboseDebugMode === true) {
                //        console.log(this.flashCardsArray[x].category);
            }
            if (environment.verboseDebugMode === true) {
                //        console.log(this.flashCardsArray[x].name);
            }
            if (this.categoryfilter.length > 0) {
                //        console.log('catfilt defined');
                // if (environment.verboseDebugMode === true) { console.log(this.categoryfilter); }
                // check the category filter
                // console.log('is ' + this.flashCardsArray[x].category + ' in ' + this.categoryfilter + '?');
                if (this.categoryfilter.indexOf(this.flashCardsArray[x].category) == -1) {
                    // not filtered
                    // console.log('not filtered');

                    // check for duplicates
                    // is this one already in the selection array?
                    // if (this.flashCardsSelection.indexOf(this.flashCardsArray[x].name)) {
                    // console.log('checking for dupes');

                    if (this.selectionContains(this.flashCardsSelection, this.flashCardsArray[x].name)) {
                        // console.log('dupe found');
                        // if so, decrement the index and go again
                        fcardindex = fcardindex - 1;
                    } else {
                        // if not, add it
                        // console.log('no dupe, adding');
                        this.flashCardsArray[x].flipped = false;
                        this.flashCardsSelection.push((this.flashCardsArray)[x]);
                    }

                } else {
                    // filtered, so decrement index and go again
                    // console.log('category filtered!');
                    fcardindex = fcardindex - 1;
                }
            } else {
                // no filter defined
                // console.log('no filter defined, adding');
                this.flashCardsArray[x].flipped = false;
                this.flashCardsSelection.push((this.flashCardsArray)[x]);
            }
        }
        // console.log('...........');
        // console.log(this.flashCardsSelection);
    }

    // flipper(index) {
    //     if (environment.verboseDebugMode === true) { console.log("Index",index); }
    //     if (index >= 0) {
    //         this.apiService.id = index;
    //     } else {
    //         this.apiService.id = 0;
    //     }
    // }

    activateCategory(cat) {
        // console.log(' SET CAT=' + cat);
        this.categoryfilter.push(cat);
        if (environment.verboseDebugMode === true) {
            console.log(this.categoryfilter);
        }
    }

    trackbyFn(item: any): string {
        // console.log(item);

        return item.name;
    }


    deactivateCategory(cat) {

        this.categoryfilter.forEach((item, index) => {
            if (item === cat) {
                this.categoryfilter.splice(index, 1);
            }
        });

    }

    selectionContains(obj, term: string): boolean {

        for (const key in obj) {

            if (obj[key].name.indexOf(term) != -1) {
                return true;
            }
        }
        return false;
    }

    // filter button
    btnActivate(ionicButton, category) {
        // console.log("ionicButton", ionicButton);
        // console.log("category", category);
        // console.clear();
        // if (environment.verboseDebugMode === true) { console.log(ionicButton._id); }
        if (environment.verboseDebugMode === true) {
            console.log('activate');
        }
        if (ionicButton.fill != 'solid') {
            ionicButton.fill = 'solid';
            console.log('not solid');
            // remove category from filter
            this.deactivateCategory(category);
            this.refreshCards();
        } else {
            if (environment.verboseDebugMode === true) {
                console.log('solid');
            }
            ionicButton.fill = 'outline';

            // add category to filter
            // check that it won't leave just the colours left
            if ((this.categoryfilter.indexOf('COLOURS') == -1) && (this.categoryfilter.length == 7)) {
                console.log('Cannot have colours category on its own');
            } else {
                this.activateCategory(category);
                this.refreshCards();
            }
        }
    }

    imgActivate(ionicImage, category) {
        // console.log("ionicButton", ionicButton);
        // console.log("category", category);
        // console.clear();
        // if (environment.verboseDebugMode === true) { console.log(ionicButton._id); }
        if (environment.verboseDebugMode === true) {
            console.log('activate');
        }
        console.log(ionicImage.src);
        console.log(ionicImage.src.search('dull'));

        console.log('---------');
        if (ionicImage.src.search('dull') != -1) {
            ionicImage.src = ionicImage.src.replace('_dull', 'erbut');
            console.log('allow ' + category);
            console.log(ionicImage.src);
            this.deactivateCategory(category);
            this.refreshCards();
        } else {
            if (environment.verboseDebugMode === true) {
                console.log('solid');
            }
            ionicImage.src = ionicImage.src.replace('erbut', '_dull');
            console.log('stop ' + category);
            console.log(ionicImage.src);
            // add category to filter
            // check that it won't leave just the colours left
            if ((this.categoryfilter.indexOf('COLOURS') == -1) && (this.categoryfilter.length == 7)) {
                console.log('Cannot have colours category on its own');
            } else {
                this.activateCategory(category);
                this.refreshCards();
            }
        }
    }


    isSelected(event) {
        if (environment.verboseDebugMode === true) {
            console.log(event);
        }
        return 'primary';
        // event.target.getAttribute('selected') ? 'primary' : '';
    }

    toggleGroupContact(Contact) {
        if (this.isGroupShownContact(Contact)) {
            this.shownGroupContact = Contact;
        } else {
            this.shownGroupContact = null;
        }
    }

    isGroupShownContact(Contact) {
        return this.shownGroupContact === null;
    }
}
