import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from './user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from './api.service';

// Babytalk Client V2
// Component: settings
// Purpose: Settings page
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Injectable({
    providedIn: 'root'
})
export class SelectSurveyService {

// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {


    }


// only used to store selection from list of surveys to the actual survey
    addSurveySelection(newObj) {
        // surveySelection.push(newObj);
        // localStorage.setItem('CurrentSurveyNumber', newObj);
        localStorage.setItem('BTLK_currentSurvey', newObj);

    }

    getSurveySelection() {
        // return parseInt(localStorage.getItem('CurrentSurveyNumber'), 10);
        return parseInt(localStorage.getItem('BTLK_currentSurvey'), 10);
    }

    // return {
    // addSurveySelection: addSurveySelection,4
    // getSurveySelection: getSurveySelection
// }

}
