import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../api.service';


@Component({
  selector: 'flashCard',
  templateUrl: './flash-card.component.html',
  styleUrls: ['./flash-card.component.scss'],
})


export class FlashCardComponent implements OnInit {

  @Input() flipped: any = false;
  @Output() flip: EventEmitter<any> = new EventEmitter<any>();
  @Input() reset = false;

  constructor(private apiService: ApiService) {

  }

  // Initialisation routine
  ngOnInit() {
    console.log('ngoninit flashcard');
    this.apiService.getFlippedValue.subscribe(value => {
      console.log('flipped value', value);
      this.flipped = value;
    });
  }

  emitflip() {

    // console.clear();
    console.log('hereeeeee');
    console.log(this.flip);
    this.flip.emit();
  }

}



