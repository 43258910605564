import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';

// Babytalk Client V2
// Component: recprogress
// Purpose: ?
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-recprogress',
    templateUrl: './recprogress.component.html',
    styleUrls: ['./recprogress.component.scss'],
})
export class RecprogressComponent implements OnInit {
    // property type declarations
    PageTitle = 'Progress';
    basesurl: string; // base server url
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;

// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
    }

// Initialisation routine
    ngOnInit() {
    }

}
