import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, ActionSheetController } from '@ionic/angular';
@Component({
  selector: 'app-evidence-info-modal',
  templateUrl: './evidence-info-modal.component.html',
  styleUrls: ['./evidence-info-modal.component.scss'],
})
export class EvidenceInfoModalComponent implements OnInit {
  Evidence :any;
  constructor(private modalCtrl :ModalController) { }

  ngOnInit() {
   
}

closeModal()
{
  this.modalCtrl.dismiss({
    'dismissed': true
  });
}

}