import {Component, OnInit} from '@angular/core';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';

// Babytalk Client V2
// Component: logout
// Purpose: log out user
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})

export class LogoutComponent implements OnInit {
    // property type declarations
    PageTitle = 'Logout';
    basesurl: string; // base server url
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;

    // constructor

    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
    }

// Initialisation routine
    ngOnInit() {
    }

}
