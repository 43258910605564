import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: viewdiary
// Purpose: Display the selected child's activity diary
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-viewdiary',
    templateUrl: './viewdiary.component.html',
    styleUrls: ['./viewdiary.component.scss'],
})

export class ViewdiaryComponent implements OnInit {
    // property type declarations
    PageTitle = 'View Activity Diary';
    basesurl: string;
    message: any;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private taglist: {};
    private babyname: string;
    private newActivityurl: string;
    private activity: {};
    private babyid: string;
    private taglisturl: string;
    private userfirstname: string;
    private usersurname: string;
    private userid: string;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
    }

    // Initialisation routine
    ngOnInit() {

        // get most recent diaryentry
        if (this.userInfoService.getBabyID() != undefined) {

            this.babyname = this.userInfoService.getBabyName();
            this.basesurl = 'https://' + environment.serverUrl + '/v1/';
            this.newActivityurl = this.basesurl + 'diary/recordactivity/' + this.userInfoService.getBabyID();
            this.babyid = this.userInfoService.getBabyID();

            this.activity = {};

            //  set the date to today
            const d = new Date();
            const year = d.getFullYear().toString();
            const month = d.getMonth() + 1;
            let smonth: string;
            let sday: string;

            if (month < 10) {
                smonth = '0' + month.toString();
            } else {
                smonth = month.toString();
            }

            const day = d.getDate();
            if (day < 10) {
                sday = '0' + day.toString();
            } else {
                sday = day.toString();
            }
            // TODO
            /*
                 this.activity.date_observed = new Date(year + ' ' + smonth + ' ' + sday);
             */

            // when page is loaded, populate the drop down with activity tags
            // get the list of tags from the server

            this.taglist = {};
            this.taglisturl = this.basesurl + 'diary/activitytags/0';
            this.userfirstname = this.userInfoService.getFirstName();
            this.usersurname = this.userInfoService.getSurname();
            this.userid = this.userInfoService.getUserID();

        } else {
            // console.log("baby not defined');
            this.router.navigateByUrl('/nobaby');
        }
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();
    }

    reportNewActivity() {

    }

    toStart() {

    }

    backOneQuestion() {

    }

    forwardOneQuestion() {

    }

    toEnd() {

    }
}
