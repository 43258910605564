import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
    selector: 'app-upload-photo',
    templateUrl: './upload-photo.component.html',
    styleUrls: ['./upload-photo.component.scss'],
})
export class UploadPhotoComponent implements OnInit {
    selectedFile: File;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;

    constructor(public router: Router,
                private http: HttpClient) {
    }

    ngOnInit() {
    }


    onFileChanged(event) {
        this.selectedFile = event.target.files[0];
    }

    onUpload() {
        const uploadData = new FormData();
        uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
        this.http.post('my-backend.com/file-upload', uploadData, {
            reportProgress: true,
            observe: 'events'
        })
            .subscribe(event => {
                console.log(event); // handle event here
            });
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

}
