import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: viewwords
// Purpose: Display the selected child's vocabulary
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-viewwords',
    templateUrl: './viewwords.component.html',
    styleUrls: ['./viewwords.component.scss'],
})
export class ViewwordsComponent implements OnInit {
    message: any;
    // property type declarations
    PageTitle = 'View Words';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private newWordURL: string;
    private newWord: any;
    private babyName: string;
    private babyID: string;
    private serverBaseURL: string;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
    }

    // Initialisation routine
    ngOnInit() {
        if (this.userInfoService.getBabyID() != undefined) {

            this.serverBaseURL = 'https://' + environment.serverUrl + '/v1/';
            this.newWordURL = this.serverBaseURL + 'diary/recordword/' + this.userInfoService.getBabyID();
            this.babyID = this.userInfoService.getBabyID();
            this.babyName = this.userInfoService.getBabyName();
            this.newWord = {};

            const d = new Date();
            const syear = d.getFullYear().toString();
            const month = d.getMonth() + 1;
            let smonth: string;
            let sday: string;
            const day = d.getDate();

            if (month < 10) {
                smonth = '0' + month.toString();
            } else {
                smonth = month.toString();
            }

            if (day < 10) {
                sday = '0' + day.toString;
            } else {
                sday = day.toString();
            }

            const dateObject = new Date(syear + ' ' + smonth + ' ' + sday);

            this.newWord.date = dateObject;

        } else {
            // No baby selected
            this.router.navigateByUrl('/nobaby');
        }
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        // this.spinner.show();
    }

    reportNewActivity() {

    }
}
