import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {QuestionsLayout, QuestionsMetaLayout} from '../interfaces';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {UserInfoService} from '../user-info.service';
import {Storage} from '@ionic/storage';

// Babytalk Client V2
// Component: questions resolver
// Purpose: pre-load the questions data before rendering the survey page

@Injectable({
    providedIn: 'root'
})
export class QuestionsResolverService implements Resolve<QuestionsMetaLayout> {

    public basesurl: string; // base server url
    public surveynumber: number;
    public surveyurl: string;
    public answerurl: string;
    public suffixurl: string;
    public metaurl: string;
    public baseaurl: string;
    public questionnumber: number;
    public recentquestion: number;
    public questions: QuestionsLayout[];
    public questionsmeta: QuestionsMetaLayout;
    public surveyname: string;
    public lastquestion: number;
    public sectiontitle: string;
    public subsectiontitle: string;
    selectedbaby: any;
    babyId: any;

    constructor(private apiService: ApiService,
                public client: HttpClient,
                private userInfoService: UserInfoService,
                private router: Router,
                private storage: Storage) {
    }


    resolve(route: ActivatedRouteSnapshot) {
        // this.storage.get('selectedbaby').then((val) => {
        //     this.selectedbaby = val;
        //     this.babyId = this.selectedbaby[0].id;
        //     console.log('selectedbaby', this.babyId);
        //   });
        console.log('starting resolver------------------------');

        // let myParam = route.data.resolvedata;
        // if (environment.verboseDebugMode === true) { console.log(myParam); }

        // if so:
        // get current survay number and question number if they eist
        //   this.spinner.show();

        // get data from local storage
        this.surveynumber = this.userInfoService.getCurrentSurvey();
        this.recentquestion = this.userInfoService.getCurrentQuestionNumber();
        this.lastquestion = this.userInfoService.getFinalQuestionNumber();

        console.log('s urvey number: '); // correct - selected by user
        console.log(this.surveynumber);
        console.log(' rec question:'); // incorrect
        console.log(this.recentquestion);
        console.log(' lastquestion:'); // incorrect
        console.log(this.lastquestion);
        // this.storage.get('CurrentSurveyNumber').then((val: any) => {
        // if (environment.verboseDebugMode === true) { console.log(val); }
        // console.log('resolver callback------------------------');

        // this.surveynumber = parseInt(val, 10);

        // console.log('in resolver, survey num=' + this.surveynumber);
        // console.log('in resolver, recentquestion=' + this.recentquestion);
        // console.log('current q num=' + this.userInfoService.getCurrentQuestionNumber());

        // fallback
        this.questionnumber = 1;

        // if current question is higher than the last  or storage copy is blank, fix it
        if ((this.recentquestion > this.lastquestion) || (this.userInfoService.getCurrentQuestionNumber() === null)) {
            this.questionnumber = 1;
            this.userInfoService.setCurrentQuestionNumber(this.questionnumber);
        } else {
            this.questionnumber = this.userInfoService.getCurrentQuestionNumber();
        }

        // if last answered question is the last question, roll back by 1


        console.log('specified q num=' + this.questionnumber);

        // if (environment.verboseDebugMode === true) { console.log(this.userInfoService.getCurrentQuestionNumber()); }
        // define URLs
        /* this.basesurl = 'https://' + environment.serverUrl + '/v1/';
         this.suffixurl = this.surveynumber + '?q=' + this.questionnumber;
         this.surveyurl = environment.serverUrl + '/v1/' + 'surveys/' + this.suffixurl;
         this.answerurl = environment.serverUrl + '/v1/' + 'answers/' + this.suffixurl;
         this.metaurl = environment.serverUrl + '/v1/' + 'qmetadata/' + this.suffixurl;
 */
        // define URLs

        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        if (environment.verboseDebugMode === true) {
            console.log(this.basesurl);
        }
        this.suffixurl = this.surveynumber + '?q=' + this.questionnumber;
        this.surveyurl = this.basesurl + 'surveys/' + this.suffixurl;
        this.answerurl = this.baseaurl + 'answers/' + this.suffixurl;
        this.metaurl = this.basesurl + 'qmetadata/' + this.suffixurl;

        if (environment.verboseDebugMode === true) {
            console.log('resolving');
        }
        console.log('getting data from target url=' + this.metaurl);
        console.log('ending resolver------------------------');

        // });

        return (this.apiService.getQuestionMetaDataFromServer(this.metaurl));
    }
}

