import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';
import {NewWordLayout} from '../interfaces';
import {Storage} from '@ionic/storage';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

// Babytalk Client V2
// Component: newword
// Purpose: log a new word learned by the child
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-newword',
    templateUrl: './newword.component.html',
    styleUrls: ['./newword.component.scss'],
})
export class NewwordComponent implements OnInit {
    // property type declarations
    wordBoost: boolean;
    public PageTitle = 'Report New Word';
    basesurl: string;
    public message: string;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    private babyname: string;
    private babyid: string;
    private newwordurl: string;
    selectedBabyData: any;
    UserData: any;
    date: any;
    slideOneForm: FormGroup;


    private newword: NewWordLayout = {};

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public storage: Storage,
                public formBuilder: FormBuilder,
                public apiService: ApiService) {

        if (environment.wordboost) {
            this.wordBoost = true;
        } else {
            this.wordBoost = false;
        }
        this.slideOneForm = formBuilder.group({
            date: ['']
        });
    }

    // Initialisation routine
    ngOnInit() {
    }


    ionViewWillEnter() {

        this.getUserInfo();

    }



    reportNewWord() {

        // console.log("Report New Word");
        const data: any = {
            babyId: this.selectedBabyData.id,
            word: this.newword.word,
            understands: this.newword.u === true ? 1 : 0,
            understandssays: this.newword.us == true ? 1 : 0,
            dateObserved: new DatePipe('en-US').transform(this.newword.date, 'y-MM-dd HH:mm:ss')
        };
        console.log(data.dateObserved);

        // no word?
        if (data.word == undefined) {
            this.apiService.showToast('Enter Word');
        } else {
            // word entered
            this.apiService.showLoader();
            this.apiService.AddNewWord(data).subscribe((res: any) => {
                this.apiService.hideLoader();
                if (res.replyStatus == 'success') {
                    this.apiService.showToast('New Word Added successfully');
                }
            }, err => {
                this.apiService.hideLoader();
                if (err.status == 401) {
                    this.apiService.tokenExpired();
                } else {
                    console.log('Error', err);
                }
            });
        }
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    showWordsReviewPage() {
        this.router.navigateByUrl('/viewwordlist');
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();

        // $window.location.reload(true);
    }

    setBabyData() {
        this.babyid = this.selectedBabyData.id;
        console.log('selected babyid locally found');
        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.newwordurl = this.basesurl + 'diary/recordword/' + this.userInfoService.getBabyID();

        if (environment.verboseDebugMode == true) {
            console.log(this.selectedBabyData);
        }
        this.babyid = this.selectedBabyData.id;
        this.babyname = this.selectedBabyData.baby_name;
        this.newword = {};


        // set the date to today --------------------------------------------
        const d = new Date();
        const year = d.getFullYear().toString();
        const month = d.getMonth() + 1;
        let smonth: string;
        let sday: string;

        // pad month
        if (month < 10) {
            smonth = '0' + month.toString();
        } else {
            smonth = month.toString();
        }

        const day = d.getDate();
        // pad day
        if (day < 10) {
            sday = '0' + day.toString();
        } else {
            sday = day.toString();
        }
        console.log(year + ' ' + smonth + ' ' + sday);
        // set date to today

        this.newword.date = (new Date(year + ' ' + smonth + ' ' + sday)).toISOString();
        // completeDate : (new Date(timestamp)).toISOString();

        if (environment.verboseDebugMode == true) {
            console.log(this.newword.date);
        }

        // -----------------------------------------------------------------
    }


    getUserInfo() {
        console.log('getting user info from storage...');

        this.storage.get('userInfo').then((val) => {
            // this.uid = val.UserID;
            this.UserData = val;
            console.log('USER INFO', this.UserData);
        }).catch(error => {
            // interpret error and maybe display something on the UI
            console.log('Error getting user info from storage');
            console.log(error);

        }).finally(() => {
            if (!this.UserData) {
                console.log('No user info');
                this.errormessage = 'Cannot find user data! Please Log out and back in again.';
            }
            console.log('Error getting user info from storage');
            console.log(Error);
        });

        this.storage.get('selectedbaby').then((val) => {
            this.selectedBabyData = val[0];
            console.log('selectedBaby', val[0]);
            this.setBabyData();
        });
    }


}
