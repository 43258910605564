import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';

interface register_data_layout {
    givenname?: string;
    surname?: string;
    username?: string;
    organisation?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
}

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    // property type declarations
    PageTitle = 'Register User';
    basesurl: string; // base server url
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    // Form data for the login modal
    registerData: register_data_layout;
    wordBoost: boolean;

// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService) {
        this.wordBoost = environment.wordboost;
    }

// app.controller('RegisterCtrl', function ($scope, $state, $ionicModal, $timeout, $ionicLoading, $http) {
    // // if (environment.verboseDebugMode === true) { console.log("RegisterCtrl'); }

// Initialisation routine
    ngOnInit() {

    }

    showButtonsPage() {
        this.router.navigateByUrl('/buttons');
    }

    showLoginPage() {
        // if (environment.verboseDebugMode === true) { console.log("login'); }
        this.router.navigateByUrl('/auth');
    }

    doRegister() {
        /*
       // console.log('Registering user', registerData.email);
         this.spinner.show();
    // $ionicloading.show({template: 'Loading...'});
         let data = registerData;
         stoken = '';
         let stoken='';
         // let dataobject = questions;
         TokenOperation.get().then(function(token){
         stoken = token.data;
         $http({
         headers: {
         'X-CSRF-TOKEN': stoken
         },
         method: 'POST',
         url: 'http:// localhost/auth/register',
         data: data
         })
         .then(function (headers) {
       // console.log('response  ', headers.status, headers.statusText);
         // resp = data;
         this.spinner.hide();
         // closeLogin();
         }, function (data) {
       // console.log('error login', data);
         // execute this code on error
         }
         )
         });
         */
    }

    refresh() {

    }

    register() {

    }
}
