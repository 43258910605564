import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';
import {Storage} from '@ionic/storage';
import {FormBuilder} from '@angular/forms';

@Component({
    selector: 'app-withdraw',
    templateUrl: './withdraw.component.html',
    styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent implements OnInit {

    public form = [
        {val: 'You can use my survey data.', isChecked: true},
        {val: 'Please delete all my survey data.', isChecked: false},

    ];


    constructor(public router: Router,
                private userInfoService: UserInfoService,
                public storage: Storage,
                public formBuilder: FormBuilder,
                public apiService: ApiService) {

        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.withdrawurl = this.basesurl + 'withdraw';

        if (environment.wordboost) {
            this.wordBoost = true;

        } else {
            this.wordBoost = false;
        }

    }

    wordBoost: boolean;
    PageTitle = 'Withdraw from study';
    NoChildAdded: boolean;
    NoFamilySurvey: boolean;
    commserror: boolean;
    commssuccess: boolean;
    errormessage = '';
    successmessage: string;
    // property type declarations
    basesurl: string;
    public message: string;
    private babyname: string;
    private babyid: string;
    private withdrawurl: string;
    selectedBabyData: any;
    UserData: any;
    date: any;


    ngOnInit() {
    }

    withdraw() {
        // console.log("Report New Word");
        console.log(this.form[0].isChecked);
        const data: any = {
            keep: !this.form[1].isChecked
            //  word: this.newword.word,
            //  understands: this.newword.u === true ? 1 : 0,
            //  understandssays: this.newword.us == true ? 1 : 0,
            //  dateObserved: new DatePipe('en-US').transform(this.newword.date, 'y-MM-dd HH:mm:ss')
        };

        this.apiService.postDataToServer(this.withdrawurl, data).subscribe((res: any) => {
            // this.apiService.hideLoader();
            if (res.replyStatus == 'success') {
                this.apiService.showToast('Withdraw Request Sent!');
            }
        }, err => {
            // this.apiService.hideLoader();
            if (err.status == 401) {
                this.apiService.tokenExpired();
            } else {
                console.log('Error', err);
            }
        });

    }

    showButtonsPage() {
        this.router.navigateByUrl('/buttons');
    }

}
