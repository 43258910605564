import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ProgressObjectLayout, QuestionsLayout, QuestionsMetaLayout, UserInfoLayout} from './interfaces';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {UserInfoService} from './user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UtilsService} from './utils.service';
import {LoadingController, ToastController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {AngularFireModule} from 'angularfire2';


@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private flipped = new BehaviorSubject('');
    getFlippedValue = this.flipped.asObservable();

    [x: string]: any;
    UserData: any;
    public menuButton = false;
    public imagePath = 'https://' + environment.serverUrl + '/v1/img/';
    imgloc = [];
    // registerUSER = "https://team4.devhostserver.com/babytalk/public/v1/register/user-profile"
    baseAPIurl = 'https://' + environment.serverUrl;
    registerUSER = this.baseAPIurl + '/v1/register/user-profile';
    babylisturl = this.baseAPIurl + 'mybabies/5';
    userInfourl = this.baseAPIurl + 'userdetails/5';
    addBabyURL = this.baseAPIurl + '/v1/save/new-baby';

    // changed by APR 01/11/2019
    // baseAPIurl = "https://team4.devhostserver.com/babytalk/public"
    deleteBabyURL = this.baseAPIurl + '/v1/baby';
    resigsterDeiceTokenURL = this.baseAPIurl + '/v1/update/device-token';
    // ---
    reviewWordsURL = this.baseAPIurl + '/v1/review/words';
    SelectedBabyChangeURL = this.baseAPIurl + '/v1/change/select-baby';
    AddNewWordURL = this.baseAPIurl + '/v1/save/new-words';
    DiaryListURL = this.baseAPIurl + '/v1/get/user-wise-diary-list';
    private imageLocations: any;
    private mybabies: any[];
    private userDetails: any[];
    private uidata: UserInfoLayout;
    public bannermessage: string;
    public header = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    };
    // For Map
    latitude = '';
    longitude = '';
    public auth = {
        headers:
            {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
    };
    // DiaryListURL = this.baseAPIurl +"/v1/get/user-wise-diary-list";

    mybabyList = this.baseAPIurl + '/v1/mybabies/0';
    getTagsURL = this.baseAPIurl + '/v1/get/user-wise-tags?userId=';
    diaryDataURL = this.baseAPIurl + '/v1/get/user-wise-diary-list';
    addDiaryURL = this.baseAPIurl + '/v1/save/new-diary';

    // baseAPIurl = "https://team4.devhostserver.com/babytalk/public"
    // baseAPIurl ="http://www.babytalk.website/btsrv/public"
    // registerUSER = this.baseAPIurl + "/v1/register/user-profile"
    // babylisturl =  this.baseAPIurl + 'mybabies/5';
    // userInfourl = this.baseAPIurl + 'userdetails/5';
    // addBabyURL =  this.baseAPIurl +'/v1/save/new-baby';
    // deleteBabyURL = this.baseAPIurl +'/v1/baby';
    // resigsterDeiceTokenURL =  this.baseAPIurl + "/v1/update/device-token";
    // reviewWordsURL = this.baseAPIurl + "/v1/review/words";
    // selectedBabyChangeURL = this.baseAPIurl + "/v1/change/select-baby";
    // AddNewWordURL = this.baseAPIurl + "/v1/save/new-words";

    surveylisturl = this.baseAPIurl + '/v1/mysurveys/';
    surveydataurl = this.baseAPIurl + '/v1/surveys/';
    urlString = this.baseAPIurl + '/v1/save/new-tag';
    loginUrl = this.baseAPIurl + '/api/authenticate';
    infourl = this.baseAPIurl + '/v1/infobites/';
    getRecWordsURL = this.baseAPIurl + '/v1/recommendation/';
    userProgressurl = this.baseAPIurl + '/v1/userprogressdetails/';
    graphprogressurl = this.baseAPIurl + '/v1/graphprogressbycat/';
    graphprogressvocaburl = this.baseAPIurl + '/v1/graphprogressvocabsize/';

    // TODO: check this, looks wrong
    uploadPic = environment.serverUrl + '/v1/baby/pic/';
    editBabyImage = environment.serverUrl + '/v1/edit/baby-image';

    // added by andy 25/02/22
    updateCode = this.baseAPIurl + '/v1/addusercode';
    MOTDurl = this.baseAPIurl + '/v1/bannermsg';
    EMurl = this.baseAPIurl + '/v1/emergencymsg';

    setFlipValue(value: any) {
        this.flipped.next(value);
    }

    // constructor
    constructor(private http: HttpClient,
                public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public utils: UtilsService,
                protected loading: LoadingController,
                public toastController: ToastController,
                public storage: Storage,
                public angularFireModule: AngularFireModule,
                // public storage: Storage,
    ) {
        this.wordBoost = environment.wordboost;

    }

    graphprogressbycat(selectedbabyId) {
        console.log('APIid', selectedbabyId);
        const graphprogressbycat = this.graphprogressurl + selectedbabyId;
        return this.http.get(graphprogressbycat, this.header);
    }

    graphprogressvocabsize(selectedbabyId) {
        const graphprogressvocab = this.graphprogressvocaburl + selectedbabyId;
        return this.http.get(graphprogressvocab, this.header);
    }

    getPersonaliseImg(Id) {
        console.log('ID:', Id);
        const url = 'https://' + environment.serverUrl + '/v1/baby/select-pic/' + Id;
        return this.http.get(url, this.header);
    }

    getBabyList() {
        console.log('Header', this.header);
        //  return this.http.get('http://www.babytalk.website/btsrv/public/v1/mybabies/0', this.header);
        return this.http.get('https://' + environment.serverUrl + '/v1/mybabies/0', this.header);
        // .subscribe(
        //     (result: any[]) => {
        //         this.spinner.hide();
        //         this.mybabies = result;
        //         for (const id of this.mybabies) {
        //             // tslint requires this test:
        //             if (this.mybabies.hasOwnProperty(id)) {
        //                 this.imgloc.push('https://' + environment.serverUrl + '/v1/img/' + this.mybabies[id].id);
        //                 // check if this is the currently selected baby
        //                 if ((this.mybabies[id].selected) == 1) {
        //                     // if so populate local data storage
        //                     this.userInfoService.setBabyID(this.mybabies[id].id);
        //                     this.userInfoService.setBabyName(this.mybabies[id].baby_name);
        //                     this.userInfoService.setBabyDOB(this.mybabies[id].dob);
        //                     this.userInfoService.setBabyRecordID(this.mybabies[id].id);
        //                 }
        //             }
        //         } // end for loop
        //         this.imageLocations = this.imgloc;
        //         // Preload the images
        //         if (environment.verboseDebugMode === true) { console.log('preloading'); }

        //         this.utils.image_preload(this.imageLocations);
        //         this.spinner.hide();
        //     }, error => {
        //         this.spinner.hide();
        //         console.log('http error');
        //         if (environment.verboseDebugMode === true) { console.log(error); }
        //         // this.errors = error;
        //     }
        // );
    }

    getMOTDFromServer() {
        // ---- get message of the day : banner message for buttons screen
        // make API call to /bannermsg
        const motd = this.MOTDurl;
        // console.log('MOTD ', motd);

        // console.log('getting user data...');

        this.spinner.show();

        this.getDataObjectFromServer(motd)
            .subscribe(
                (result: string) => {
                    // console.log('MOTD data rx');
                    this.spinner.hide();
                    // this.uidata = result;
                    console.log('MOTD --> ', result);
                    this.bannermessage = result;
                    this.userInfoService.setMOTD(this.bannermessage);

                }, error => {
                    this.spinner.hide();

                    console.log('motd http error');

                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                }
            );

    }

    getEmergencyMessage() {
        // make API call to /emengencymsg

        return '';
    }


    getMyUserData(userURL: string) {
        console.log('USER INFO DATA', userURL);
        // -------------- update local user info ---------------
        // populate the userinfoservice from the server if it's empty
        console.log('getting user data...');
        this.spinner.show();
        this.getDataObjectFromServer(userURL)
            .subscribe(
                (result: UserInfoLayout) => {
                    console.log('user data rx');
                    this.spinner.hide();
                    this.uidata = result;
                    console.log('UserData', result);

                    // this.userDetails = this.uidata;
                    if (environment.verboseDebugMode == true) {
                        console.log(this.uidata);
                    }
                    if (this.uidata.hasOwnProperty('UserID')) {
                        this.storage.set('userData', this.uidata);
                        console.log('loading data into browser storage');
                        if (environment.verboseDebugMode == true) {
                            console.log(this.uidata);
                        }
                        this.userInfoService.setUserID(this.uidata.UserID);
                        this.userInfoService.setFirstName(this.uidata.FirstName);
                        this.userInfoService.setSurname(this.uidata.Surname);
                        this.userInfoService.setCurrentSurvey(this.uidata.CurrentSurvey);
                        this.userInfoService.setCurrentQuestionNumber(this.uidata.CurrentQuestion);
                        this.userInfoService.setBabyID(this.uidata.BabyID);
                        this.userInfoService.setBabyName(this.uidata.BabyName);
                        this.userInfoService.setBabyDOB(this.uidata.BabyDOB);
                        this.userInfoService.setOrgName(this.uidata.OrgName);
                    }
                }, error => {
                    this.spinner.hide();
                    console.log('http error');
                    if (environment.verboseDebugMode == true) {
                        console.log(error);
                    }
                }
            );
    }

    deleteObjectFromServer(URLstring) {
        const headersObject = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.delete<any>(URLstring,
            {headers: headersObject})
            .pipe(
                catchError(this.handleError('deleteObject'))
            );
    }

    editBabyImageAPI(data): Observable<any> {
        return this.http.post(this.editBabyImage, data, this.header);
    }

    getRecommendedWordsFromServer(babyId): Observable<any> {
        console.log('Header', this.header);
        const recWordsURL = this.getRecWordsURL + babyId;
        return this.http.get(recWordsURL, this.header);
    }

    getDataFromServer(infoUrl): Observable<any> {
        console.log(infoUrl);
        const infourl = this.infourl + infoUrl;
        // if (environment.verboseDebugMode === true) { console.log("ApiPageinfourl",infourl); }
        return this.http.get(infourl, this.header);
    }

    // getRecommendedWordsFromServer(URLstring): Observable<RecommendedWordsLayout> {
    //     console.log('Getting recommended words...');
    //       return (this.http.get<RecommendedWordsLayout>(URLstring));
    // }

    getDataObjectFromServer(URLstring): Observable<any> {
        // if (environment.verboseDebugMode === true) { console.log('GETTing...'); }
        // return (this.http.get<any>(URLstring));
        console.log(URLstring);
        return this.http.get(URLstring, this.header);
    }

    getTags(userId): Observable<any> {
        const getTagsURL = this.getTagsURL + userId;
        console.log('getTagsURL', getTagsURL);
        return this.http.get(getTagsURL, this.auth);
    }

    addTag(data): Observable<any> {
        return this.http.post(this.urlString, data, this.header);
    }

    AddNewWord(data): Observable<any> {
        return this.http.post(this.AddNewWordURL, data, this.header);
    }

    addCode(data): Observable<any> {
        return this.http.post(this.updateCode, data, this.header);
    }

    diaryList(data): Observable<any> {
        console.log('IN API', data);
        return this.http.post(this.DiaryListURL, data, this.auth);
    }

    registerDeviceToken(data): Observable<any> {
        // console.log("POsting to serverdata",data)
        return this.http.post(this.resigsterDeiceTokenURL, data, this.header);
    }

    selectedBabyChange(data): Observable<any> {
        if (environment.verboseDebugMode == true) {
            console.log('TestingApi');
        }
        return this.http.post(this.SelectedBabyChangeURL, data, this.auth);
    }

    // SelectedBaby(data): Observable<any> {
    //     return this.http.post(this.SelectedBabyChangeURL, data, this.header);
    // }

    addDiaryActity(data): Observable<any> {
        console.log('DiaryData', data);
        return this.http.post(this.addDiaryURL, data, this.header);
    }

    getDiaryData(): Observable<any> {
        return this.http.get(this.diaryDataURL, this.header);
    }

    deleteBaby(babyId): Observable<any> {
        return this.http.delete(this.baseAPIurl + '/v1/baby/' + babyId, this.header);
    }

    getProgressDataFromServer(Id): Observable<ProgressObjectLayout> {
        console.log(' getProgressDataFromServer GETTing...');
        const url = this.userProgressurl + Id;
        console.log('API', url);
        return this.http.get(url, this.header);
        // return (this.http.get<ProgressObjectLayout>(URLstring),this.header);
    }

    getUserInfoDataFromServer(URLstring): Observable<UserInfoLayout> {
        console.log(' getUserInfoDataFromServer GETTing...');
        return (this.http.get<UserInfoLayout>(URLstring));
    }


    getQuestionMetaDataFromServer(URLstring): Observable<QuestionsMetaLayout> {
        console.log(' getQuestionMetaDataFromServer GETTing...' + URLstring);
        return (this.http.get<QuestionsMetaLayout>(URLstring));
    }

    getSurveyDataFromServer(URLstring): Observable<any> {
        console.log('getSurveyDataFromServer APIBabyID', URLstring);
        // var survey = this.surveydataurl + surveyid;
        if (environment.verboseDebugMode == true) {
            console.log(URLstring);
        }
        // return this.http.get(survey, this.header);
        return (this.http.get<QuestionsLayout>(URLstring, this.header));
    }

    getSurveyListFromServer(APIBabyID): Observable<any> {
        console.log('getSurveyListFromServer APIBabyID', APIBabyID);
        const survey = this.surveylisturl + APIBabyID;
        // if (environment.verboseDebugMode === true) { console.log(URLstring); }
        return this.http.get(survey, this.header);
        // return (this.http.get<QuestionsLayout>(URLstring));
    }

    putDataToServer(URLstring, dataObject?): Observable<any> {
        if (environment.verboseDebugMode == true) {
            console.log('PUTting...');
        }
        const headersObject = new HttpHeaders()
            .set('Content-Type', 'application/json');
        // const urlSearchParams = new URLSearchParams();


        return this.http.put<any>(URLstring, dataObject,
            {headers: headersObject})
            .pipe(
                catchError(this.handleError('putdata'))
            );
    }

    async successData() {
        const toast = await this.toastController.create({
            message: 'Data Sent!',
            duration: 2000,
            color: 'success',
        });
        toast.present();
    }

    async successFinalData() {
        const toast = await this.toastController.create({
            message: 'Survey Finalised.',
            duration: 2000,
            color: 'success',
        });
        toast.present();
    }

    async errorDataMsg() {
        const toast = await this.toastController.create({
            message: 'Error',
            duration: 2000,
            color: 'danger',
        });
        toast.present();
    }

    postDataToServer(URLstring, dataObject): Observable<any> {
        if (environment.verboseDebugMode == true) {
            console.log('POSTing...');
        }
        if (environment.verboseDebugMode == true) {
            console.log(URLstring);
        }
        if (environment.verboseDebugMode == true) {
            console.log(dataObject);
        }
        const headersObject = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + localStorage.getItem('token'));
        const urlSearchParams = new URLSearchParams();
        return this.http.post<any>(URLstring, dataObject,
            {headers: headersObject})
            .pipe(
                catchError(this.handleError('postdata'))
            );
    }

    registerUser(data): Observable<any> {
        console.log('POsting to serverdata', data);
        return this.http.post(this.registerUSER, data);
    }

    reviewWords(data) {
        return this.http.post(this.reviewWordsURL, data, this.header);
    }

    public forgotPassword(URLstring, email) {
        return this.http.post(URLstring, email);
    }

    public addNewBaby(data, header) {
        return this.http.post(this.addBabyURL, data, header);
    }

    public downloadData() {
//        return this.http.get('http://babytalk.website/btsrv/public/v1/download', this.header);
        return this.http.get('https://' + environment.serverUrl + '/v1/download', this.header);

    }

    userImageUpload(fileParams) {
        const babyId = fileParams.babyId;
        return this.http.post(this.uploadPic + babyId, fileParams, this.header);
    }

    async showToast(message) {
        const toast = await this.toastController.create({
            message,
            duration: 2000
        });
        toast.present();
    }

    async showNotification() {
        const toast = await this.toastController.create({
            message: 'Why have we recommended these words?These words have been determined by'
                + 'our experimental model to be the best words to learn in order to boost language acquisition',
            duration: 12000,
            position: 'bottom',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        });
        toast.present();
    }

    async showLoader() {
        const loader = await this.loading.create({
            message: 'Please wait',
            spinner: 'bubbles',
            duration: 5000,
            mode: 'md'
        });
        loader.present();
    }

    /**
     * Hide loader afte completing request on any where in application
     */
    async hideLoader() {
        /*const loader = await this.loading;
        loader.dismiss();*/
        this.loading.getTop().then((loadingPresent) => {
            if (loadingPresent) {
                // console.warn('yes loader is present');
                this.loading.dismiss(true);
            }
        });
    }

    login(email: string, password: string) {
        return this.http.post(this.loginUrl, {email, password});
    }

    tokenExpired() {
        this.showToast('You need to log in');
        this.storage.clear();
        localStorage.removeItem('satellizer_token');
        localStorage.removeItem('token');
        this.userinfoservice.clearAllLocalStorage();
        this.storage.set('IsTravel', true);
        this.router.navigateByUrl('/login');
    }

    access401() {
        this.showToast('Unauthorized User, please relogin with proper credentials.');
    }


    private handleError(operation: string) {
        console.log('error handling');
        let resource = '';
        if ((operation.indexOf('module') !== -1)) {
            resource = 'Module';
        }

        return (err: any) => {
            const errMsg = `error in ${operation}() `;
            console.log(`${errMsg}:`, err);
            if (err instanceof HttpErrorResponse) {

                // This error handling isn't the most elegant. Sorry about that.

                console.log(`status: ${err.status}, ${err.statusText}`);
                /* if (err.error) {
                   if (environment.verboseDebugMode == true) { console.log(err.error); }

                   if (err.error == 'no_tutor_record') {
                     errMsg = 'Your Tutor Record for this Semester cannot be found.';
                     if (environment.verboseDebugMode == true) { console.log(errMsg); }

                   }
                 } */
                // foreign key violation
                /*if (err.error.message) {
                    if (err.error.message == 'no_tutor_record') {
                        errMsg = 'Your Tutor Record for this Semester cannot be found.';
                    }
                    if (err.error.message.substring(0, 69) == 'SQLSTATE[23000]: Integrity constraint violation: 1062 Duplicate entry') {
                        errMsg = resource + ' Already exists!';
                    } else if (err.error.message.substring(0, 53) == 'SQLSTATE[23000]: Integrity constraint violation: 1048') {
                        errMsg = ' Field cannot be empty!';
                    } else {
                        errMsg = err.statusText + '\n' + err.error.message;
                    }
                } else {
                    errMsg = err.error.text;
                }*/
            }
            return throwError(errMsg);
        };
    }

    // public forgotPassword(URLstring, email) {
    //     return this.http.post(URLstring, email);
    // }

    // public addNewBaby(data){
    //     return this.http.post(this.addBabyURL,data,this.header)
    // }

    // userImageUpload(fileParams){
    //     // https://php4.shaligraminfotech.com/babytalk/public/v1/baby/pic/2
    //     return this.http.post('https://' + environment.serverUrl +  '/baby/pic/2',fileParams,this.header);
    // }


    // async showToast(message) {
    //     const toast = await this.toastController.create({
    //         message: message,
    //         duration: 2000
    //     });
    //     toast.present();
    // }

    // async showLoader() {
    //     const loader = await this.loading.create({
    //         message: 'Please wait',
    //         spinner: 'bubbles',
    //         mode: 'md'
    //     });
    //     loader.present();
    // }

    /**
     * Hide loader afte completing request on any where in application
     */
    // async hideLoader() {
    //     /*const loader = await this.loading;
    //     loader.dismiss();*/
    //     this.loading.getTop().then((loadingPresent) => {
    //         if (loadingPresent) {
    //             // console.warn('yes loader is present');
    //             this.loading.dismiss(true);
    //         }
    //     });
    // }


}
