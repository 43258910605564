import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {SelectSurveyService} from '../select-survey.service';
import {ProgressObjectLayout} from '../interfaces';
import {Storage} from '@ionic/storage';
import {environment} from '../../environments/environment';
// Babytalk Client V2
// Component: selectsurv
// Purpose: user selects survey to take
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-selectsurv',
    templateUrl: './selectsurv.component.html',
    styleUrls: ['./selectsurv.component.scss'],
})

export class SelectsurvComponent implements OnInit {

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                public storage: Storage,
                private selectSurveyService: SelectSurveyService) {
        this.wordBoost = environment.wordboost;

    }
    // property (field) type declarations
    wordBoost: boolean;
    PageTitle = 'Select Survey';
    NoChildAdded: boolean;
    NoFamilySurvey: boolean;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    progressData: ProgressObjectLayout;
    selectedBabyData: any;
    UserData: any;
    surveyId: any;
    buttonColor: string;
    clickedbuttonColor: string;

    private babyselected: string;
    private babyname: string;
    private basesurl: string;
    private surveylisturl: string;
    private mysurveys: any;
    private userProgressurl: string;
    private surveyselect: any;
    private newbabyid: string;
    private mybabyname: string;


    Colors: Array<any> = ['#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1',
        '#ecf0f1'];

    // Initialisation routine
    ngOnInit() {
        this.buttonColor = '#ecf0f1';
        this.clickedbuttonColor = 'rgb(170,170,170)';
    }

    ionViewWillEnter() {
        this.getUserInfo();
        for (let index = 0; index < 20; index++) {
            this.Colors[index] = '#ecf0f1';
        }

    }

    setBabyData() {
        console.log('in setbabydata');
        this.babyselected = this.selectedBabyData.id;
        console.log('selectedBaby', this.babyselected);
        this.babyname = this.selectedBabyData.baby_name;
        // this.basesurl = 'https://' + environment.serverUrl + '/v1/';

        this.apiService.showLoader();
        // -------------- get available surveys list ---------------
        this.apiService.getSurveyListFromServer(this.babyselected)
            .subscribe(
                (res: any) => {
                    this.apiService.hideLoader();
                    this.mysurveys = res;
                    console.log('Myres', this.mysurveys);

                    // ------- disabling this bit -----------
                    // sort array into date order
                    /* const arr1 = this.mysurveys.map((obj) => {
                         return { ...obj, sdate: new Date(obj.started_at) };
                     });
                     const sortedAsc = [...arr1].sort(
                         (objA, objB) => objA.sdate.getTime() - objB.sdate.getTime(),
                     );*/
                    // ------------------------

                    // ---- sort by id instead ---
                    const arr1 = this.mysurveys.map((obj) => {
                        return {...obj, id: obj.id};
                    });
                    const sortedAsc = [...arr1].sort(
                        (objA, objB) => objA.id - objB.id,
                    );

                    // -------------------------


                    console.log('arr1', arr1);
                    console.log('sortedAsc', sortedAsc);
                    this.mysurveys = sortedAsc;

                }, error => {
                    this.apiService.hideLoader();
                    if ( error.status == 401 ) {
                        this.apiService.tokenExpired();
                    } else if ( error.status == 500 ) {
                        this.apiService.showToast('Server configuration error 500');
                    } else if ( error.status == 0 ) {
                        this.apiService.showToast('Cannot contact server!');
                    } else {
                        this.apiService.showToast('Error ' + error.status);
                        console.log('Error', error);


                    }

                    // if (environment.verboseDebugMode === true) {
                    //     console.log(error);
                    // }
                }
            );
    }

    getButtonColor(index) {
        const num = this.getnumber(index);
        return this.Colors[num];
    }

    getDisabledButtonColor(index) {
        const num = this.getnumber(index);
        return this.Colors[num];
    }

    getnumber(data) {
        let i = data;
        if (i > this.Colors.length - 1) {
            i = i - this.Colors.length;
            if (i < this.Colors.length) {
                return i;
            } else {
                this.getnumber(i);
            }
        } else {
            return i;
        }
    }

    showDatePretty(datetime) {
        if (datetime == undefined) {
            datetime = '';
        }
        // Split timestamp into [ Y, M, D, h, m, s ]
        const t = datetime.split(/[- :]/);
        // Apply each element to the Date function
        // let d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2]));
        return (d);
    }

    // get status of survey - is it available yet?
    surveyStatus(datetime): boolean {
        if (datetime == undefined) {
            datetime = '';
        }
        const now = new Date(); // Todays Date
        // Split timestamp into [ Y, M, D, h, m, s ]
        const t = datetime.split(/[- :]/);

        // Apply each element to the Date function
        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        let availabledate = new Date();
        availabledate = d;


        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        // Discard the time and time-zone information.
        const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
        const utc2 = Date.UTC(availabledate.getFullYear(), availabledate.getMonth(), availabledate.getDate());
        const daysleft = Math.floor((utc2 - utc1) / _MS_PER_DAY);
        // let message = 'To be completed in ' + daysleft + ' days.';
        console.log(daysleft);
        return (daysleft <= 0);

    }

    // get status of wordboost survey - completed or not?
    surveyStatusWordBoost(surv): string {


        console.log(surv);
        // return fresh, inprogress, complete
        return ('fresh');

    }


    showDaysLeft(datetime): string {
        if (datetime == undefined) {
            datetime = '';
        }
        const now = new Date(); // Todays Date

        // Split timestamp into [ Y, M, D, h, m, s ]
        const t = datetime.split(/[- :]/);

        // Apply each element to the Date function
        const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

        let expire = new Date();
        expire = d;
        const hour1 = expire.getHours();
        expire.setTime(expire.getTime() + (7 * 86400000));
        const hour2 = expire.getHours();

        if (hour1 != hour2) {
            d.setTime(d.getTime() + (hour1 - hour2) * 3600000);
        }

        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        // Discard the time and time-zone information.
        const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
        const utc2 = Date.UTC(expire.getFullYear(), expire.getMonth(), expire.getDate());
        const daysleft = Math.floor((utc2 - utc1) / _MS_PER_DAY);
        let message = 'To be completed in ' + daysleft + ' days.';
        if (daysleft < 0) {
            message = 'Survey is overdue.';
        }
        return (message);

    }

    // this is run when the user selects a survey - starts the oricess of loading a new survey
    showQuestions(surveyId, i) {
        // show spinner
        this.apiService.showLoader();

        this.Colors[i] = this.clickedbuttonColor;

        console.log('selectsurvcomponent / showQuestions');
        console.log('ID', surveyId);
        this.surveyId = '';
        this.surveyId = surveyId;

        // survey has been selected so we now need to get the last known position in this survey (from the server)

        this.surveyselect = this.surveyId;
        this.selectSurveyService.addSurveySelection(this.surveyId);

        // clear storage  of old data


        // populate the userinfoservice from the server if it's empty
        // this.newbabyid = this.userInfoService.getBabyID();
        // this.mybabyname = this.userInfoService.getBabyName();
        // this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        // this.userProgressurl = this.basesurl + 'userprogressdetails/' + surveynum;

        // this.spinner.show();

        // ajax call to server: also tells server which survey instance we've selected
        this.apiService.getProgressDataFromServer(this.surveyId)
            .subscribe(
                (progressData: ProgressObjectLayout) => {
                    console.log('getProgressDataFromServer response: progressData:', progressData);

                    this.apiService.hideLoader();
                    // this.userInfoService.setUserID(cdata.UserID);
                    // this.userInfoService.setFirstName(cdata.FirstName);
                    // this.userInfoService.setSurname(cdata.Surname);
                    // todo

                    // we get (last completed + 1) as q num from server
                    // this could be wrong - could be the last question + 1 if survey hasn't been finalised
                    // so we need to ensure that if it is last question + 1, we roll back one to last question
                    if (progressData.CurrentQuestion > progressData.FinalQ) {
                        this.userInfoService.setCurrentQuestionNumber(progressData.FinalQ);
                    } else {
                        this.userInfoService.setCurrentQuestionNumber(progressData.CurrentQuestion); // number
                    }

                    this.userInfoService.setCurrentSurvey(progressData.CurrentSurvey); // string
                    // get the question the server thinks we should be on


                    // this.userInfoService.setBabyID(cdata.BabyID);
                    // this.userInfoService.setBabyName(cdata.BabyName);
                    // this.userInfoService.setBabyDOB(cdata.BabyDOB);
                    // this.userInfoService.setOrgName(cdata.OrgName);
                    // mycompletedsurveys = cdata[0];
                    // console.log('user progress data:');
                    // console.log(cdata.CurrentSurvey, cdata.CurrentQuestion);
                    // if (environment.verboseDebugMode === true) { console.log(userInfoService.getCurrentQuestionNumber()); }
                    // if (environment.verboseDebugMode === true) { console.log(cdata); }
                    console.log('navigating to Questions...');
                    this.router.navigateByUrl('/questions', {});

                }, error => {


                    //         this.spinner.hide();
                    this.apiService.hideLoader();
                    if (error.status == 401) {
                        this.apiService.tokenExpired();
                    } else if (error.status == 500) {
                        this.apiService.showToast('Server configuration error 500');
                    } else if (error.status == 0) {
                        this.apiService.showToast('Cannot contact server!');
                        this.errormessage = 'Unable to contact server!';
                    } else {
                        this.apiService.showToast('Error ' + error.status);
                        console.log('Error', error);


                    }

                    // if (environment.verboseDebugMode === true) {
                    //     console.log(error);
                    // }
                });
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();
        // $window.location.reload(true);
    }

    showButtonsPage() {
        this.router.navigateByUrl('/buttons');
    }

    getUserInfo() {
        console.log('getting user info from storage...');
        this.storage.get('userInfo').then((val) => {
            // this.uid = val.UserID;
            this.UserData = val;
            console.log('USER INFO', this.UserData);
        }).catch(error => {
            // interpret error and maybe display something on the UI
            console.log('Error getting user info from storage');
            console.log(error);

        }).finally(() => {
            if (!this.UserData) {
                console.log('No user info');
                this.errormessage = 'Cannot find user data! Please Log out and back in again.';
            }
            console.log('Error getting user info from storage');
            console.log(Error);
        });

        this.storage.get('selectedbaby').then((val) => {
            this.selectedBabyData = val[0];
            console.log('selectedBaby', val[0]);
            this.setBabyData();
        });
    }
}
