import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {environment} from '../../environments/environment';
import {UtilsService} from '../utils.service';

import {Storage} from '@ionic/storage';
import {ModalController} from '@ionic/angular';
import {EvidenceInfoModalComponent} from '../evidence-info-modal/evidence-info-modal.component';

import {InfoBites} from '../interfaces';

// Babytalk Client V2
// Component: info
// Purpose: display infobites
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
})

export class InfoComponent implements OnInit {
    // property type declarations
    PageTitle = 'Infobites';
    basesurl: string; // base server url
    babyid: string;
    babyname: string;
    babydob: string;
    infourl: string;
    userfirstname: string;
    usersurname: string;
    userid: string;
    wordBoost: boolean;

    infobites: InfoBites;
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    UserData: any;
    selectedBabyData: any;
    games: any[];
    public gamesDataAvailable: boolean = false;
    public knowDataAvailable: boolean = false;
    public helpDataAvailable: boolean = false;
    public noDataAvailable: boolean = true;
    private data: any;
    private babylisturl: string;
    private mybabies: any;
    private imgloc: any;
    private imageLocations: any;

// constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                public storage: Storage,
                public utils: UtilsService,
                public modalController: ModalController) {

        this.basesurl = 'https://' + environment.serverUrl + '/v1/';
        this.infourl = this.basesurl + 'infobites/';

        if (environment.wordboost) {
            this.wordBoost = true;
        } else {
            this.wordBoost = false;
        }

    }

// Initialisation routine
    ngOnInit() {
    }


    ionViewWillEnter() {
        console.log('info PAge');
        this.getUserInfo();

    }


    downloadInfoBitesData() {
        // set user and baby data
        this.babyid = this.selectedBabyData.id;
        this.babyname = this.selectedBabyData.baby_name;
        this.babydob = this.selectedBabyData.dob;

        this.userfirstname = this.UserData.givenname;
        this.usersurname = this.UserData.username;
        this.userid = this.UserData.id;

        this.spinner.show();

        // -------------- get infobites data  ---------------
        this.apiService.getDataFromServer(this.babyid)
            .subscribe(
                (data: InfoBites) => {
                    // console.log('sucessfully got infobites data...');
                    if (data.games.length) {
                        this.gamesDataAvailable = true;
                        this.noDataAvailable = false;
                        this.infobites = data;

                    } else {
                        this.gamesDataAvailable = false;
                    }
                    if (data.know.length) {
                        this.knowDataAvailable = true;
                        this.infobites = data;
                        this.noDataAvailable = false;
                    } else {
                        this.knowDataAvailable = false;
                    }

                    if (data.help.length) {
                        this.helpDataAvailable = true;
                        this.infobites = data;
                        this.noDataAvailable = false;
                    } else {
                        this.helpDataAvailable = false;
                    }
                    this.spinner.hide();
                }, error => {
                    this.gamesDataAvailable = false;
                    this.helpDataAvailable = false;
                    this.knowDataAvailable = false;
                    this.spinner.hide();
                    console.log('infobites download error...');
                    if (environment.verboseDebugMode === true) {
                        console.log(error);
                    }
                    if (error.status == 401) {
                        this.apiService.access401();
                    }
                    // this.errors = error;
                });

    }

    showAge(bday) {
        if (bday == undefined) {
            bday = '';
        }
        let now = new Date(); // todays Date
        let birthday = (bday.split('-'));
        let dobMonth = birthday[1];
        let dobDay = birthday[2];
        let dobYear = birthday[0];
        let nowDay = now.getDate();
        let nowMonth = now.getMonth() + 1;  // jan=0 so month+1
        let nowYear = now.getFullYear();
        let ageyear = nowYear - dobYear;
        let agemonth = nowMonth - dobMonth;
        let ageday = nowDay - dobDay;
        if (agemonth < 0) {
            ageyear--;
            agemonth = (12 + agemonth);
        }
        if (nowDay < dobDay) {
            agemonth--;
            ageday = 30 + ageday;
        }
        let val = (ageyear * 12) + agemonth + ' months';
        return val;
    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();
        // $window.location.reload(true);
    }


    // getUserInfo() {
    //     this.storage.get('userInfo').then((val) => {
    //         //this.uid = val.UserID;
    //         this.UserData = val
    //         console.log("USER INFO", this.UserData);
    //     });

    //     this.storage.get('selectedbaby').then((val) => {
    //         this.selectedBabyData = val[0];
    //         console.log("selectedBaby", val[0])
    //         this.setBabyData();
    //     });
    // }


    getUserInfo() {
        if (environment.verboseDebugMode === true) {
            console.log('getUserInfo');
        }
        this.storage.get('userInfo').then((val) => {
            // this.uid = val.UserID;
            this.UserData = val;
            console.log('USER INFO from native storage', this.UserData);
        });

        this.storage.get('selectedbaby').then((val) => {
            this.selectedBabyData = val[0];
            console.log('selectedBaby from native storage', val[0]);
            this.downloadInfoBitesData();
        });
    }


    async OpenModal(data) {
        const modal = await this.modalController.create({
            component: EvidenceInfoModalComponent,
            componentProps: {
                evidence: data.evidence,
                advice: data.advice
            }
        });

        modal.onDidDismiss()
            .then((data: any) => {
                console.log('data', data);
            });
        this.getUserInfo;
        return await modal.present();


    }

}
