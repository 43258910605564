import { AfterContentInit, AfterViewInit, Component, ContentChildren, Input, OnChanges, QueryList } from '@angular/core';
import { FlashCardComponent } from '../flash-card/flash-card.component';


@Component({
    selector: '.app-flash-card-grid',
    templateUrl: './flash-card-grid.component.html',
    styleUrls: ['./flash-card-grid.component.scss'],
})
export class FlashCardGridComponent implements AfterViewInit, OnChanges, AfterContentInit {
    [x: string]: any;

    @ContentChildren(FlashCardComponent)
    groups: QueryList<FlashCardComponent>;

    @Input()
    public set resetAll(categoryId: boolean) {
        console.log(categoryId);
    }

    constructor() {

    }

    ngAfterViewInit() {
        console.log('Inside FlashCardGridComponent:ngAfterViewInit');
        // this.groups.toArray()[0].flipped = true;
        this.groups.toArray().forEach((t) => {
            t.flip.subscribe(() => {
                this.openGroup(t);
            });
        });
    }

    ngOnChanges() {
        console.log('FlashCardGridComponent ngOnChanges');
        // console.log(this.resetall);
    }

    ngAfterContentInit() {
        console.log('FlashCardGridComponent ngAfterContentInit - wrapper');
        console.log('FlashCardGridComponent ngAfterContentInit - content');
    }

    openGroup(flashCard) {
        if (flashCard.flipped == true) {
            flashCard.flipped = false;
            console.log('False');
        } else {
            flashCard.flipped = true;
            console.log('true');
        }
    }

    reset(flashCard) {
        console.log('FlashCardGridComponent Inside reset');
        console.log(flashCard);
        this.groups.toArray().forEach((t) => t.flipped = false);
    }
}

