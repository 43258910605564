import {Injectable} from '@angular/core';


// Babytalk Client V2
// Component: utils service
// Purpose: Utilities service
// Porting Status: Ready

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    imgs = Array();

// constructor
    constructor() {
    }

    // preload images
    image_preload(...imgs: any[]): void {
        for (let i = 0; i < imgs.length; i++) {
            this.imgs[i] = new Image();
            this.imgs[i].src = imgs[i];
            console.log('preloaded : ' + imgs[i]);
        }

    }


    showDatePretty(datetime) {
        // show date in nicely formatted fashion

        if (datetime == undefined) {
            datetime = '';
        }
        // Split timestamp into [ Y, M, D, h, m, s ]
        let t = datetime.split(/[- :]/);
        // Apply each element to the Date function
        // let d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        let d = new Date(Date.UTC(t[0], t[1] - 1, t[2]));
        return (d);
    }


    cloneObject(obj) {
        // clone a javascript object
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        const temp = obj.constructor(); // give temp the original obj's constructor

        for (const key of obj) {

            temp[key] = this.cloneObject(obj[key]);
        }

        return temp;
    }

}