import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AlertController, LoadingController, MenuController, ModalController, NavController, Platform,} from '@ionic/angular';
import {AuthenticationService} from '../authentication.service';
import {RegisterationComponent} from '../registeration/registeration.component';
import {FCM} from '@ionic-native/fcm/ngx';
import {Storage} from '@ionic/storage';
import {NewbabymodalPage} from '../mybaby/newbabymodal/newbabymodal.page';
import {environment} from '../../environments/environment';

// Babytalk Client V2
// Component: login
// Purpose: login page
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

interface AuthLayout {
    email?: string;
    password?: string;
    organisation?: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    // property type declarations
    PageTitle = 'Login';
    basesurl: string; // base server url
    public commserror: boolean;
    commssuccess: boolean;
    public errormessage: string;
    successmessage: string;
    loginForm: FormGroup;
    username: FormControl;
    password: FormControl;
    auth: AuthLayout = {};
    loading = false;
    error = '';
    passwordforgotten = false;
    sentlink = false;
    message: string;
    senttoemailaddress = '';
    returnUrl = '';
    forgotpassword;
    forgotPassResponse: any;
    tokenId: any;
    // baseurl = 'http://' + babytalkserver;

    // Form data for the login modal
    registerData = {};

    wordBoost: boolean;

    // constructor
    constructor(
        public router: Router,
        private userInfoService: UserInfoService,
        private spinner: NgxSpinnerService,
        public apiService: ApiService,
        private nav: NavController,
        private loadingCtrl: LoadingController,
        public navCntl: NavController,
        private authService: AuthenticationService,
        public menu: MenuController,
        public alertController: AlertController,
        public modalController: ModalController,
        public fcm: FCM,
        public storage: Storage,
        public platform: Platform,
        public modalCtnl: ModalController,
        private fb: FormBuilder
    ) {
        this.wordBoost = environment.wordboost;
    }

    ngOnInit() {
        // force parent component to refresh
        this.authService.currentMessage.subscribe(
            (message) => (this.message = message)
        );
        // this.forgotpassword = 'https://' + 'www.babytalk.website/btsrv/public/' + '/v1/forgot-password';
        this.forgotpassword =
            'https://' + environment.serverUrl + '/v1/forgot-password';
    }

    ionViewWillEnter() {
        if (environment.verboseDebugMode === true) {
            console.log(true);
        }
        this.menu.swipeGesture(false);
    }

    async forgottenpassword() {
   /*     const alert = await this.alertController.create({
            header: 'Forgot Password? Enter your username and we will send a new one.',
            inputs: [
                {
                    name: 'email',
                    type: 'text',
                    placeholder: 'Enter Email',
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    },
                },
                {
                    text: 'Ok',
                    handler: (data) => {
                        if (environment.verboseDebugMode === true) {
                            console.log(data);
                        }
                        this.sendResetEmail(data.email);
                    },
                },
            ],
        });*/
    // disabled and replaced with a message
            const alert = await this.alertController.create({
                header: 'Forgot Password?',
                message: 'Please contact Andy at andyrox@liverpool.ac.uk to have your password reset.',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel',
                        handler: () => {
                            console.log('Confirm Cancel');
                        },
                    }
                ],
            });

            await alert.present();

    }

    // GET FCM TOKEN.
    getToken() {
        // console.log('Inside GetToken');
        this.fcm.getToken().then((token: any) => {
            this.platform.is('android') == true
                ? (this.tokenId = token)
                : (this.tokenId = token);
            this.registerToken();
        });
    }

    registerToken() {
        const data: any = {deviceToken: this.tokenId};
        console.log('userToken', data);
        this.apiService.registerDeviceToken(data).subscribe(
            (res) => {
                if (environment.verboseDebugMode === true) {
                    console.log(res);
                }
            },
            (error) => {
                if (error.status == 401) {
                    this.apiService.tokenExpired();
                } else if (error.status == 0) {
                    this.apiService.showToast('Cannot contact server!');
                } else {
                    this.apiService.showToast('Error ' + error.status);
                    console.log('Error', error);


                }
                // if (environment.verboseDebugMode === true) {
                //     console.log(err);
                // }
            }
        );
    }

    sendResetEmail(usersemailaddress) {
        // console.log('send reset email');
        if (environment.verboseDebugMode === true) {
            // console.log(usersemailaddress);
        }

        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(usersemailaddress)) {
            this.apiService.showToast('Email not valid');
            this.forgottenpassword();
        } else {
            const email = {email: usersemailaddress};
            // this.spinnerService.show();
            // call createmodule api service function

            this.apiService.forgotPassword(this.forgotpassword, email).subscribe(
                (data: any) => {
                    this.forgotPassResponse = data.replyStatus;
                    // console.log('Response', this.forgotPassResponse);
                    this.apiService.showToast(data.replyMsg);
                },
                (error) => {
                    // console.log('POST call in error:::', error);
                    if (environment.verboseDebugMode === true) {
                        console.log(JSON.stringify(error));
                    }
                    this.errormessage = error;
                    this.commserror = true;
                    this.commssuccess = false;
                },
                () => {
                    // console.log('The POST observable is now completed.');
                    // this.commssuccess = true;
                }
            );

            // this.spinnerService.hide();

            this.passwordforgotten = false;
            this.sentlink = true;
            this.senttoemailaddress = usersemailaddress;
        }
    }

    //    onFormSubmit(data) {
    //      if (environment.verboseDebugMode === true) {
    //        console.log('Login', data);
    //  }

    onLogin(data) {
        console.log('Logging in ', data);

        this.apiService.login(data.email, data.password).subscribe(
            async (res: any) => {
                console.log('response ', res.data.userinfo.givenname);
                if (res.replyStatus == 'success') {
                    this.apiService.showToast('Login Successful');
                    console.log('success. setting localstorage email, token, mode ');

                    localStorage.setItem('Email', data.email);
                    localStorage.setItem('token', res.data.token); // Set Token
                    localStorage.setItem('mode', 'Practitioner Mode');

                    console.log('setting storage (239 token) ');
                    this.storage.set('token', res.data.token);
                    console.log('res.data.userinfo.ucode', res.data.userinfo);

                    if (res.data.userinfo.ucode == 1) {
                        console.log('ucode=1 ');
                        this.storage.set('isResearchKey', true);
                        this.userInfoService.getAdvisorMode(false);
                        this.userInfoService.getFeatureMode(true);
                    } else {
                        console.log('Else Statement');
                        console.clear();
                    }
                    console.log('setting storage (251 token) ');
                    this.storage.set('token', res.data.token);
                    console.log('setting storage (userinfo, selectedbaby, useraddedbaby) ');
                    this.storage.set('userInfo', res.data.userinfo); // save user Info
                    this.storage.set('selectedbaby', res.data.selectedbaby);
                    this.storage.set('useraddedbaby', res.data.useraddedbaby);
                    this.apiService.menuButton = true;

                    this.getToken(); // Register Device token to DB..

                    if (res.data.useraddedbaby.length) {
                        this.navCntl.navigateRoot('/buttons', {skipLocationChange: true});
                    } else {
                        this.apiService.showToast('No baby added');

                        const modal = await this.modalController.create({
                            component: NewbabymodalPage,
                        });
                        modal.onDidDismiss().then((data: any) => {
                            this.storage.get('selectedbaby').then((val) => {
                                if (val.length) {
                                    this.navCntl.navigateRoot('/buttons', {
                                        skipLocationChange: true,
                                    });
                                } else {
                                    this.apiService.showToast('No Baby Added');
                                }
                            });
                        });
                        return await modal.present();
                    }
                } else {
                    this.apiService.showToast('Incorrect Password');
                }
            },
            (error) => {
                console.log('error');
                console.log(error);
                if (error.status == 401) {
                    console.log('401 error');
                    this.apiService.access401();
                } else if (error.status == 0) {
                    this.apiService.showToast('Cannot contact server!');
                } else {
                    this.apiService.showToast('Error ' + error.status);
                    console.log('Error', error);


                }
            }
        );
    }

    async registerModal() {
        const modal = await this.modalController.create({
            component: RegisterationComponent,
            animated: true,
        });
        modal.onDidDismiss().then((data: any) => {
        });
        return await modal.present();
    }
}
