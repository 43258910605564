import {Component, Input, OnInit} from '@angular/core';
import {ActionSheetController, ModalController, NavParams} from '@ionic/angular';
import {Router} from '@angular/router';
import {UserInfoService} from 'src/app/user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {File} from '@ionic-native/file/ngx';
import {ApiService} from 'src/app/api.service';
import {Storage} from '@ionic/storage';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-editbabymodal',
    templateUrl: './editbabymodal.page.html',
    styleUrls: ['./editbabymodal.page.scss'],
})
export class EditbabymodalPage implements OnInit {
    Img: any;
    @Input() BabyName: string;
    @Input() DOB: string;
    @Input() notes: string;
    @Input() image: any;
    id: any;

    myImageUrl: any;
    uploadUrl: any; // Server Response;
    isImage: boolean = false;
    babyId: any;
    private wordBoost: boolean;
    private imageOption: boolean;


    constructor(private navParams: NavParams,
                public router: Router, private modalCtrl: ModalController,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public actionSheetCtrl: ActionSheetController,
                private file: File, private camera: Camera,
                public apiService: ApiService,
                public storage: Storage) {

        console.log(this.navParams.get('BabyName'));
        console.log(this.navParams.get('DOB'));
        console.log(this.navParams.get('notes'));
        this.Img = this.navParams.get('profile_pic');
        this.id = this.navParams.get('id');
        this.wordBoost = environment.wordboost;
        this.imageOption = environment.imageoption;

        // this.myImageUrl = 'http://www.babytalk.website/btsrv/public/' + this.Img;
        this.myImageUrl = 'https://' + environment.serverUrl + '/' + this.Img;
        console.log(this.myImageUrl);
    }

    ngOnInit() {
        this.getInfo();
    }

    async showActionSheet() {
        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Select Photo From',
            buttons: [{
                text: 'Camera',
                handler: () => {
                    this.photoOption(1);
                }
            }, {
                text: 'Gallery',
                handler: () => {
                    this.photoOption(2);

                }
            }, {
                text: 'Cancel',
                handler: () => {
                    console.log('Cancel clicked');
                }
            }]
        });
        await actionSheet.present();
    }


    photoOption(params) {

        if (params == '1') {
            params = this.camera.PictureSourceType.CAMERA;
        } else {
            params = this.camera.PictureSourceType.PHOTOLIBRARY;
        }

        const options: CameraOptions = {
            quality: 100,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            sourceType: params,
            saveToPhotoAlbum: false,
            correctOrientation: true,
            allowEdit: true,
        };

        this.camera.getPicture(options).then((imageData) => {
            this.myImageUrl != '' ? this.isImage = false : this.isImage = true;
            console.log('ImageData', this.isImage);
            this.myImageUrl = 'data:image/jpeg;base64,' + imageData;
        }, (err) => {
            if (environment.verboseDebugMode === true) {
                console.log(err);
            }
        });
        // this.ImageUpload();
    }

    editBabyImage() {
        console.log('Editbaby Image');
        let data = {
            babyId: this.babyId,
            profilePic: this.myImageUrl
        };
        console.log('Data ', data);
        if (this.myImageUrl == '' || this.myImageUrl == undefined || this.myImageUrl == null) {
            this.apiService.showToast('Please Select Image to Update');
        } else {
            this.apiService.showLoader();
            this.apiService.editBabyImageAPI(data).subscribe(res => {
                if (environment.verboseDebugMode === true) {
                    console.log(res);
                }
                this.apiService.hideLoader();
                if (res.replyStatus == 'success') {
                    this.apiService.showToast(res.replyMsg);
                } else {
                    this.apiService.showToast(res.replyMsg);
                }
            }, err => {
                this.apiService.hideLoader();
                if (err.status == 401) {
                    this.apiService.tokenExpired();
                } else if (err.status == 0) {
                    this.apiService.showToast('Cannot contact server!');
                } else {
                    this.apiService.showToast('Error ' + err.status);
                    console.log('Error', err);
                }
            });
        }
    }

    getInfo() {
        this.storage.get('selectedbaby').then(val => {
            this.babyId = val[0].id;
        });
    }


    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    closeModal() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalCtrl.dismiss({
            'dismissed': true
        });
    }

    uploadImg() {
        this.modalCtrl.dismiss();
        // this.mybabyComponent.getBabyList()
    }

}
