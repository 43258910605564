import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../user-info.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {transition, trigger, useAnimation} from '@angular/animations';
import {bounce} from 'ng-animate';
import {Storage} from '@ionic/storage';
import {environment} from '../../environments/environment';
import {RecommendedWordsLayout, WordLayout} from '../interfaces';


// Babytalk Client V2
// Component: recommendation
// Purpose: Recommends words to learn
// Porting Status: Ported from Ionic 1 (Javascript/AngularJS)
// to Ionic 5 (Typescript/Angular 7) Aug 2019

@Component({
    selector: 'app-recommendation', // app-flashcards//
    templateUrl: './recommendation.component.html',
    styleUrls: ['./recommendation.component.scss'],
    animations: [
        trigger('bounce', [transition('* => *', useAnimation(bounce))])
    ],
})
export class RecommendationComponent implements OnInit {
    // base server url
    basesurl: string;
    getRecWordsURL: string;
    rwords: WordLayout[];
    public RecWordsObj: RecommendedWordsLayout;
    
    // property type declarations
    PageTitle = 'Recommended Words';
    commserror: boolean;
    commssuccess: boolean;
    errormessage: string;
    successmessage: string;
    bounce: any;
    flipped = false;
    shownGroup = null;
    flipCard: any;
    babyId: any;
    word: any;
    myImgUrl = '../assets/images/dummy.png';
    ImgUrl = '../assets/images/sun.png';
    Img ;
    private babyname: string;
    wordBoost: boolean;

    // constructor
    constructor(public router: Router,
                private userInfoService: UserInfoService,
                private spinner: NgxSpinnerService,
                public apiService: ApiService,
                private storage: Storage) {
        // this.apiService.showNotification();
        this.wordBoost = environment.wordboost;

    }
   
    // Initialisation routine
    ngOnInit() {
        console.log('ngoninit');

        this.storage.get('selectedbaby').then(val => {
            this.errormessage = '';
            this.babyId = val[0].id;
            console.log('BABYID (then) ', this.babyId);
            console.log('BABYID', this.babyId);

            // this.flipCard = [{
            //     title: 'Camping',
            //     path: '../../assets/flashcards/activities/camping.png'
            // }, {
            //     title: 'Head',
            //     path: '../../assets/flashcards/bodyparts/head.png'
            // }, {
            //     title: 'Carrot',
            //     path: '../../assets/flashcards/food/carrot.png'
            // }, {
            //     title: 'Gloves',
            //     path: '../../assets/flashcards/clothes/gloves.png'
            // }, {
            //     title: 'Cow',
            //     path: '../../assets/flashcards/animals/cow.png'
            // }, {
            //     title: 'Bug',
            //     path: '../../assets/flashcards/animals/bug.png'
            // }]


            // this.babyname = this.userInfoService.getBabyName();
            // this.babyId = this.userInfoService.getBabyID();
            this.spinner.show();

            // ---- load recommended words from server ---
            this.basesurl = 'https://' + environment.serverUrl + '/v1/';
            // this.basesurl = "http://www.babytalk.website/btsrv/public/v1/";
            this.getRecWordsURL = this.basesurl + 'recommendation/' + this.babyId;

            // the words returned by the server will not always have flashcards associated with them.
            // in those cases (e.g. 'you', 'this', 'later' etc) just the word should appear instead of a picture.


            // this.apiService.getRecommendedWordsFromServer(this.getRecWordsURL).subscribe(res => {

            //     // console.log('Number of words recommended=' + res.number_of_words);
            //     console.log('words recommended=');
            //     if (environment.verboseDebugMode === true) { console.log(res[0].word); }
            //     console.log(res[0].image_filename ? res[0].image_filename : 'no image');
            //     if (environment.verboseDebugMode === true) { console.log(res[1].word); }
            //     console.log(res[1].image_filename ? res[1].image_filename : 'no image');
            //     if (environment.verboseDebugMode === true) { console.log(res[2].word); }
            //     console.log(res[2].image_filename ? res[2].image_filename : 'no image');
            //     if (environment.verboseDebugMode === true) { console.log(res[3].word); }
            //     console.log(res[3].image_filename ? res[3].image_filename : 'no image');
            //     if (environment.verboseDebugMode === true) { console.log(res[4].word); }
            //     console.log(res[4].image_filename ? res[4].image_filename : 'no image');
            //     if (environment.verboseDebugMode === true) { console.log(res[5].word); }
            //     console.log(res[5].image_filename ? res[5].image_filename : 'no image');
            //     // RecWordsObj = res;
            // }, err => {
            //   //  this.apiService.showToast('Try again');
            //     if (environment.verboseDebugMode === true) { console.log(err); }
            // });

            // rthis.getRecWords();
            this.spinner.hide();
        }).catch(error => {
            // interpret error and maybe display something on the UI
            console.log('Error getting user info from storage');
            console.log(error);

        }).finally(() => {
            if (!this.babyId) {
                console.log('No child info');
                this.errormessage = 'Please Log out and back in again.';
            }
            console.log('Error getting user info from storage');
            console.log(Error);
        });

    }


    ionViewWillEnter() {
        if (environment.verboseDebugMode === true) {
            console.log('ionViewWillEnter');
        }
        this.getRecWords();

    }

    showButtonsPage() {
        // navigate to the buttons screen
        this.router.navigateByUrl('/buttons');
    }

    refresh() {
        console.log('Show spinner');
        console.log('refresh screen');
        this.spinner.show();
    }

    // flip(params) {
    //     if (environment.verboseDebugMode === true) { console.log(params); }
    //     if (params === 1) {
    //         this.flipped = !this.flipped;
    //         if (environment.verboseDebugMode === true) { console.log(this.flipped); }
    //     }

    // }

    // flip(item:any) {
    //     if(item.image_filename) {
    //         this.flipped = !this.flipped;
    //     }
    // }

    getRecWords() {
        console.log('get rec words');
        if (environment.verboseDebugMode === true) {
            console.log('getrecwords');
        }
        if (environment.verboseDebugMode === true) {
            console.log(this.babyId);
        }
        this.apiService.getRecommendedWordsFromServer(this.babyId).subscribe(res => {
                this.flipCard = res;
                if (environment.verboseDebugMode === true) {
                    console.log('ResponseFlipCard', this.flipCard);
                }
            }, err => {
                if (err.status == 401) {
                    this.apiService.access401();
                }
            }
        );
    }

   

}
